import { useState } from 'react'

const useSelectedTemplate = () => {
  const [selectedTemplate, setSelectedTemplate] = useState<string>('default')

  const select = (template: string) => {
    if (selectedTemplate === template) {
      setSelectedTemplate('default')
    } else {
      setSelectedTemplate(template)
    }
  }

  return {
    selectedTemplate,
    select,
  }
}

export default useSelectedTemplate
