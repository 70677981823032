import { InputBox } from '@shared/ui'

import { Button } from '@/components/ui/button'

const password = () => {
  return (
    <div>
      <div className="md:border-b flex justify-between items-center border-[#00071433] py-2 md:py-5">
        <div>
          <p className="text-[12px] md:text-lg font-semibold ">Change password</p>
          <p className="text-[12px] md:text-base font-normal ">Update your password here</p>
        </div>

        <div className="md:flex gap-4 hidden">
          <Button variant="outline" color="#0959FE">
            Cancel
          </Button>
          <Button variant="filled" color="#0959FE">
            Save
          </Button>
        </div>
      </div>
      <div className="flex flex-col max-w-md mt-4">
        <div className="mb-3">
          <InputBox
            label="Previous Password"
            placeholder=""
            type="password"
            id="previousPassword"
          />
        </div>
        <div className="mb-3">
          <InputBox label="New Password" placeholder="" type="password" id="newPassword" />
        </div>
        <div className="mb-3">
          <InputBox label="Confirm Password" placeholder="" type="password" id="confirmPassword" />
        </div>
      </div>

      <div className="flex gap-5 my-10 md:hidden">
        <Button variant="outline" color="#0959FE" className="w-1/2">
          Cancel
        </Button>
        <Button variant="filled" color="#0959FE" className="w-1/2">
          Save
        </Button>
      </div>
    </div>
  )
}

export default password
