export default [
  {
    id: 'customizer-icon-font-size',
    label: 'Font Size',
    icon: 'text-height',
  },
  {
    id: 'customizer-icon-text-align',
    label: 'Text Align',
    icon: 'indent',
  },
  {
    id: 'customizer-icon-text-color',
    label: 'Text Color',
    icon: 'fill-drip',
  },
  {
    id: 'customizer-icon-text-case',
    label: 'Text Case',
    icon: 'chess',
  },
]
