import { Button, InputBox } from '@shared/ui'

import HeroImage from '@/assets/images/base/BG.svg'

const JobPageHeroSection = () => {
  return (
    <header
      className="flex flex-col justify-center max-w-full px-5 py-5 rounded-none h-[400px] md:h-[500px] md:px-10"
      style={{ backgroundImage: `url(${HeroImage})`, backgroundPosition: 'cover' }}
    >
      <div className="max-w-[1000px] mx-auto">
        <section className="flex flex-col items-center justify-center py-5 md:py-10">
          <h4 className="text-2xl font-bold text-center text-white md:text-4xl">
            How To Manage A Difficult Co-worker
          </h4>
          <p className="my-3 mt-5 text-xs font-light text-center text-white md:my-10 md:text-lg md:max-w-4xl">
            We all get to work with and manage a difficult co-worker at some point. That colleague
            with the awkward sense of humor, the one that has no idea of personal space, and that
            assistant who loves to talk non-stop. In as much as we would all love to work in a
            friendly and productive environment…
          </p>
          <form className="flex mx-auto md:mb-20 md:mx-px">
            <div className="flex items-center px-2 py-1 bg-white md:w-[450px] rounded-l-sm text-current-500">
              <InputBox
                showLabel={false}
                type="text"
                placeholder="Search for job....."
                css={{
                  input:
                    'border-none rounded-none outline-none  shadow-none text-current-900 focus:ring-0',
                }}
              />
            </div>
            <Button className="rounded-l-none rounded-r-sm">Search</Button>
          </form>
        </section>
        <Button className="rounded-sm " size="md">
          Post a job
        </Button>
      </div>
    </header>
  )
}

export default JobPageHeroSection
