import JobPageHeroSection from '@features/base/ui/job-page-hero-section'
import { Button, InputBox } from '@shared/ui'

import PostOneImage from '@/assets/images/base/difficult-co-worker.svg'
import RecentPost from '@/assets/images/base/recent-post.svg'

export default function Jobs() {
  const tags = [
    '21 Century',
    'A9 Algorithm',
    'Amozon',
    'Blue Origin',
    'Business',
    'Cloud',
    'CV',
    'Cover Letter',
    'Collegues',
    'Critical Thinking',
    'Crime',
    'Cloud',
    'Employment',
    'Devops',
    'Employment In Nigeria',
    'Entrepreneur',
    'Forbes',
    'Fraud',
    'Job Hunting',
    'Holiday',
    'International Students',
    'Interview',
    'Leave',
    'Job Scam',
    'Leadership',
    'Vacation',
    'Programming',
    'Remote Jobs',
    'Success',
    'Richest Man Alive',
    'Quit',
    'Professional CV',
    'Relax',
    'Toxic',
    'Vacation',
    'Work',
  ]

  return (
    <div className="min-h-screen pb-10 bg-white">
      <JobPageHeroSection />
      <main className="gap-3 md:gap-7 my-10 grid grid-cols-12 max-w-[1050px] mx-auto ">
        <section className="col-span-12 md:col-span-7 recommende-post">
          <span className="border-b-2 border-black pe-5 md:pe-10">Recommended post</span>
          <div className="pb-10 my-10 border-b">
            <img src={PostOneImage} alt="post one" />
            <p className="mt-5 mb-2 text-sm font-medium md:text-lg">
              How To Manage A Difficult Co-worker
            </p>
            <p className="mb-5 text-xs md:mb-10 md:text-sm">
              We all get to work with and manage a difficult co-worker at some point. That colleague
              with the awkward sense of humor, the one that has no idea of personal space, and that
              assistant who loves to talk non-stop. In as much as we would all love to work in a
              friendly and productive environment, […]
            </p>
            <Button className="rounded-sm">Read More</Button>
          </div>
          <div className="pb-10 my-10 border-b">
            <img src={PostOneImage} alt="post one" />
            <p className="mt-5 mb-2 text-sm font-medium md:text-lg">
              How To Know When A Company Is Nearing Insolvency
            </p>
            <p className="mb-5 text-xs md:mb-10 md:text-sm">
              It is not exactly hard to know when a company is nearing insolvency. No director,
              manager, or even supervisor would come out and tell employees that the organization
              will soon collapse. Assuming you are even suspecting at all, they will even start
              giving assurances that might cast the doubt out of your mind. Well, it […]
            </p>
            <Button className="rounded-sm">Read More</Button>
          </div>
          <div className="pb-10 my-10 border-b">
            <img src={PostOneImage} alt="post one" />
            <p className="mt-5 mb-2 text-sm font-medium md:text-lg">Is HR Really Your Friend?</p>
            <p className="mb-5 text-xs md:mb-10 md:text-sm">
              We all get to work with and manage a difficult co-worker at some point. That colleague
              with the awkward sense of humor, the one that has no idea of personal space, and that
              assistant who loves to talk non-stop. In as much as we would all love to work in a
              friendly and productive environment, […]
            </p>
            <Button className="rounded-sm">Read More</Button>
          </div>
          <div className="pb-10 my-10 border-b">
            <img src={PostOneImage} alt="post one" />
            <p className="mt-5 mb-2 text-sm font-medium md:text-lg">
              How To Detect A Toxic Workplace
            </p>
            <p className="mb-5 text-xs md:mb-10 md:text-sm">
              Being able to detect a toxic workplace would save you a lot of stress and emotional
              troubles. But the question is, can you detect one? When you are seeking a job and
              searching all over – the internet, asking friends and other connections, filling
              applications – for a job, you would be happy to even […]
            </p>
            <Button className="rounded-sm">Read More</Button>
          </div>
          <div className="pb-10 my-10 border-b">
            <img src={PostOneImage} alt="post one" />
            <p className="mt-5 mb-2 text-sm font-medium md:text-lg">
              How To Be Successful At A New Job
            </p>
            <p className="mb-5 text-xs md:mb-10 md:text-sm">
              Being successful at a new job is something you should allocate your time, energy, and
              commitment to. Yes, you have been hired, but hope you know you were hired based on how
              you performed at the interview. Your performance at your interview only told your
              recruiter or manager that you MAY be able to do […]
            </p>
            <Button className="rounded-sm">Read More</Button>
          </div>
          <div className="pb-10 my-10 border-b">
            <img src={PostOneImage} alt="post one" />
            <p className="mt-5 mb-2 text-sm font-medium md:text-lg">
              How To Achieve Balance While Working Multiple Jobs
            </p>
            <p className="mb-5 text-xs md:mb-10 md:text-sm">
              Time may become very difficult to manage or achieve balance while working multiple
              jobs. A lot of times, we strive to attain a decent work-life balance while striving to
              make a decent living. Working an extra job or even jobs may sound like a simple answer
              to some of your financial problems. While you are […]
            </p>
            <Button className="rounded-sm">Read More</Button>
          </div>
          <div className="pb-10 my-10 border-b">
            <img src={PostOneImage} alt="post one" />
            <p className="mt-5 mb-2 text-sm font-medium md:text-lg">
              5 Tips On How To Succeed At A Remote Job (Work From Home)
            </p>
            <p className="mb-5 text-xs md:mb-10 md:text-sm">
              It is good to search for some tips on how to succeed at a remote job. Before the year
              2020, which is also the year of COVID-19, a lot of professionals, most especially
              writers and software engineers have been working from home. Not referring to
              freelancers only, but salary earners as well that do not […]
            </p>
            <Button className="rounded-sm">Read More</Button>
          </div>
          <div className="pb-10 md:mt-10">
            <img src={PostOneImage} alt="post one" />
            <p className="mt-5 mb-2 text-sm font-medium md:text-lg">
              How To Get A Remote Job From Nigeria (Earn in Dollars)
            </p>
            <p className="mb-5 text-xs md:mb-10 md:text-sm">
              Every young professional is likely looking for how to get a remote job in Nigeria. Why
              won’t they? There are a lot of perks attached to working in a company anywhere in the
              world from Nigeria. In March 2020, the world was on lockdown due to the COVID-19.
              Health professionals deemed it best that the […]
            </p>
            <Button className="rounded-sm">Read More</Button>
          </div>
          <div className="flex gap-2 md:mb-10">
            <div className="px-2 py-1 border rounded-sm border-primary-900 text-primary-900">
              01
            </div>
            <div className="px-2 py-1 text-white border rounded-sm border-primary-900 bg-primary-900">
              02
            </div>
            <div className="px-2 py-1 text-white border rounded-sm border-primary-900 bg-primary-900">
              03
            </div>
            <span className="text-2xl text-center text-gray-400">...</span>

            <div className="px-2 py-1 text-white border rounded-sm border-primary-900 bg-primary-900">
              06
            </div>
            <Button className="rounded-sm" size="sm">
              Next
            </Button>
          </div>
        </section>
        <section className="col-span-12 md:col-span-5">
          <span className="border-b-2 border-black pe-5 md:pe-10">Recent post</span>
          <div className="flex gap-2 pb-5 mt-10 mb-5 border-b ">
            <img src={RecentPost} alt="recentpost" className="h-[110px]" />
            <div>
              <h4 className="mb-3 text-base font-medium">How To Manage A Difficult Co-worker</h4>
              <p className="text-xs md:text-sm">
                "Wow what great service, I love it! It's is the most valuable business resource we
                have EVER purchased. We can't understand how we've been living without it........"
              </p>
            </div>
          </div>
          <div className="flex gap-2 pb-5 my-5 border-b ">
            <img src={RecentPost} alt="recentpost" className="h-[110px]" />
            <div>
              <h4 className="mb-3 text-base font-medium">
                Create a CV that lands your dream job Create CV
              </h4>
              <p className="text-xs md:text-sm">
                "Wow what great service, I love it! It's is the most valuable business resource we
                have EVER purchased. We can't understand........"
              </p>
            </div>
          </div>
          <div className="flex gap-2 pb-5 my-5 border-b ">
            <img src={RecentPost} alt="recentpost" className="h-[110px]" />
            <div>
              <h4 className="mb-3 text-base font-medium">Is HR Really Your Friend?</h4>
              <p className="text-xs md:text-sm">
                "Wow what great service, I love it! It's is the most valuable business resource we
                have EVER purchased. We can't understand how we've been living without it........"
              </p>
            </div>
          </div>
          <div className="flex gap-2 pb-5 my-5">
            <img src={RecentPost} alt="recentpost" className="h-[110px]" />
            <div>
              <h4 className="mb-3 text-base font-medium">How To Detect A Toxic Workplace</h4>
              <p className="text-xs md:text-sm">
                "Wow what great service, I love it! It's is the most valuable business resource we
                have EVER purchased. We can't understand how we've been living without it........"
              </p>
            </div>
          </div>
          <span className="border-b-2 border-black pe-5 md:pe-10">Categories</span>
          <div className="flex flex-col my-5 md:mt-10">
            <p className="px-4 py-2 mb-3 text-sm text-white rounded-md cursor-pointer bg-primary-900 hover:bg-primary-800">
              Career & profesional Development
            </p>
            <p className="px-4 py-2 mb-3 text-sm text-white rounded-md cursor-pointer bg-primary-900 hover:bg-primary-800">
              Revamped CV
            </p>
            <p className="px-4 py-2 mb-3 text-sm text-white rounded-md cursor-pointer bg-primary-900 hover:bg-primary-800">
              Study & Work Abroad
            </p>
            <p className="px-4 py-2 mb-3 text-sm text-white rounded-md cursor-pointer bg-primary-900 hover:bg-primary-800">
              Study Abroad
            </p>
            <p className="px-4 py-2 mb-3 text-sm text-white rounded-md cursor-pointer bg-primary-900 hover:bg-primary-800">
              Uncategorized
            </p>
          </div>
          <span className="border-b-2 border-black pe-5 md:pe-10">Trending tags</span>
          <div className="flex flex-wrap gap-2 mt-5 mb-5 md:mt-10">
            {tags.map((tag) => (
              <Button key={tag} variant="outline" className="mb-1 rounded-3xl">
                {tag}
              </Button>
            ))}
          </div>
          <span className="border-b-2 border-black pe-5 md:pe-10">Newsletter</span>
          <div className="my-5 md:mt-8">
            <form action="" className="flex flex-col">
              <InputBox
                showLabel={false}
                type="text"
                placeholder="Your email address"
                css={{
                  input:
                    ' border-none rounded-sm outline-none mb-3 md:mb-5 shadow-sm text-current-900 focus:ring-0',
                }}
              />
              <Button className="rounded-sm">Subscribe</Button>
            </form>
          </div>
        </section>
        <section className="col-span-12 md:col-span-7">
          <div>
            <span className="border-b-2 border-black pe-5 md:pe-10">Leave a reply</span>
            <form action="">
              <InputBox
                type="textarea"
                name="comment"
                id="comment"
                placeholder="Comment"
                cols={10}
                rows={10}
                className="my-5 rounded-lg"
              />
              <div className="grid grid-cols-1 gap-2 mb-3 md:mb-10 md:grid-cols-2">
                <InputBox type="text" label="Name" id="name" placeholder="Name" />
                <InputBox type="text" label="Email" id="email" placeholder="Email" />
              </div>
              <Button className="rounded-sm">Subscribe</Button>
            </form>
          </div>
        </section>
      </main>
    </div>
  )
}
