import { forwardRef } from 'react'

import { RankedList } from '@shared/ui'
import { CVTemplateProps } from '@widgets/types'
import clsx from 'clsx'

const Template2 = forwardRef<HTMLDivElement, CVTemplateProps>(
  ({ className, cv }: CVTemplateProps, ref) => {
    return (
      <div
        ref={ref}
        className={clsx('flex gap-2 items-start justify-between text-[#727176]', className)}
      >
        <div className="flex flex-col gap-2 w-5/12 grow">
          <header className="relative bg-[#f1e1c8] min-h-32 w-full">
            <div className="absolute top-0 left-3 bottom-5 right-0 bg-white py-5 px-2 flex flex-col text-center justify-center items-center">
              {cv?.avatar && (
                <img
                  src={cv.avatar}
                  alt="avatar"
                  className="rounded-full h-14 w-14 object-cover border border-current-500"
                />
              )}
              <h3 className="text-md lg:text-xl font-semibold uppercase">{cv?.fullName}</h3>
              <p className="text-xs lg:text-sm font-light">{cv?.profession}</p>
            </div>
          </header>
          <main className="flex flex-col gap-2 p-3 pt-0 -mt-7 z-10">
            <section>
              <h5 className="text-sm font-bold -ml-1 pl-1">CONTACT</h5>
              <p className="text-xs font-light">{cv?.randomPhoneNumber}</p>
              <p className="text-xs font-light">{cv?.randomEmail}</p>
              <p className="text-xs font-light">{cv?.formattedAddress}</p>
            </section>
            <section>
              <h5 className="text-sm font-bold bg-[#f1e1c8] -ml-1 pl-1">PROFILE</h5>
              <p className="text-xs font-light">{cv?.summary}</p>
            </section>
            <section>
              <h5 className="text-sm font-bold bg-[#f1e1c8] -ml-1 pl-1">WORK EXPERIENCE</h5>
              <div className="flex flex-col gap-2">
                {cv?.workExperience
                  .filter((exp) => !exp.isVolunteerRole)
                  .map((exp, index) => (
                    <div key={index}>
                      <div className="mb-3">
                        <h5 className="text-xs font-light">
                          <span>
                            {exp.organization},{' '}
                            {exp.location?.remote
                              ? 'Remote'
                              : exp.location?.city + ', ' + exp.location?.country}
                          </span>{' '}
                          - <span>{exp.title}</span>
                        </h5>
                        <p className="text-xs font-light">{exp.duration}</p>
                      </div>
                      <div className="text-xs font-light">{exp.description}</div>
                    </div>
                  ))}
              </div>
            </section>
          </main>
        </div>
        <div className="flex flex-col gap-2 w-5/12 grow my-4">
          <section>
            <h5 className="text-sm font-bold bg-[#f1e1c8] -ml-1 pl-1">VOLUNTEERING</h5>
            <div className="flex flex-col gap-2">
              {cv?.workExperience
                .filter((exp) => exp.isVolunteerRole)
                .map((exp, index) => (
                  <div key={index}>
                    <div className="mb-3">
                      <h5 className="text-xs font-light">
                        <span>
                          {exp.organization},{' '}
                          {exp.location?.remote
                            ? 'Remote'
                            : exp.location?.city + ', ' + exp.location?.country}
                        </span>{' '}
                        - <span>Volunteer</span>
                      </h5>
                      <p className="text-xs font-light">{exp.duration}</p>
                    </div>
                    <div className="text-xs font-light">{exp.description}</div>
                  </div>
                ))}
            </div>
          </section>
          <section>
            <h5 className="text-sm font-bold bg-[#f1e1c8] -ml-1 pl-1 mb-2">EDUCATION</h5>
            {cv?.education.map((edu, index) => (
              <div className="text-xs font-light uppercase" key={index}>
                <p>
                  <span>{edu.organization}</span> -{' '}
                  <span>
                    {edu.accreditation.fieldOfStudy}, {edu.accreditation.degree}
                  </span>
                </p>
                <p>
                  {edu.dates?.from} - {edu.dates?.isCurrent ? 'Present' : edu.dates?.to}
                </p>
              </div>
            ))}
          </section>
          <section>
            <h5 className="text-sm font-bold bg-[#f1e1c8] -ml-1 pl-1">SKILLS</h5>
            <RankedList
              items={cv?.skills.map((skill) => skill.name) || []}
              className={(rank, index) =>
                clsx(
                  'bg-[#f1e1c8] rounded-full border border-[#f1e1c8]',
                  rank >= index ? 'bg-[#f1e1c8]' : 'bg-white',
                )
              }
            />
          </section>
          <section>
            <h5 className="text-sm font-bold bg-[#f1e1c8] -ml-1 pl-1">HOBBIES</h5>
            <div className="flex flex-col text-sm font-light">
              <p>Reading</p>
              <p>Traveling</p>
              <p>Photography</p>
            </div>
          </section>
          <section>
            <h5 className="text-sm font-bold bg-[#f1e1c8] -ml-1 pl-1">LANGUAGES</h5>
            <RankedList
              items={['English', 'French', 'Spanish', 'German', 'Italian']}
              className={(rank, index) =>
                clsx(
                  'bg-[#f1e1c8] rounded-full border border-[#f1e1c8]',
                  rank >= index ? 'bg-[#f1e1c8]' : 'bg-white',
                )
              }
              css={{ item: 'py-2 pr-2' }}
            />
          </section>
        </div>
      </div>
    )
  },
)

Template2.displayName = 'Template2'
export default Template2
