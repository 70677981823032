import { useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify'

import axios from 'axios'

const AUTH_MICROSERVICE_URL = import.meta.env.VITE_REVAMPINSIGHTS_API_URL + '/auth'

const ConfirmEmail = () => {
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  // TODO: if the token is not set, redirect to 404 page

  useEffect(() => {
    const confirm = async () => {
      try {
        const token = searchParams.get('token')
        if (!token) {
          // Redirect to 404 page
          navigate('*', { replace: true })
          return
        }
        const { data } = await axios.put(`${AUTH_MICROSERVICE_URL}/confirm-email?token=${token}`)
        if (data.success) {
          // TODO: show success message as toast
          toast.success('Email confirmation successful. Please log in.')

          navigate('/auth/login', { replace: true })
        }
      } catch {
        // TODO: Show error message on the screen
        toast.error('An error occurred. Please try again.')
      }
    }

    confirm()
  }, [])

  return <></>
}

export default ConfirmEmail
