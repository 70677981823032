import { useState } from 'react'

import { Step } from '@shared/types'
import { AxiosResponse } from 'axios'

import { loaders } from '@/widgets'

import Navigator from './navigator'

type Props = {
  stepId: number
  currentStep: Step
  navigate: (back: boolean) => void
  onComplete: (data: Record<string, unknown>) => void
  children?: React.ReactNode
  isLastStep?: boolean
}

const WithMultiStepsLoader: React.FC<Props> = ({
  stepId,
  currentStep,
  navigate,
  onComplete,
  children,
  isLastStep,
}: Props) => {
  const [loading, setLoading] = useState(false)

  const submitHandler = async (data: Record<string, unknown>) => {
    setLoading(true)

    // update the form data and move to the next step
    await currentStep.onSubmit(data)
    if (!currentStep.asList) {
      navigate(false)
    }

    // scroll to the top of the page
    window.scrollTo({ top: 0, behavior: 'smooth' })

    setLoading(false)
    return Promise.resolve({ data: { success: true } } as AxiosResponse)
  }

  // extend the form loader component
  const extend = (Component: React.ComponentType<loaders.IWithFormLoaderComponentProps>) => {
    return function Wrapper(props: loaders.IWithFormLoaderComponentProps) {
      return (
        <div className="flex items-start justify-between gap-5">
          {/* Step Handler */}
          <Component {...props} />
          {children}
        </div>
      )
    }
  }

  // load the handler for the current step
  const StepForm = loaders.WithFormLoader(submitHandler, currentStep.handler, currentStep.schema)

  return (
    <div className="flex flex-col">
      {currentStep.asList || !isLastStep ? (
        <>
          <StepForm onComplete={onComplete} extend={extend} />
          <Navigator navigate={navigate} stepId={stepId} loading={loading} />
        </>
      ) : (
        <StepForm onComplete={onComplete} extend={extend}>
          <Navigator navigate={navigate} stepId={stepId} loading={loading} nextShouldSubmit />
        </StepForm>
      )}
    </div>
  )
}

export default WithMultiStepsLoader
