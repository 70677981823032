import { forwardRef } from 'react'

import { CVTemplateProps } from '@widgets/types'
import clsx from 'clsx'

const Template1 = forwardRef<HTMLDivElement, CVTemplateProps>(
  ({ className, cv }: CVTemplateProps, ref) => {
    return (
      <div ref={ref} className={clsx('', className)}>
        <header className="bg-[#084b80] rounded-t-sm text-white flex flex-col gap-3 pt-3 items-center text-center">
          <hr className="w-full bg-secondary-200 max-w-32" />
          <h2 className="xs:text-lg text-2xl font-bold">{cv?.fullName}</h2>
          <p className="text-xs xl:text-sm font-extralight mb-1">
            <span>{cv?.formattedAddress}</span>
            <span className="font-medium mx-1">|</span>
            <span>E: {cv?.randomEmail}</span>
          </p>
        </header>
        <main className="bg-[#f0f0f0] px-3 md:px-5">
          {/* Professional Summary */}
          <section className="pb-2 pt-4">
            <hr className="w-full max-w-24 border-secondary-200 mb-1" />
            <h4 className="text-md font-bold text-[#084b80]">Summary</h4>
            <p className="text-sm font-light">{cv?.summary || 'No summary provided by the user'}</p>
          </section>

          {/* Work Experience */}
          <section className="pb-2 pt-4">
            <hr className="w-full max-w-24 border-secondary-200 mb-1" />
            <h4 className="text-md font-bold text-[#084b80]">Experience</h4>
            {cv?.workExperience.map((exp, index) => (
              <div key={index}>
                <div>
                  <div className="flex gap-1 items-center">
                    <span className="text-sm font-semibold">{exp.title}</span>
                    <span className="font-light">|</span>
                    <p className="text-xs font-light">
                      {exp.dates?.from} to {exp.dates?.isCurrent ? 'Current' : exp.dates?.to}
                    </p>
                  </div>
                  <p className="text-xs font-semibold -mt-1">{exp.organization}</p>
                </div>
                <div className="text-xs font-light py-[0.2rem]">
                  {exp.description || 'No description provided by the user'}
                </div>
              </div>
            ))}
          </section>

          {/* Skills */}
          <section className="pb-2 pt-4">
            <hr className="w-full max-w-24 border-secondary-200 mb-1" />
            <h4 className="text-md font-bold text-[#084b80]">Skills</h4>
            <ul className="text-xs font-light grid grid-cols-2 gap-1 pl-1 list-disc list-inside">
              {cv?.skills.map((skill) => <li key={skill.id}>{skill.name}</li>)}
            </ul>
          </section>

          {/* Education */}
          <section className="pb-2 pt-4">
            <hr className="w-full max-w-24 border-secondary-200 mb-1" />
            <h4 className="text-md font-bold text-[#084b80]">Education</h4>
            <div className="flex flex-col gap-1">
              {cv?.education.map((edu, index) => (
                <div key={`${edu.organization}-${index}`}>
                  <div>
                    <div className="flex gap-1 items-center">
                      <span className="text-sm font-semibold">
                        {edu.accreditation?.degree} &mdot; {edu.accreditation?.fieldOfStudy}
                      </span>
                      <span className="font-light">|</span>
                      <p className="text-xs font-light">
                        {edu.dates?.from} to {edu.dates?.isCurrent ? 'Current' : edu.dates?.to}
                      </p>
                    </div>
                    <p className="text-xs font-semibold -mt-1">
                      {edu.organization} &mdot; {edu.formattedLocation}
                    </p>
                  </div>
                  <div className="text-xs font-light py-[0.2rem]">
                    {edu.description || 'No description provided by the user'}
                  </div>
                </div>
              ))}
            </div>
          </section>
        </main>
      </div>
    )
  },
)

Template1.displayName = 'Template1'
export default Template1
