import { RouteObject } from 'react-router-dom'

import DashboardLayout from '@features/dashboard/layout'
import AddAdmin from '@pages/dashboard/admin/add-admin'
import Admin from '@pages/dashboard/admin/admin'
import Dashboard from '@pages/dashboard/admin/dashboard'
import { Settings } from '@pages/dashboard/admin/settings'
import Users from '@pages/dashboard/admin/users'
import ROUTEMAP from '@shared/routemap.json'

export default {
  path: ROUTEMAP.admin.DASHBOARD,
  element: (
    <DashboardLayout>
      <Dashboard />
    </DashboardLayout>
  ),
  children: [
    {
      path: 'users',
      element: <Users />,
    },
    {
      path: 'admin',
      element: <Admin />,
    },
    { path: 'add-admin', element: <AddAdmin /> },
    {
      path: 'settings',
      element: <Settings />,
    },
  ],
} as RouteObject
