import { To, useNavigate } from 'react-router-dom'

import { cn } from '@shared/lib/utils'

import CheckIcon from '@/assets/images/user-dashboard/check.png'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'

const PlansAndPricing = () => {
  const navigate = useNavigate()
  const handleSubscribe = (route: To) => {
    navigate(route)
  }

  return (
    <>
      <div>
        <div className="flex justify-between items-center py-2 md:py-5">
          <div>
            <p className="text-[12px] md:text-lg font-semibold ">Plans & Pricings</p>
            <p className="text-[12px] md:text-base font-normal ">
              Upgrade your plans & pricings details here
            </p>
          </div>
          <div className="md:flex gap-4 hidden">
            <Button variant="outline" color="#0959FE">
              Cancel
            </Button>
            <Button variant="filled" color="#0959FE">
              Save
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-12 mt-5">
          {/* free */}
          <Card className={cn('col-span-12 mb-5 md:mr-5 md:col-span-4')}>
            <CardHeader>
              <h3 className="text-base md:text-2xl font-medium mb-2 md:mb-4">Starter</h3>
              <CardTitle className="text-lg md:text-4xl">Free</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <Button className="w-full mb-2" variant="outline" size="lg" disabled>
                Try for free
              </Button>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">90 days access</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">3 templates</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Unlimited customization</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as PDF</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as Docx</p>
                </div>
              </div>
            </CardContent>
          </Card>
          {/* classic */}
          <Card className={cn('col-span-12 mb-5 md:mr-5 md:col-span-4')}>
            <CardHeader>
              <h3 className="text-base md:text-2xl font-medium mb-2 md:mb-4">Classic</h3>
              <CardTitle className="text-lg md:text-4xl">
                $ 15<span className="text-xs md:text-lg font-normal"> / month</span>
              </CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <Button
                className="w-full mb-2"
                variant="filled"
                size="lg"
                onClick={() => handleSubscribe('/user/dashboard/subscribe-to-revamp')}
              >
                Upgrade
              </Button>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">90 days access</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">3 templates</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Unlimited customization</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as PDF</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as Docx</p>
                </div>
              </div>
            </CardContent>
          </Card>
          {/* Premium */}
          <Card className={cn('col-span-12 mb-5 md:col-span-4')}>
            <CardHeader>
              <h3 className="text-base md:text-2xl font-medium mb-2 md:mb-4">Premium</h3>
              <CardTitle className="text-lg md:text-4xl">
                $ 50<span className="text-xs md:text-lg font-normal"> / month</span>
              </CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <Button className="w-full mb-2" variant="outline" size="lg">
                Upgrade
              </Button>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">90 days access</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">3 templates</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Unlimited customization</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as PDF</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as Docx</p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="flex gap-5 my-10 md:hidden">
          <Button variant="outline" color="#0959FE" className="w-1/2">
            Cancel
          </Button>
          <Button variant="filled" color="#0959FE" className="w-1/2">
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default PlansAndPricing
