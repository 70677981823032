import { RouteObject } from 'react-router-dom'

import PageNotFound from '@pages/errors/not-found'
import { AppLayout } from '@shared/ui'

const routes: RouteObject = {
  path: '*',
  element: (
    <AppLayout padded>
      <PageNotFound />
    </AppLayout>
  ),
}

export default routes
