import { z } from 'zod'

const formSchema = z.object({
  fullName: z
    .string()
    .min(1, { message: 'Full name is required' })
    .regex(/^[a-zA-Z\s]*$/, { message: 'Full name must contain only letters' }),
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Must be a valid email' }),
  password: z
    .string()
    .min(6, { message: 'Password must be at least 6 characters' })
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/, {
      message:
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
    }),
  confirmPassword: z.string().min(1, { message: 'Confirm password is required' }),
})

formSchema.refine((data) => data.password === data.confirmPassword, {
  message: 'Passwords do not match',
  path: ['confirmPassword'],
})

export type IFormSchema = z.infer<typeof formSchema>
export default formSchema
