import { Navigate, RouteObject } from 'react-router-dom'

import CreateCV from '@pages/fastrack/create-cv'
import ReviewCV from '@pages/fastrack/review-cv'
import ROUTEMAP from '@shared/routemap.json'
import { AppLayout } from '@shared/ui'

const routes: RouteObject = {
  path: ROUTEMAP.cv['FAST-TRACK'],
  element: (
    <AppLayout padded maxWidth>
      <Navigate to="create" replace />
    </AppLayout>
  ),
  children: [
    {
      path: 'create',
      element: <CreateCV />,
    },
    {
      path: 'review',
      element: <ReviewCV />,
    },
  ],
}

export default routes
