import { useState } from 'react'
import { To, useNavigate } from 'react-router-dom'

import TableComponent from '@shared/ui/table'
import { ColumnDef } from '@tanstack/react-table'

import DeleteIcon from '@/assets/images/user-dashboard/delete.png'
import EditIcon from '@/assets/images/user-dashboard/fe_edit.png'
import { Button } from '@/components/ui/button'

export type ListOfAdmin = {
  id: string
  fullName: string
  country: string
  state: string
  phoneNumber: string
  role: string
  dateReg: string
  status: 'Pending' | 'Approved' | 'Rejected'
}
const listOfAdminData: ListOfAdmin[] = [
  {
    id: '1',
    fullName: 'Ikperi David',
    country: 'Egypt',
    state: 'Egypt',
    phoneNumber: '077 4952 5383 ',
    role: 'Deal Manager',
    dateReg: '24 May, 2020',
    status: 'Approved',
  },
  {
    id: '2',
    fullName: 'Ikperi David',
    country: 'Singapore',
    state: 'Singapore',
    phoneNumber: '077 4952 5383 ',
    role: 'Representative',
    dateReg: '8 Mar, 2020',
    status: 'Pending',
  },
  {
    id: '3',
    fullName: 'Ikperi David',
    country: 'Sweden',
    state: 'Sweden',
    phoneNumber: '077 4952 5383 ',
    role: 'Co-Admin',
    dateReg: '24 May, 2020',
    status: 'Rejected',
  },
  {
    id: '4',
    fullName: 'Ikperi David',
    country: 'Egypt',
    state: 'Egypt',
    phoneNumber: '077 4952 5383 ',
    role: 'Deal Manager',
    dateReg: '24 May, 2020',
    status: 'Approved',
  },
  {
    id: '5',
    fullName: 'Ikperi David',
    country: 'Egypt',
    state: 'Egypt',
    phoneNumber: '077 4952 5383 ',
    role: 'Deal Manager',
    dateReg: '24 May, 2020',
    status: 'Approved',
  },
  {
    id: '6',
    fullName: 'Ikperi David',
    country: 'Egypt',
    state: 'Egypt',
    phoneNumber: '077 4952 5383 ',
    role: 'Deal Manager',
    dateReg: '24 May, 2020',
    status: 'Pending',
  },
  {
    id: '7',
    fullName: 'Ikperi David',
    country: 'Egypt',
    state: 'Egypt',
    phoneNumber: '077 4952 5383 ',
    role: 'Deal Manager',
    dateReg: '24 May, 2020',
    status: 'Approved',
  },
  {
    id: '8',
    fullName: 'Ikperi David',
    country: 'Egypt',
    state: 'Egypt',
    phoneNumber: '077 4952 5383 ',
    role: 'Deal Manager',
    dateReg: '24 May, 2020',
    status: 'Approved',
  },
  {
    id: '9',
    fullName: 'Ikperi David',
    country: 'Egypt',
    state: 'Egypt',
    phoneNumber: '077 4952 5383 ',
    role: 'Deal Manager',
    dateReg: '24 May, 2020',
    status: 'Rejected',
  },
  {
    id: '10',
    fullName: 'Ikperi David',
    country: 'Egypt',
    state: 'Egypt',
    phoneNumber: '077 4952 5383 ',
    role: 'Deal Manager',
    dateReg: '24 May, 2020',
    status: 'Approved',
  },
]
const listOfAdminColumns: ColumnDef<ListOfAdmin>[] = [
  {
    accessorKey: 'id',
    header: () => <div>#</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('id')}</p>,
  },
  {
    accessorKey: 'fullName',
    header: () => <div>Full Name</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('fullName')}</p>,
  },
  {
    accessorKey: 'country',
    header: () => <div>Country</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('country')}</p>,
  },
  {
    accessorKey: 'state',
    header: () => <div>State</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('state')}</p>,
  },
  {
    accessorKey: 'phoneNumber',
    header: () => <div>Phone Number</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('phoneNumber')}</p>,
  },
  {
    accessorKey: 'role',
    header: () => <div>Role</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('role')}</p>,
  },
  {
    accessorKey: 'dateReg',
    header: () => <div>Date Reg</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('dateReg')}</p>,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      let statusClass = ''

      if (row.getValue('status') === 'Approved') {
        statusClass = 'text-[#2CCF54] bg-[#2CCF541A] px-1 text-center'
      } else if (row.getValue('status') === 'Pending') {
        statusClass = 'text-[#FFC107] bg-[#FFC1071A] px-1 text-center'
      } else if (row.getValue('status') === 'Rejected') {
        statusClass = 'text-[#EB001B] bg-[#EB001B1A] px-1 text-center'
      }

      return <div className={`capitalize ${statusClass}`}>{row.getValue('status')}</div>
    },
  },
  {
    id: 'actions',
    header: 'Action',
    cell: () => {
      const [isModalOpen, setIsModalOpen] = useState(false)

      const handleLogoutClick = () => {
        setIsModalOpen(true)
      }
      const handleCloseModal = () => {
        setIsModalOpen(false)
      }

      return (
        <>
          <div className="flex gap-1 md:gap-3">
            <img src={EditIcon} alt="edit-icon" className="w-[8px] md:w-[20px]" />
            <button onClick={handleLogoutClick}>
              <img src={DeleteIcon} alt="delete-icon" className="w-[8px] md:w-[20px]" />
            </button>
          </div>
          {isModalOpen && (
            <div
              className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50"
              onClick={handleCloseModal}
              aria-hidden="true"
            >
              <div
                className="relative w-1/2 p-6 bg-white rounded-lg shadow-lg"
                aria-hidden="true"
                onClick={(e) => e.stopPropagation()}
              >
                <h3 className="text-3xl text-center text-[#0F1022] font-semibold mb-4">
                  You are about to delete this admin account, are you sure you want to proceed?
                </h3>
                <div className="flex justify-center gap-4">
                  <Button variant="filled" color="#0959FE" onClick={handleCloseModal}>
                    Yes
                  </Button>
                  <Button
                    variant="outline"
                    color="#0959FE"
                    onClick={() => {
                      handleCloseModal()
                    }}
                  >
                    No
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )
    },
  },
]
const Admin = () => {
  const navigate = useNavigate()

  const handleButtonClick = (route: To) => {
    navigate(route)
  }

  return (
    <TableComponent<ListOfAdmin>
      data={listOfAdminData}
      columns={listOfAdminColumns}
      title="List of admin created"
      filterPlaceholder="Search for job..."
      createButtonText="Add Admin"
      showCreateButton={true}
      createButtonVariant="filled"
      onButtonClick={() => handleButtonClick('/admin/dashboard/add-admin')}
    />
  )
}

export default Admin
