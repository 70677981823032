import sessionSlice from '@entities/session/model/slice'

type IFormField = {
  name: string
  label: string
  placeholder: string
  type: string
  value?: string
  accept?: string
}

const RESUME_FORM_FIELDS: Record<'branch_a' | 'branch_b' | 'branch_c', IFormField[]> = {
  branch_a: [
    {
      name: 'firstName',
      label: 'First Name',
      placeholder: 'First Name',
      type: 'text',
      value: sessionSlice.user?.firstName,
    },
    {
      name: 'lastName',
      label: 'Last Name',
      placeholder: 'Last Name',
      type: 'text',
      value: sessionSlice.user?.lastName,
    },
    {
      name: 'email',
      label: 'Email Address',
      placeholder: 'Email Address',
      type: 'email',
      value: sessionSlice.user?.email || '',
    },
    {
      name: 'phoneNumber',
      label: 'Phone Number',
      placeholder: 'Phone Number',
      type: 'text',
    },
  ],
  branch_b: [
    {
      name: 'profession',
      label: 'Profession',
      placeholder: 'Profession',
      type: 'text',
    },
    {
      name: 'yearsOfExperience',
      label: 'Years of Experience',
      placeholder: 'Years of Experience',
      type: 'number',
    },
    {
      name: 'birthDate',
      label: 'Birth Date',
      placeholder: 'Birth Date',
      type: 'date',
    },
  ],
  branch_c: [
    {
      name: 'resume',
      label: 'Upload Your CV',
      placeholder: 'Upload Your CV',
      type: 'file',
      accept: '.pdf,.doc,.docx',
    },
  ],
}

export default RESUME_FORM_FIELDS
