import { Textify } from '@shared/lib'
import { Icon } from '@shared/ui'
import clsx from 'clsx'
import AutoScroll from 'embla-carousel-auto-scroll'
import useEmblaCarousel from 'embla-carousel-react'

type TestimonialCardProps = {
  name: string
  rating: number
  review: string
  date?: string
  className?: string
}

const randomKeys = Array.from({ length: 5 }).map(() => Textify.generateRandomString(3))

const TestimonialCard = ({ name, rating, review, date, className }: TestimonialCardProps) => {
  return (
    <div
      className={clsx('bg-white rounded-md border min-w-[400px] p-5 mx-3 embla__slide', className)}
    >
      <div className="flex items-center gap-1">
        {Array.from({ length: 5 }).map((_, i) => (
          <Icon
            key={randomKeys[i]}
            icon="star"
            size="sm"
            color={i + 1 <= rating ? 'yellow-400' : 'gray-300'}
          />
        ))}
      </div>
      <div className="flex flex-col gap-2 mt-5">
        <h5 className="font-medium text-md">{name}</h5>
        <q className="text-sm">{review}</q>
        {date && <p className="mt-5 ml-auto text-xs text-current-500 w-fit">{date}</p>}
      </div>
    </div>
  )
}

const testimonialsRow1 = [
  {
    name: 'Innocent, Graphic Designer',
    rating: 5,
    review:
      'As someone switching careers, I needed to highlight my transferable skills. Revamp Insights made that easy and got me noticed!',
    date: '15 May 2020 9:00 am',
  },
  {
    name: 'Titi, Customer Support',
    rating: 4,
    review: 'Great templates for creating industry-specific CVs!',
    date: '15 May 2020',
  },
  {
    name: 'Daniel, Brand Communications Specialist',
    rating: 4,
    review:
      'I was unsure how to organize my experience, but Revamp Insights guided me. It made all the difference in my job search!',
    date: '15 May 2020 9:00 am',
  },
  {
    name: 'Seyi, PR Specialist',
    rating: 5,
    review:
      'I received more callbacks after using Revamp Insights to update my CV. The platform is simple yet effective for career advancement.',
    date: '15 May 2020 9:00 am',
  },
]

const testimonialsRow2 = [
  {
    name: 'kofi, Software Engineer',
    rating: 4,
    review:
      "Wow what great service, I love it! It's is the most valuable business resource we have EVER purchased. We can't understand how we've been living without it.",
    date: '15 May 2020',
  },
  {
    name: 'kofi, Software Engineer',
    rating: 5,
    review:
      "Wow what great service, I love it! It's is the most valuable business resource we have EVER purchased. We can't understand how we've been living without it.",
    date: '5 Sep 2021',
  },
  {
    name: 'kofi, Software Engineer',
    rating: 4,
    review:
      'The CV tool was incredibly easy to use. I now have a professional, polished CV that has opened a new opportunities for me',
    date: '18 Oct 2021',
  },
  {
    name: 'Amina, HR Consultant',
    rating: 4,
    review:
      "With Revamp Insights, I didn't need to worry about the formatting or structure of my CV. It’s straightforward, professional, and gets results.",
    date: '18 Oct 2021',
  },
]

export default function TestimonialsSection() {
  const [emblaRow1Ref] = useEmblaCarousel({ loop: true, active: true }, [
    AutoScroll({ direction: 'forward' }),
  ])
  const [emblaRow2Ref] = useEmblaCarousel({ loop: true, active: true }, [
    AutoScroll({ direction: 'backward' }),
  ])

  return (
    <div className="py-16 bg-white">
      {/* Two rows auto scroll animated testimonials */}
      <section className="max-w-[900px] mx-auto px-5">
        <div className="flex flex-col gap-3">
          <h4 className="text-2xl font-medium text-center md:text-4xl">Testimonials</h4>
          <p className="text-center text-current-500">
            Here are what some of our users have to say about us
          </p>
        </div>
        <div className="flex flex-col gap-5 mt-5 md:mt-10">
          {/* Animate auto scroll from left to right */}
          <div className="embla">
            <div className="overflow-x-hidden embla__viewport" ref={emblaRow1Ref}>
              <div className="flex py-5 embla__container">
                {testimonialsRow1.map((testimonial, i) => (
                  <TestimonialCard
                    key={i}
                    name={testimonial.name}
                    rating={testimonial.rating}
                    review={testimonial.review}
                    date={testimonial.date}
                  />
                ))}
              </div>
            </div>
          </div>
          {/* Animate auto scroll from right to left */}
          <div className="embla">
            <div className="overflow-x-hidden embla__viewport" ref={emblaRow2Ref}>
              <div className="flex py-5 embla__container">
                {testimonialsRow2.map((testimonial, i) => (
                  <TestimonialCard
                    key={i}
                    name={testimonial.name}
                    rating={testimonial.rating}
                    review={testimonial.review}
                    date={testimonial.date}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
