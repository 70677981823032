import { Button, Icon } from '@shared/ui'

import { Plan } from './plans'

type Props = Plan & {
  onSubscribe?: () => Promise<void>
}

const PricingPlanCard = ({ title, price, features, onSubscribe }: Props) => {
  return (
    <div className="bg-white rounded-md p-5 border">
      <h6 className="text-md font-medium">{title}</h6>
      <h3 className="text-2xl font-semibold my-5">
        {typeof price === 'string' ? price : `$${price}`}
        {typeof price === 'number' && (
          <span className="text-sm font-normal text-current-500">/month</span>
        )}
      </h3>
      <Button variant="primary" size="md" className="w-full" onClick={onSubscribe}>
        Get Started
      </Button>
      <ul className="mt-8 list-none flex flex-col gap-3">
        {features.map(({ isSupported, name }, i) => (
          <li key={i} className="flex items-center gap-2">
            <Icon
              icon={isSupported ? 'check' : 'close'}
              size="sm"
              color={isSupported ? 'primary-900' : 'red-500'}
            />
            <span className="text-sm">{name}</span>
          </li>
        ))}
      </ul>
    </div>
  )
}

export type PricingPlanCardProps = Props
export default PricingPlanCard
