import { type ReactNode } from 'react'

import clsx from 'clsx'

import { Icon } from '@/shared/ui'

type Props = {
  onClick?: React.MouseEventHandler<HTMLButtonElement>
  children: ReactNode
  variant?: 'primary' | 'secondary' | 'outline'
  color?: string
  size?: 'sm' | 'md' | 'lg'
  className?: string
  type?: 'button' | 'submit' | 'reset'
  isLoading?: boolean
  disabled?: boolean
}

const Button = ({
  onClick,
  children,
  className,
  color,
  variant = 'primary',
  size = 'sm',
  type = 'button',
  isLoading = false,
  disabled = false,
}: Props) => {
  return (
    <button
      type={type}
      onClick={onClick}
      className={clsx(
        'flex items-center justify-center rounded-md focus:outline-none transition-all duration-200',
        {
          'bg-primary-900 text-white hover:bg-primary-800': variant === 'primary',
          'bg-secondary-900 text-white hover:bg-secondary-800': variant === 'secondary',
          [`bg-transparent border border-${color || 'secondary-200'} text-${color || 'secondary-900'} hover:border-${color || 'primary-50'}`]:
            variant === 'outline',
          'px-4 py-2 text-sm': size === 'sm',
          'px-6 py-3 text-base': size === 'md',
          'px-8 py-4 text-lg': size === 'lg',
          'opacity-50 cursor-not-allowed': disabled || isLoading,
        },
        className,
      )}
      disabled={disabled || isLoading}
    >
      {isLoading && (
        <Icon icon="circle-notch" style="solid" size="md" animation="spin" className="mr-2" />
      )}{' '}
      {children}
    </button>
  )
}

export type IButtonProps = Props
export default Button
