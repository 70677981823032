import { forwardRef } from 'react'

import { RankedList } from '@shared/ui'
import { CVTemplateProps } from '@widgets/types'
import clsx from 'clsx'

const Template3 = forwardRef<HTMLDivElement, CVTemplateProps>(
  ({ className, cv }: CVTemplateProps, ref) => {
    return (
      <div
        ref={ref}
        className={clsx('flex gap-2 items-start justify-between text-[#727176]', className)}
      >
        <div className="flex flex-col gap-2 w-1/2 bg-[#f5f5f5] h-screen">
          <header className="pt-5">
            <h3 className="text-lg lg:text-xl font-bold uppercase">
              <span className="text-[#f58634]">{cv?.firstName}</span>
              <br />
              <span>{cv?.lastName}</span>
            </h3>
            <p className="text-xs lg:text-sm font-semibold">{cv?.profession}</p>
          </header>
          <main className="flex flex-col gap-2 p-3 pt-0 -mt-7 z-10">
            <section>
              <h5 className="text-sm font-bold text-[#f58634]">CONTACT</h5>
              <p className="text-xs font-light">{cv?.randomPhoneNumber}</p>
              <p className="text-xs font-light">{cv?.randomEmail}</p>
              <p className="text-xs font-light">{cv?.formattedAddress}</p>
            </section>
            <section>
              <h5 className="text-sm font-bold text-[#f58634]">PROFILE</h5>
              <p className="text-xs font-light">{cv?.summary}</p>
            </section>
            <section>
              <h5 className="text-sm font-bold text-[#f58634]">WORK EXPERIENCE</h5>
              <div className="flex flex-col gap-2">
                {cv?.workExperience
                  .filter((exp) => !exp.isVolunteerRole)
                  .map((exp, index) => (
                    <div key={index}>
                      <div className="mb-3">
                        <h5 className="text-xs font-light">
                          <span>
                            {exp.organization},{' '}
                            {exp.location?.remote
                              ? 'Remote'
                              : exp.location?.city + ', ' + exp.location?.country}
                          </span>{' '}
                          - <span>{exp.title}</span>
                        </h5>
                        <p className="text-xs font-light">{exp.duration}</p>
                      </div>
                      <div className="text-xs font-light">{exp.description}</div>
                    </div>
                  ))}
              </div>
            </section>
          </main>
        </div>
        <div className="flex flex-col gap-2 w-1/2 grow my-4 pr-2">
          <section>
            <h5 className="text-sm font-bold text-[#f58634]">VOLUNTEERING</h5>
            <div className="flex flex-col gap-2">
              {cv?.workExperience
                .filter((exp) => exp.isVolunteerRole)
                .map((exp, index) => (
                  <div key={index}>
                    <div className="mb-3">
                      <h5 className="text-xs font-light">
                        <span>
                          {exp.organization},{' '}
                          {exp.location?.remote
                            ? 'Remote'
                            : exp.location?.city + ', ' + exp.location?.country}
                        </span>{' '}
                        - <span>Volunteer</span>
                      </h5>
                      <p className="text-xs font-light">{exp.duration}</p>
                    </div>
                    <div className="text-xs font-light">{exp.description}</div>
                  </div>
                ))}
            </div>
          </section>
          <section>
            <h5 className="text-sm font-bold text-[#f58634] mb-2">EDUCATION</h5>
            {cv?.education.map((edu, index) => (
              <div className="text-xs font-light uppercase" key={index}>
                <p>
                  <span>{edu.organization}</span> -{' '}
                  <span>
                    {edu.accreditation.fieldOfStudy}, {edu.accreditation.degree}
                  </span>
                </p>
                <p>
                  {edu.dates?.from} - {edu.dates?.isCurrent ? 'Present' : edu.dates?.to}
                </p>
              </div>
            ))}
          </section>
          <section>
            <h5 className="text-sm font-bold text-[#f58634]">SKILLS</h5>
            <RankedList
              items={cv?.skills.map((skill) => skill.name) || []}
              className={(rank, index) =>
                clsx(rank >= index ? 'bg-[#f58634]' : 'bg-white', `w-[${100 / 7}%]`)
              }
              css={{ rankbar: 'w-6/12 border border-secondary-200 bg-white gap-0' }}
            />
          </section>
          <section>
            <h5 className="text-sm font-bold text-[#f58634]">HOBBIES</h5>
            <div className="flex flex-col text-sm font-light">
              <p>Reading</p>
              <p>Traveling</p>
              <p>Photography</p>
            </div>
          </section>
          <section>
            <h5 className="text-sm font-bold text-[#f58634]">LANGUAGES</h5>
            <RankedList
              items={['English', 'Spanish', 'French', 'German', 'Italian']}
              className={(rank, index) =>
                clsx('grow', rank >= index ? 'bg-[#f58634]' : 'bg-white', `w-[${100 / 7}%]`)
              }
              css={{ rankbar: 'w-6/12 border border-secondary-200 bg-white gap-0' }}
            />
          </section>
        </div>
      </div>
    )
  },
)

Template3.displayName = 'Template3'
export default Template3
