import { toast } from 'react-toastify'

import {
  AllStepsCVDataKey,
  IEducation,
  ILanguage,
  IWorkHistory,
  allStepsCVData,
} from '@features/build-cv/assisted/model'
import { Textify } from '@shared/lib'
import { Button, Icon } from '@shared/ui'
import CountLabel from '@shared/ui/detailed-steps/count-label'

type Props = {
  dataKey: AllStepsCVDataKey
  getSummary: (item: string | IWorkHistory | IEducation | ILanguage) => React.ReactNode
}

export default function List({ dataKey, getSummary }: Props) {
  const data = allStepsCVData[dataKey].get()
  if (!Array.isArray(data) || data.length === 0) return null

  const remove = (idx: number) => {
    allStepsCVData[dataKey].remove(idx)
    toast.info(`${Textify.toSpacedWords(dataKey)} removed`, {
      autoClose: 1000,
    })
  }

  return (
    <div>
      <hr />
      <h4 className="text-md font-medium mt-5 mb-2">
        {Textify.toTitleCase(Textify.toSpacedWords(dataKey))}
      </h4>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-2">
        {data.map((item, idx: number) => (
          <div
            key={idx}
            className="flex items-center gap-2 border p-3 rounded-md bg-white relative"
          >
            <Button
              className="absolute top-0 right-0 m-2 pl-0 pr-0 pt-0 pb-0 text-xs border-none"
              variant="outline"
              onClick={() => remove(idx)}
            >
              <Icon icon="times" color="current-900" size="md" />
            </Button>
            <CountLabel count={idx + 1} size="sm" />
            {getSummary(item)}
          </div>
        ))}
      </div>
    </div>
  )
}
