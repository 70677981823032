import { Instance, types } from 'mobx-state-tree'

const Snapshot = types.model({
  id: types.identifier,
  url: types.string,
})

const Snapshots = types
  .model('Snapshots', {
    key: types.identifier,
    items: types.optional(types.array(Snapshot), []),
  })
  .actions((self) => ({
    isValidUrl(url: string) {
      return /data:image\/[a-z]+;base64,/.test(url)
    },

    addSnapshot(id: string, url: string) {
      const hasItem = self.items.find((item) => item.id === id)
      if (!hasItem) {
        self.items.push({ id, url })
        return true
      }

      return false
    },

    removeSnapshot(id: string) {
      const index = self.items.findIndex((i) => i.id === id)
      if (index !== -1) {
        self.items.splice(index, 1)
        return true
      }

      return false
    },

    clear() {
      self.items.clear()
    },

    updateSnapshot(id: string, newURL: string) {
      const index = self.items.findIndex((i) => i.id === id)
      if (index !== -1) {
        self.items[index].url = newURL
        return true
      }

      return false
    },

    getSnapshot(id: string): { id: string; url: string } | null {
      return self.items.find((i) => i.id === id) || null
    },
  }))
  .views((self) => ({
    get count() {
      return self.items.length
    },

    get isEmpty() {
      return self.items.length === 0
    },

    get first() {
      return self.items[0]
    },

    get last() {
      return self.items[self.items.length - 1]
    },

    get all() {
      return self.items
    },
  }))

export type ISnapshots = Instance<typeof Snapshots>
export default Snapshots
