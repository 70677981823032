import { useEffect } from 'react'

const useAutoScroll = (
  ref: React.RefObject<HTMLElement>,
  currentId: string,
  callbackUpdate: () => void,
  direction: 'horizontal' | 'vertical' = 'horizontal',
) => {
  const scroll = () => {
    const container = ref.current
    if (!container) return

    const element = container.querySelector<HTMLElement>(`#${currentId}`)
    if (!element) return

    if (direction === 'horizontal') {
      container.scrollLeft = Math.ceil(
        element.offsetLeft - container.offsetWidth / 2 + element.offsetWidth / 2,
      )
    } else {
      container.scrollTop = Math.ceil(
        element.offsetTop - container.offsetHeight / 2 + element.offsetHeight / 2,
      )
    }
  }

  useEffect(() => {
    const interval = setInterval(callbackUpdate, 5000)
    return () => clearInterval(interval)
  }, [])

  useEffect(() => {
    scroll()
  }, [currentId])
}

export default useAutoScroll
