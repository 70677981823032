import { Instance, types } from 'mobx-state-tree'

const Address = types.model('Address', {
  streetNumber: types.maybeNull(types.string),
  street: types.maybeNull(types.string),
  city: types.maybeNull(types.string),
  state: types.string,
  country: types.string,
  countryCode: types.maybeNull(types.string),
})

const Location = types.compose(
  Address,
  types.model('Location', {
    remote: types.boolean,
    apartmentNumber: types.maybe(types.string),
    coords: types.maybe(
      types.model('Coords', {
        latitude: types.number,
        longitude: types.number,
      }),
    ),
    postalCode: types.maybe(types.string),
  }),
)

type IAddress = Instance<typeof Address>
type ILocation = Instance<typeof Location>

export type { IAddress, ILocation }
export { Address }
export default Location
