// import MissionAndVisionImage from '@/assets/images/base/a_conference_room.svg'
import { Button, InputBox } from '@shared/ui'

import BGIMage from '@/assets/images/base/man_with_headphone.svg'
import MessageBox from '@/assets/images/base/message_box.svg'

import CTACard from './common/cta-card'

export default function ContactUs() {
  return (
    <main className="min-h-screen py-10 bg-white">
      <div className="max-w-[900px] mx-auto px-5 md:px-2">
        <section className="md:w-1/2">
          <h4 className="text-3xl font-bold md:text-4xl">
            How can we <span className="text-primary-900">help</span> you?
          </h4>
          <p className="mt-5 mb-3 md:text-lg text-current-500">
            Reach out to us by dropping a message and let us know how we can help you.
          </p>
        </section>
        <div
          className="mx-2 md:mx-auto max-w-[900px] min-h-80 rounded-md px-5 py-10 mb-5 md:mb-16 text-white text-center bg-blend-darken  bg-no-repeat bg-cover"
          style={{ backgroundImage: `url(${BGIMage})` }}
        ></div>
        <section className="flex flex-col-reverse justify-between gap-5 mt-5 mb-5 ml-0 md:mb-16 md:items-end md:ml-20 ps-2 md:border md:flex-row ">
          <div className="flex-1 hidden md:block">
            <img
              src={MessageBox}
              alt="A conference room containing a team discussing a strategy together."
              className="object-contain p-5"
            />
            <p className="mt-20 md:text-lg">Email Address</p>
            <p className="text-[#0959fe]">admin@revampinsights.com</p>
          </div>
          <div className="bg-[#0959FE] py-5 px-7 md:px-14 w-full ">
            <div className="ps-3">
              <h4 className="text-2xl font-bold text-white md:text-3xl">Contact Us</h4>
              <p className="mt-1 mb-3 text-white md:text-lg">
                Drop a message and let us get in touch
              </p>
            </div>
            <div>
              <InputBox label="Name" placeholder="Name" type="text" id="same" />
            </div>
            <div className="mt-3">
              <InputBox label="Email Address" placeholder="Email Address" type="email" id="email" />
            </div>
            <div className="mt-3">
              <InputBox label="Subject" placeholder="Subject" type="text" id="subject" />
            </div>
            <div className="mt-10 ">
              <InputBox placeholder="Password" rows={8} type="textarea" id="password" />
            </div>
            <div className="mt-10">
              <Button
                className="w-full md:w-80 md:m-auto bg-[#000714] hover:bg-[#000714]"
                color=""
                size="md"
              >
                Send
              </Button>
            </div>
          </div>
        </section>
        <CTACard />
      </div>
    </main>
  )
}
