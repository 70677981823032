import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import { Step } from '@shared/types'

/**
 * Get the step with the given index
 * @returns information about the current step and functions to navigate between steps
 */
const useStep = (steps: Step[]) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [stepId, setStepId] = useState<number>(0)

  // step functions
  const stepBack = () => {
    setStepId((prev) => {
      return prev - 1 < 0 ? prev : prev - 1
    })
  }
  const stepNext = () => {
    setStepId((prev) => {
      return prev + 1 >= steps.length ? prev : prev + 1
    })
  }

  const updateLocation = () => {
    navigate(`${location.pathname}?step=${steps[stepId].id}`, {
      replace: true,
      state: { isFinalStep: stepId === steps.length - 1 },
    })
  }

  useEffect(() => {
    const currentStep = new URLSearchParams(location.search).get('step')
    if (currentStep) {
      if (currentStep !== steps[stepId].id) {
        const idx = steps.findIndex((s) => s.id === currentStep)
        setStepId(idx !== -1 ? idx : 0)
      }
    } else {
      updateLocation()
    }
  }, [])

  useEffect(() => {
    const currentStep = new URLSearchParams(location.search).get('step')
    if (steps[stepId].id !== currentStep) {
      updateLocation()
    }
  }, [stepId])

  return { stepId, step: steps[stepId], stepBack, stepNext }
}

export default useStep
