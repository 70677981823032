import clsx from 'clsx'

type Props = {
  showText?: boolean
  clickable?: boolean
  colored?: boolean
  className?: string
  type?:
    | string
    | React.FC<{
        className?: string
        onClick?: () => void
        children: React.ReactNode
      }>
  css?: {
    head?: string
    tail?: string
  }
}

const AppLogo = ({
  type,
  className,
  css,
  showText = true,
  clickable = false,
  colored = true,
}: Props) => {
  const Logo = clickable ? 'a' : type || 'div'

  return (
    <Logo
      className={clsx('flex', className, { 'cursor-pointer': clickable })}
      onClick={clickable ? () => window.location.replace('/') : undefined}
    >
      <img
        src={colored ? '/icons/logo.svg' : '/icons/colorless-logo.svg'}
        alt="logo"
        className={clsx('h-8 w-8 self-start', css?.head)}
      />
      {showText && <span className={clsx('text-lg font-bold self-end', css?.tail)}>evamp</span>}
    </Logo>
  )
}

export type IAppLogoProps = Props
export default AppLogo
