import { toast } from 'react-toastify'

import { AxiosError, AxiosResponse } from 'axios'

const submit = async <T>(
  p: Promise<AxiosResponse>,
  onComplete: (data: T) => unknown,
): Promise<string | void> => {
  try {
    const res = await p
    console.log(res)
    if (res.status >= 200 && res.status < 300) {
      onComplete(res.data)
      toast.success(res.data.message)
    }
  } catch (e) {
    const { response, message } = e as AxiosError
    return (response?.data as { message?: string })?.message || message
  }
}

export default submit
