const TermsOfUse = () => {
  return (
    <div className="w-full h-full pt-12 bg-white md:pt-24">
      <div className="max-w-[1000px] mx-auto ">
        <h3 className="mb-6 text-base font-medium md:text-2xl md:mb-12">TERMS OF USE</h3>
        <p className="mb-5 md:mb-10">
          Welcome to Revamp Insights. By accessing or using our platform, you agree to comply with
          and be bound by the following terms and conditions, as well as any additional guidelines,
          terms, and policies referenced herein. Please read these terms carefully before using the
          platform. If you do not agree to these terms, you may not use the platform.
        </p>
        <ol className="text-xs font-medium list-decimal md:text-lg">
          <li className="mb-5 md:mb-10">
            Acceptance of Terms
            <p className="text-xs font-normal md:text-base">
              By accessing or using Revamp Insights, you accept these Terms of Use. These terms
              apply to all visitors, users, and others who access or use the platform. If you are
              using Revamp Insights on behalf of an organization, you agree to these terms on behalf
              of that organization.
            </p>
          </li>

          <li className="mb-5 md:mb-10">
            Eligibility
            <p className="text-xs font-normal md:text-base">
              To use Revamp Insights, you must be at least 18 years old or the age of legal majority
              in your jurisdiction, whichever is greater. By using the platform, you represent and
              warrant that you meet this eligibility requirement.
            </p>
          </li>
          <li className="mb-5 md:mb-10">
            User Accounts
            <p className="text-xs font-normal md:text-base">
              To access certain features of Revamp Insights, you may be required to create an
              account. You agree to:
            </p>
            <ul className="list-disc ms-2 md:ms-4">
              <li className="text-xs font-normal md:text-base">
                Provide accurate, current, and complete information during the registration process.
              </li>
              <li className="text-xs font-normal md:text-base">
                Maintain the security of your account by not sharing your password with others.
              </li>
              <li className="text-xs font-normal md:text-base">
                Take responsibility for any activities or actions under your account.
              </li>
              <li className="text-xs font-normal md:text-base">
                Notify us immediately of any unauthorized use of your account.
              </li>
            </ul>
          </li>
          <li className="mb-5 md:mb-10">
            Use of Platform
            <p className="text-xs font-normal md:text-base">
              You agree to use Revamp Insights only for lawful purposes and in accordance with these
              Terms of Use. You agree not to:
            </p>
            <ul className="list-disc ms-2 md:ms-4">
              <li className="text-xs font-normal md:text-base">
                Use the platform in any way that violates any applicable local, national, or
                international law.
              </li>
              <li className="text-xs font-normal md:text-base">
                Use the platform to engage in fraudulent, misleading, or unlawful activities.
              </li>
              <li className="text-xs font-normal md:text-base">
                Attempt to interfere with the operation or security of the platform.
              </li>
              <li className="text-xs font-normal md:text-base">
                Use any automated means to access the platform or any data contained within it.
              </li>
            </ul>
          </li>
          <li className="mb-5 md:mb-10">
            Content Ownership and Use
            <p className="text-xs font-normal md:text-base">
              Revamp Insights provides a platform for users to access content such as job listings,
              CV optimization tools, educational resources, and more. All content provided by Revamp
              Insights is owned by us or our licensors and is protected by intellectual property
              laws.
            </p>
            <p className="text-xs font-normal md:text-base">
              You may not copy, distribute, modify, or create derivative works from any content
              without our prior written consent. You retain ownership of any content you submit to
              the platform, but by submitting content, you grant Revamp Insights a worldwide,
              royalty-free, non-exclusive, transferable license to use, distribute, modify, and
              display your content in connection with the platform.
            </p>
          </li>
          <li className="mb-5 md:mb-10">
            Third-Party Links and Services
            <p className="text-xs font-normal md:text-base">
              Revamp Insights may contain links to third-party websites or services that are not
              owned or controlled by us. We are not responsible for the content, privacy policies,
              or practices of any third-party sites or services. You agree to access third-party
              content at your own risk and to read their terms of use and privacy policies before
              using their services.
            </p>
          </li>
          <li className="mb-5 md:mb-10">
            Termination
            <p className="text-xs font-normal md:text-base">
              We may terminate or suspend your access to Revamp Insights immediately, without prior
              notice or liability, for any reason, including but not limited to if you breach these
              Terms of Use. Upon termination, your right to use the platform will cease, but the
              provisions of these Terms that by their nature should survive termination shall remain
              in effect.
            </p>
          </li>
          <li className="mb-5 md:mb-10">
            Limitation of Liability
            <p className="text-xs font-normal md:text-base">
              Revamp Insights is provided on an &quot;as is&quot; and &quot;as available&quot; basis
              without any warranties, express or implied. We do not guarantee the accuracy,
              completeness, or timeliness of any information on the platform. To the maximum extent
              permitted by law, we disclaim any liability for any indirect, incidental, or
              consequential damages arising from your use of the platform.
            </p>
          </li>
          <li className="mb-5 md:mb-10">
            Indemnification
            <p className="text-xs font-normal md:text-base">
              You agree to indemnify, defend, and hold harmless Revamp Insights, its affiliates,
              directors, employees, and agents from and against any claims, liabilities, damages,
              losses, and expenses (including reasonable legal fees) arising out of your use of the
              platform, violation of these Terms of Use, or violation of any third-party rights.
            </p>
          </li>
          <li className="mb-5 md:mb-10">
            Modifications to the Terms
            <p className="text-xs font-normal md:text-base">
              We reserve the right to modify these Terms of Use at any time. Any changes will be
              effective immediately upon posting the revised terms on the platform. Your continued
              use of Revamp Insights after such changes will constitute your acceptance of the
              revised terms.
            </p>
          </li>
          <li className="mb-5 md:mb-10">
            Governing Law
            <p className="text-xs font-normal md:text-base">
              These Terms of Use shall be governed by and construed in accordance with the laws of
              Nigeria, without regard to its conflict of law provisions. Any disputes arising out of
              or related to these terms or the platform shall be resolved exclusively in the courts
              located within Nigeria.
            </p>
          </li>
          <li className="mb-5 md:mb-10">
            Contact Us
            <p className="text-xs font-normal md:text-base">
              If you have any questions or concerns about these Terms of Use, please contact us at
            </p>
            <p className="text-xs font-normal md:text-base">support@revampinsights.com.</p>
          </li>
        </ol>
      </div>
    </div>
  )
}

export default TermsOfUse
