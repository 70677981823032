import clsx from 'clsx'

export default function DottedLinesHorizontal({
  // color,
  horizontal,
  className,
}: {
  // color: string
  horizontal?: boolean
  className?: string
}) {
  return (
    <div
      className={clsx(
        'flex-1',
        // 'border-' + color,
        {
          'border-t-2 w-full': horizontal,
          'border-l-2 w-0 min-h-8 transform translate-x-1/2': !horizontal,
        },
        className,
      )}
    />
  )
}
