import { Link, useLocation } from 'react-router-dom'

import ROUTEMAP from '@shared/routemap.json'

type FooterContent = {
  question: string
  hypertext: string
  route: string
}

const FOOTER_CONTENT: Record<string, FooterContent> = {
  [ROUTEMAP.auth.LOGIN]: {
    question: "Don't have an account?",
    hypertext: 'Sign Up',
    route: ROUTEMAP.auth.SIGNUP,
  },
  [ROUTEMAP.auth.SIGNUP]: {
    question: 'Already have an account?',
    hypertext: 'Log In',
    route: ROUTEMAP.auth.LOGIN,
  },
  [ROUTEMAP.auth.FORGOT_PASSWORD]: {
    question: 'Remember your password?',
    hypertext: 'Log In',
    route: ROUTEMAP.auth.LOGIN,
  },
  [ROUTEMAP.auth.CHECK_EMAIL]: {
    question: 'Remember your password?',
    hypertext: 'Log In',
    route: ROUTEMAP.auth.LOGIN,
  },
  [ROUTEMAP.auth.RESET_PASSWORD]: {
    question: 'Back to',
    hypertext: 'Log In',
    route: ROUTEMAP.auth.LOGIN,
  },
}

FOOTER_CONTENT['/auth'] = FOOTER_CONTENT[ROUTEMAP.auth.LOGIN]

const Footer = () => {
  const location = useLocation()
  if (!Object.keys(FOOTER_CONTENT).includes(location.pathname)) return null

  const { question, hypertext, route } = FOOTER_CONTENT[location.pathname]
  return (
    <>
      <hr className="my-3 bg-secondary-200" />
      <div className="flex items-center justify-center w-full gap-2 mx-auto text-xs">
        <p className="text-current-700">{question}</p>
        <Link to={route} className="text-primary-900">
          {hypertext}
        </Link>
      </div>
    </>
  )
}

export default Footer
