import { useParams } from 'react-router-dom'

import { AppLogo, Button } from '@shared/ui'

import GMailLogo from '@/assets/images/base/gmail.svg'

const VerifyEmail = () => {
  const { userEmail } = useParams()
  const sendEmail = () => {
    window.location.href = `mailto:${userEmail}`
  }
  return (
    <div>
      <div className="flex flex-col items-center justify-center w-full gap-2 mx-auto">
        <AppLogo className="mb-2 md:mt-4" clickable />
        <img src={GMailLogo} alt="Gmail logo" />
        <h2 className="text-lg font-bold text-current-900">Verify your email address</h2>
        <p className="text-xs font-normal text-center md:text-sm md:pt-3">
          You’ve entered{' '}
          <span className="text-xs font-medium text-center md:text-sm">{userEmail}</span> as the
          email address for your account please verify this email address by clicking button below.
        </p>
      </div>
      <div className="flex items-center justify-between mt-5 md:mt-7">
        <Button onClick={sendEmail} type="submit" className="w-full" size="sm">
          Verify your email
        </Button>
      </div>
      <div className="flex flex-col items-center justify-center">
        <p className="mt-3 mb-1 text-xs text-center md:text-sm md:pt-4 ">
          or copy and paste this link into your browser
        </p>
        <p className="text-xs text-center mb-4 md:text-sm  text-[#0062E0]">SynthStreamLabs.net</p>
      </div>
    </div>
  )
}

export default VerifyEmail
