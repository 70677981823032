import Cookie from '@features/base/ui/cookie-policy'
import PrivacyPolicy from '@features/base/ui/privacy-policy'
import TermsOfUse from '@features/base/ui/terms-of-use'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

export default function LegalDocuments() {
  return (
    <main className="min-h-screen py-10 bg-[#FAFDFF]">
      <div className="max-w-[900px] md:mb-16 mb-10 mx-auto px-5 md:px-2">
        <h1 className="text-center text-base mb-4 md:text-7xl text-[#0959FE]">Legal documents</h1>
        <p className="text-base text-center">
          All documents were updated <br />
          on October 02, 2024
        </p>
      </div>
      <Tabs defaultValue="terms-of-us" className="w-full">
        <TabsList className="grid w-full grid-cols-3 bg-[#FAFDFF]">
          <TabsTrigger
            value="terms-of-us"
            className="flex items-center  justify-center py-4 data-[state=active]:bg-white data-[state=active]:border-b-0 data-[state=active]:shadow-none"
          >
            Terms of Use
          </TabsTrigger>
          <TabsTrigger
            value="privacy-policy"
            className="flex items-center  justify-center py-4 data-[state=active]:bg-white data-[state=active]:border-b-0 data-[state=active]:shadow-none"
          >
            Privacy Policy
          </TabsTrigger>
          <TabsTrigger
            value="cookie-policy"
            className="flex items-center  justify-center py-4 data-[state=active]:bg-white data-[state=active]:border-b-0 data-[state=active]:shadow-none"
          >
            Cookie Policy
          </TabsTrigger>
        </TabsList>
        <TabsContent value="terms-of-us">
          <TermsOfUse />
        </TabsContent>
        <TabsContent value="privacy-policy">
          <PrivacyPolicy />
        </TabsContent>
        <TabsContent value="cookie-policy">
          <Cookie />
        </TabsContent>
      </Tabs>
    </main>
  )
}
