import { ILanguage } from '@features/build-cv/assisted/model'
import { Button, Icon, InputBox } from '@shared/ui'

import { SoleDropdown, loaders } from '@/widgets'

import List from './list'

const LanguagesStep = ({ register, errors }: loaders.IWithFormLoaderComponentProps) => {
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
        <InputBox
          className="flex-1"
          label="Language"
          placeholder="Type Here..."
          type="text"
          id="language"
          error={errors.language?.message}
          {...register('language')}
        />
        <SoleDropdown
          className="self-end flex-1"
          placeholder="Proficiency"
          useSelectedAsPlaceholder
          flow="bottom-right"
          options={['Beginner', 'Intermediate', 'Advanced', 'Fluent']}
          onSelect={(item) => {
            register('level').onChange({
              target: { name: 'level', value: item },
              type: 'text',
            })
          }}
        />
      </div>
      <Button size="sm" variant="outline" className="w-fit" type="submit">
        <Icon icon="plus" className="mr-2" color="current-900" />
        Add language
      </Button>
      <List
        dataKey="select_language"
        getSummary={(item) => {
          const data = item as ILanguage
          return (
            <div className="flex flex-col gap-1">
              <p className="text-sm text-gray-500">
                {data.language} &mdash; {data.level}
              </p>
            </div>
          )
        }}
      />
    </div>
  )
}

export default LanguagesStep
