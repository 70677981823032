import { useEffect, useState } from 'react'

import { DetailedStep } from '@shared/types'
import CountLabelHorizontal from '@shared/ui/detailed-steps-horizontal/count-label'
import DottedLinesHorizontal from '@shared/ui/detailed-steps-horizontal/dotted-lines'
import clsx from 'clsx'

type Props = {
  steps: DetailedStep[]
  stepComponent?: React.FC<DetailedStep & { is_active?: boolean } & { interactive?: boolean }>
  currentStep?: number
  interactive?: boolean
  horizontal?: boolean
  className?: string
  onStepChange?: (step: number) => void
  css?: {
    step?: string
    stepTitle?: string
    stepDescription?: string
    stepIcon?: string
    stepBox?: string
  }
}

const DetailedStepsHorizontal: React.FC<Props> = ({
  steps,
  stepComponent: StepComponent,
  currentStep = 0,
  interactive = false,
  horizontal = false,
  onStepChange,
  className,
  css,
}: Props) => {
  const [activeStep, setActiveStep] = useState(currentStep)

  useEffect(() => {
    setActiveStep(currentStep)
  }, [currentStep])

  const handleStepChange = (step: number) => {
    if (!onStepChange) return

    setActiveStep(step)
    onStepChange(step)
  }

  return (
    <div
      className={clsx(
        'flex',
        {
          'flex-row': horizontal,
          'flex-col': !horizontal,
        },
        className,
      )}
    >
      {steps.map((step, index) => (
        <div
          className={clsx(
            'flex flex-1 gap-3',
            {
              'flex-row': !horizontal,
              'flex-col': horizontal,
              'cursor-pointer': interactive,
              'cursor-not-allowed pointer-events-none': !interactive,
            },
            css?.stepBox,
          )}
          key={step.title}
          onClick={() => handleStepChange(index)}
          onKeyDown={(e) => e.key === 'Enter' && handleStepChange(index)}
          role="button"
          tabIndex={0}
          title={step.title}
        >
          <div
            className={clsx('flex items-center relative', {
              'flex-col ': !horizontal,
              'flex-row': horizontal,
              'justify-center': horizontal && steps.length - 1 !== index,
            })}
          >
            <CountLabelHorizontal
              count={index + 1}
              is_active={interactive && activeStep === index}
              icon={step.icon}
              size="sm"
              className={clsx({
                'text-[#0959FE4D] border-[#0959FE4D]':
                  !interactive || (interactive && activeStep < index),
                'text-white bg-primary-900 border-primary-900': interactive && activeStep >= index,
              })}
            />
            <DottedLinesHorizontal
              className={clsx({
                'md:hidden': steps.length - 1 === index && !horizontal,
                hidden: steps.length - 1 === index && horizontal,
                'border-solid border-primary-900': interactive && activeStep >= index,
                'border-dashed border-[#0959FE4D]':
                  !interactive || (interactive && activeStep < index),
              })}
              horizontal={horizontal}
            />
          </div>
          {StepComponent ? (
            <StepComponent {...step} is_active={interactive && activeStep >= index} />
          ) : (
            <div className={clsx('flex flex-col gap-1', css?.step)}>
              <h4 className={css?.stepTitle}>{step.title}</h4>
              {step.description && (
                <p className={clsx('text-sm', css?.stepDescription)}>{step.description}</p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export type IDetailedStepsHorizontalProps = Props
export default DetailedStepsHorizontal
