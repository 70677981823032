import { UserCV } from '@shared/model/usercv'
import { Instance, types } from 'mobx-state-tree'

const User = types
  .model('User', {
    _id: types.identifier,
    email: types.maybeNull(types.string),
    roleId: types.string,
    fullName: types.string,
    cv: types.maybeNull(UserCV),
  })
  .views((self) => ({
    get firstName() {
      return self.fullName.split(' ')[0]
    },

    get lastName() {
      return self.fullName.split(' ')[1]
    },
  }))
  .views((self) => ({
    get initials() {
      return self.firstName[0] + self.lastName[0]
    },

    get username() {
      // generate username from email or fullName
      return (
        (self.email ? self.email.split('@')[0] : self.fullName.split(' ').join('_'))
          .toLocaleLowerCase()
          .substring(0, 16) + Date.now().toString()
      )
    },
  }))

export type IUser = Instance<typeof User>
export default User
