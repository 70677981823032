import { z } from 'zod'

const formSchema = {
  contact: z.object({
    first_name: z.string().min(1, { message: 'First name is required' }),
    last_name: z.string().min(1, { message: 'Last name is required' }),
    street_address: z.string().max(225),
    city: z.string().max(64).min(1, { message: 'City is required' }),
    country: z.string().max(64).min(1, { message: 'Country is required' }),
    postal_code: z.string().max(16),
    email: z.string().email({ message: 'Must be a valid email' }),
    phone_number: z.string().min(1, { message: 'Phone number is required' }).max(16),
  }),
  work_history: z.object({
    job_title: z.string().min(1, { message: 'Job title is required' }),
    employer: z.string().min(1, { message: 'Employer is required' }),
    city: z.string().max(64).min(1, { message: 'City is required' }),
    country: z.string().max(64).min(1, { message: 'Country is required' }),
    start_date: z.string().min(1, { message: 'Start date is required' }),
    end_date: z.string().nullable(),
  }),
  education: z.object({
    qualification: z.string().min(1, { message: 'Qualification is required' }),
    field_of_study: z.string().max(64),
    institution: z.string().min(1, { message: 'Institution is required' }),
    country: z.string().max(64).min(1, { message: 'Country is required' }),
    start_date: z.string().min(1, { message: 'Start date is required' }),
    end_date: z.string().nullable(),
  }),
  skills: z.object({
    skill: z.string().min(1, { message: 'Skill is required' }),
  }),
  select_language: z.object({
    language: z.string().min(1, { message: 'Language is required' }),
    level: z.string().min(1, { message: 'Proficiency is required' }),
  }),
  summary: z.object({
    prof_summary: z.string().min(1, { message: 'Summary is required' }),
  }),
  review: z.object({
    review: z.boolean(),
  }),
}

export default formSchema
