import { Navigate, RouteObject } from 'react-router-dom'

import CheckYourEmail from '@features/auth/check-your-mail/ui/check-your-email'
import ConfirmEmail from '@features/auth/email-confirmation'
import Footer from '@features/auth/footer'
import ForgotPasswordForm from '@features/auth/forgot-password'
import AuthLayout from '@features/auth/layout'
import Login from '@features/auth/login'
import ResetPasswordForm from '@features/auth/reset-password'
import Signup from '@features/auth/signup'
import VerifyEmail from '@features/auth/verify-email/ui'

const routes: RouteObject = {
  path: '/auth',
  element: (
    <AuthLayout footer={<Footer />}>
      <Navigate to="login" replace />
    </AuthLayout>
  ),
  children: [
    {
      path: 'login',
      element: <Login />,
    },
    {
      path: 'signup',
      element: <Signup />,
    },
    {
      path: 'forgot-password',
      element: <ForgotPasswordForm />,
    },
    {
      path: 'reset-password',
      element: <ResetPasswordForm />,
    },
    {
      path: 'check-email/:userEmail',
      element: <CheckYourEmail />,
    },
    {
      path: 'confirm-email',
      element: <ConfirmEmail />,
    },
    {
      path: 'verify-email/:userEmail',
      element: <VerifyEmail />,
    },
  ],
}

export default routes
