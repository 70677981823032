import { IEducation } from '@features/build-cv/assisted/model'
import { Button, Icon, InputBox } from '@shared/ui'

import { loaders } from '@/widgets'

import List from './list'

const EducationStep = ({ register, errors }: loaders.IWithFormLoaderComponentProps) => {
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
          <InputBox
            className="flex-1"
            label="Institution"
            placeholder="Type Here..."
            type="text"
            id="institution"
            error={errors.institution?.message}
            {...register('institution')}
          />
          <InputBox
            className="flex-1"
            label="Qualification"
            placeholder="Type Here..."
            type="text"
            id="qualification"
            error={errors.qualification?.message}
            {...register('qualification')}
          />
        </div>
        <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
          <InputBox
            className="flex-1"
            label="Field of Study"
            placeholder="Type Here..."
            type="text"
            id="field_of_study"
            error={errors.field_of_study?.message}
            {...register('field_of_study')}
          />
          <InputBox
            className="flex-1"
            label="Country"
            placeholder="Type Here..."
            type="text"
            id="country"
            error={errors.country?.message}
            {...register('country')}
          />
        </div>
        <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
          <InputBox
            className="flex-1"
            label="Start Date"
            placeholder="Type Here..."
            type="month"
            id="start_date"
            error={errors.start_date?.message}
            {...register('start_date')}
          />
          <InputBox
            className="flex-1"
            label="End Date"
            placeholder="Type Here..."
            type="month"
            id="end_date"
            error={errors.end_date?.message}
            {...register('end_date')}
          />
        </div>
      </div>
      <Button size="sm" variant="outline" className="w-fit" type="submit">
        <Icon icon="plus" className="mr-2" color="current-900" />
        Add education
      </Button>
      <List
        dataKey="education"
        getSummary={(item) => {
          const data = item as IEducation
          return (
            <div>
              <p className="text-sm font-medium">
                {data.qualification} in {data.field_of_study}
              </p>
              <p className="mt-1 text-xs">
                {data.start_date} &mdash; {data.end_date}
              </p>
              <p className="text-xs">{data.institution}</p>
            </div>
          )
        }}
      />
    </div>
  )
}

export default EducationStep
