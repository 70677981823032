import { useLocation } from 'react-router-dom'

import { Button } from '@shared/ui'

const Navigator = ({
  navigate,
  stepId,
  loading,
  nextShouldSubmit = false,
}: {
  navigate: (back: boolean) => void
  stepId: number
  loading: boolean
  nextShouldSubmit?: boolean
}) => {
  const location = useLocation()
  const { isFinalStep } = location.state ?? { isFinalStep: false }

  return (
    <div className="flex items-center justify-between gap-5 my-20">
      <Button
        size="md"
        variant="outline"
        color="primary-900"
        disabled={stepId === 0}
        onClick={() => navigate(true)}
      >
        Back
      </Button>
      <Button
        size="md"
        variant="primary"
        isLoading={loading}
        type={nextShouldSubmit ? 'submit' : 'button'}
        onClick={() => {
          if (!nextShouldSubmit) {
            window.scrollTo({ top: 0, behavior: 'smooth' })
            navigate(false)
          }
        }}
      >
        {isFinalStep ? 'Finish' : 'Save & Continue'}
      </Button>
    </div>
  )
}

export default Navigator
