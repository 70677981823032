import { Link } from 'react-router-dom'

import { LoginUserDTO } from '@entities/session/api/types'
import formSchema from '@features/auth/login/model/form'
import { AxiosResponse } from 'axios'

import SessionAPI from '@/entities/session/api'
import { AppLogo, Button, InputBox } from '@/shared/ui'
import { loaders } from '@/widgets'

const LoginForm = loaders.WithFormLoader(
  SessionAPI.login as (data: Partial<LoginUserDTO>) => Promise<AxiosResponse>,
  ({ register, errors, isLoading }) => {
    return (
      <div>
        <div className="flex items-center justify-center w-full gap-2 mx-auto">
          <h2 className="self-start text-lg font-bold text-current-900">Sign In</h2>
          <AppLogo className="mb-4" clickable />
        </div>
        <div className="space-y-4">
          <InputBox
            label="Email Address"
            placeholder="Email Address"
            type="email"
            id="email"
            error={errors.email?.message}
            {...register('email')}
          />
          <InputBox
            label="Password"
            placeholder="Password"
            type="password"
            id="password"
            error={errors.password?.message}
            {...register('password')}
          />
        </div>
        <div>
          <div className="mt-5">
            <Button type="submit" size="sm" className="w-full" isLoading={isLoading}>
              Continue
            </Button>
          </div>
          <Link className="block mt-2 text-xs w-fit text-primary-700" to="/auth/forgot-password">
            Forgot your password?
          </Link>
        </div>
      </div>
    )
  },
  formSchema,
)

export default LoginForm
