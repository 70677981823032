import { NavLink } from 'react-router-dom'

import { AppLogo } from '@shared/ui'

import LogoutIcon from '@/assets/images/user-dashboard/logout.png'

interface NavItem {
  path: string
  icon: string
  label: string
}

interface SidebarProps {
  navItems: NavItem[]
  onNavLinkClick: (title: string) => void
}

const Sidebar: React.FC<SidebarProps> = ({ navItems, onNavLinkClick }: SidebarProps) => {
  return (
    <div className="hidden md:flex flex-col bg-[#051A45] text-white py-5 gap-6 px-3 w-44 max-w-md h-screen">
      <AppLogo className="pb-4 text-xl text-white" colored={false} />
      {navItems.map((item) => (
        <NavLink
          key={item.path}
          to={item.path}
          onClick={() => onNavLinkClick(item.label)}
          className="flex items-center gap-2"
        >
          <img src={item.icon} alt={`${item.label}-icon`} className="w-5 h-5" />
          <div>{item.label}</div>
        </NavLink>
      ))}
      <div className="flex items-center gap-2">
        <img src={LogoutIcon} alt="Logout-icon" />
        <p>Logout</p>
      </div>
    </div>
  )
}

export default Sidebar
