import { allStepsCVData } from '@features/build-cv/assisted/model'
import { InputBox } from '@shared/ui'

import { loaders } from '@/widgets'

const ContactDetailsStep = ({ register, errors }: loaders.IWithFormLoaderComponentProps) => {
  const data = allStepsCVData['contact'].get()

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
        <InputBox
          className="flex-1"
          label="First Name"
          placeholder="Type Here..."
          type="text"
          id="first_name"
          defaultValue={data.first_name}
          error={errors.first_name?.message}
          {...register('first_name')}
        />
        <InputBox
          className="flex-1"
          label="Last Name"
          placeholder="Type Here..."
          type="text"
          id="last_name"
          defaultValue={data.last_name}
          error={errors.last_name?.message}
          {...register('last_name')}
        />
      </div>
      <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
        <InputBox
          className="flex-1"
          label="Street Address (Optional)"
          placeholder="Type Here..."
          type="text"
          id="street_address"
          defaultValue={data.street_address}
          error={errors.street_address?.message}
          {...register('street_address')}
        />
        <InputBox
          className="flex-1"
          label="Country"
          placeholder="Type Here..."
          type="text"
          id="country"
          defaultValue={data.country}
          error={errors.country?.message}
          {...register('country')}
        />
      </div>
      <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
        <InputBox
          className="flex-1"
          label="City"
          placeholder="Type Here..."
          type="text"
          id="city"
          defaultValue={data.city}
          error={errors.city?.message}
          {...register('city')}
        />
        <InputBox
          className="flex-1"
          label="Postal Code (Optional)"
          placeholder="Type Here..."
          type="text"
          id="postal_code"
          defaultValue={data.postal_code}
          error={errors.postal_code?.message}
          {...register('postal_code')}
        />
      </div>
      <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
        <InputBox
          className="flex-1"
          label="Email"
          placeholder="Type Here..."
          type="email"
          id="email"
          defaultValue={data.email}
          error={errors.email?.message}
          {...register('email')}
        />
        <InputBox
          className="flex-1"
          label="Phone Number"
          placeholder="Type Here..."
          type="tel"
          id="phone_number"
          defaultValue={data.phone_number}
          error={errors.phone_number?.message}
          {...register('phone_number')}
        />
      </div>
    </div>
  )
}

export default ContactDetailsStep
