import {
  ContactDetailsStep,
  EducationStep,
  SkillsStep,
  WorkHistoryStep,
} from '@features/build-cv/assisted/ui'
import LanguagesStep from '@features/build-cv/assisted/ui/languages'
import ReviewStep from '@features/build-cv/assisted/ui/review'
import SummaryStep from '@features/build-cv/assisted/ui/summary'
import { Step } from '@shared/types'

import allStepsCVData, { AllStepsCVDataKey } from './model/cv-data'
import formSchema from './model/validators'

const submitHandler = async (
  key: AllStepsCVDataKey,
  data: Record<string, unknown>,
): Promise<void> => {
  allStepsCVData[key].update(data)
}

const steps: Step[] = [
  {
    id: 'contact',
    title: 'Contact Details',
    description: 'Your cv header will contain this information.',
    handler: ContactDetailsStep,
    schema: formSchema['contact'],
    onSubmit: async (data) => submitHandler('contact', data),
  },
  {
    id: 'work_history',
    title: 'Work History',
    description: 'Work backwards from your most recent job, starting with that.',
    handler: WorkHistoryStep,
    schema: formSchema['work_history'],
    onSubmit: async (data) => submitHandler('work_history', data),
    asList: true,
  },
  {
    id: 'education',
    title: 'Education',
    description:
      "List the colleges, universities, and other educational settings you attended. List your school or any other training facility if you didn't pursue additional schooling, especially if it relates to the employment you're applying for.",
    handler: EducationStep,
    schema: formSchema['education'],
    onSubmit: async (data) => submitHandler('education', data),
    asList: true,
  },
  {
    id: 'skills',
    title: 'Skills',
    description: 'Start with our professional advice for each job title.',
    handler: SkillsStep,
    schema: formSchema['skills'],
    onSubmit: async (data) => submitHandler('skills', data),
    asList: true,
  },
  {
    id: 'select_language',
    title: 'Languages',
    description:
      'Use the examples below, then modify them to reflect your experience and the requirements of the position.',
    handler: LanguagesStep,
    schema: formSchema['select_language'],
    onSubmit: async (data) => submitHandler('select_language', data),
    asList: true,
  },
  {
    id: 'summary',
    title: 'Summary',
    description:
      'Use the examples below, then modify them to reflect your experience and the requirements of the position.',
    handler: SummaryStep,
    schema: formSchema['summary'],
    onSubmit: async (data) => submitHandler('summary', data),
  },
  {
    id: 'review',
    title: 'Review',
    description:
      'Review your cv and make any necessary modifications. Apply for jobs after downloading or emailing yourself a copy.',
    handler: ReviewStep,
    schema: formSchema['review'],
    onSubmit: async () => {
      // TODO: send the data to the server
    },
    asList: true,
  },
]

export type { Step }
export default steps
