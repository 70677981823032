import { Duration, Location } from '@shared/model/usercv'
import { Instance, types } from 'mobx-state-tree'

const Education = types
  .model('Education', {
    organization: types.string,
    location: types.maybe(Location),
    accreditation: types.model('Accreditation', {
      degree: types.string,
      fieldOfStudy: types.string,
    }),
    description: types.maybe(types.string),
    dates: Duration,
    grade: types.maybe(types.string),
  })
  .views((self) => ({
    get formattedLocation() {
      let location = ''
      if (!self.location) {
        return 'No location provided'
      }

      if (self.location.city) {
        location += `${self.location.city}, `
      }

      if (self.location.state) {
        location += `${self.location.state} `
      }

      if (self.location.country) {
        location += self.location.country
      }

      return location
    },
  }))

export type IEducation = Instance<typeof Education>
export default Education
