import { Icon } from '@shared/ui'
import { CV_CUSTOMIZER_ICONS } from '@widgets/cv-preview/constants'
import SoleDropdown from '@widgets/sole-dropdown'
import { SoleDropdownItem } from '@widgets/types'
import clsx from 'clsx'

type Props = {
  className?: string
  onSelected: (key: string, value: SoleDropdownItem) => void
}

const CVCustomizer = ({ className, onSelected }: Props) => {
  return (
    <div
      className={clsx('flex items-center gap-3 ml-auto overflow-x-auto container-snap', className)}
    >
      <SoleDropdown
        placeholder={<Icon icon="file-lines" color="current-900" className="xl:text-xl" />}
        options={['item 1', 'item 2', 'item 3', 'item 4', 'item 5']}
        className="mr-auto"
      />
      <div className="flex gap-2 items-center">
        {CV_CUSTOMIZER_ICONS.map(({ id, icon }) => (
          <SoleDropdown
            key={id}
            placeholder={<Icon icon={icon} color="current-900" className="xl:text-xl" />}
            options={['item 1', 'item 2', 'item 3', 'item 4', 'item 5']}
            onSelect={(value) => onSelected(id, value as SoleDropdownItem)}
          />
        ))}
      </div>
    </div>
  )
}

export default CVCustomizer
