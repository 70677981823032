import { SoleDropdownItem } from '@widgets/types'

type SoleDropdownLabelProps = {
  selected: SoleDropdownItem | SoleDropdownItem[]
  isMulti: boolean
  placeholder: string | React.ReactNode
  selectedAsPH: boolean
}

const getLabel = (
  selected: SoleDropdownLabelProps['selected'],
  isMulti: SoleDropdownLabelProps['isMulti'],
  placeholder: SoleDropdownLabelProps['placeholder'],
) => {
  if (!selected || (isMulti && !(selected as SoleDropdownItem[]).length)) {
    return placeholder
  }

  if (isMulti) {
    return (selected as SoleDropdownItem[]).length + ' selected'
  }

  return typeof selected === 'string' ? selected : (selected as { label: string }).label
}

const SoleDropdownLabel = ({
  selected,
  isMulti,
  placeholder,
  selectedAsPH,
}: SoleDropdownLabelProps) => {
  return (
    <span className="flex-1 truncate text-sm">
      {selectedAsPH ? getLabel(selected, isMulti, placeholder) : placeholder}
    </span>
  )
}

export type { SoleDropdownLabelProps }
export default SoleDropdownLabel
