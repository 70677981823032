import { Instance, ModelActions, types } from 'mobx-state-tree'

type AllStepsCVDataKey =
  | 'contact'
  | 'work_history'
  | 'education'
  | 'skills'
  | 'select_language'
  | 'summary'
type IWorkHistory = {
  job_title: string
  employer: string
  city: string
  country: string
  start_date: string
  end_date: string
  job_description: string
  currently_working_here: string
}
type IEducation = {
  qualification: string
  field_of_study: string
  institution: string
  country: string
  start_date: string
  end_date: string
}
type ILanguage = { language: string; level: string }

interface CommonActions<T extends object> {
  update(data: Record<string, unknown>): void
  remove(idx: string | number): void
  get(): T
}

function createModel<T extends object>(modelDefinition: T) {
  const model = types
    .model({
      data: types.frozen<T>(),
    })
    .actions(
      (self) =>
        ({
          update(data: Record<string, unknown>) {
            if (Array.isArray(self.data)) {
              Object.assign(self, {
                data: [...self.data, 'skill' in data ? data.skill : data],
              })
            } else {
              for (const key in data) {
                if (key in self.data) {
                  Object.assign(self, {
                    data: { ...self.data, [key]: data[key] },
                  })
                }
              }
            }
          },
          remove(idx: string | number) {
            if (Array.isArray(self.data)) {
              Object.assign(self, {
                data: self.data.filter((_, i) => i !== idx),
              })
            } else {
              self.data = { ...self.data, [idx]: undefined }
            }
          },
          get() {
            return self.data
          },
        }) as ModelActions & CommonActions<T>,
    )

  return model
    .views((self) => ({
      get length() {
        return Array.isArray(self.data) ? self.data.length : 0
      },
    }))
    .create({ data: modelDefinition })
}

const allStepsCVData = {
  contact: createModel({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    street_address: '',
    city: '',
    country: '',
    postal_code: '',
  }),
  work_history: createModel([] as IWorkHistory[]),
  education: createModel([] as IEducation[]),
  skills: createModel([] as string[]),
  select_language: createModel([] as ILanguage[]),
  summary: createModel({
    prof_summary: '',
  }),
}

type AllStepsCVDataValue<T extends AllStepsCVDataKey> = Instance<(typeof allStepsCVData)[T]>

export type { AllStepsCVDataKey, AllStepsCVDataValue, IWorkHistory, IEducation, ILanguage }
export default allStepsCVData
