class Textify {
  static toChainCase = (str: string) => {
    return str.replace(/([A-Z])/g, '-$1').toLowerCase()
  }

  static fromChainCase = (str: string) => {
    return str.replace(/-([a-z])/g, (g) => g[1].toUpperCase())
  }

  static toSentenceCase = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }

  static toTitleCase = (str: string) => {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase())
  }

  static toSpacedWords = (str: string) => {
    // A-Z or - followed by a-z
    return str
      .replace(/([A-Z]|-)([a-z])/g, '$1 $2')
      .replace(/-/g, ' ')
      .trim()
  }

  static generateRandomString = (length: number) => {
    return Math.random()
      .toString(36)
      .substring(2, length + 2)
  }

  static constructURL = (
    path: string,
    baseUrl: string | null = null,
    params?: string | Record<string, string>,
  ): string => {
    const url = new URL(baseUrl ?? window.location.origin)
    url.pathname = path

    if (params) {
      if (typeof params === 'string') {
        url.search = params
      } else {
        Object.keys(params).forEach((key) => {
          url.searchParams.append(key, params[key])
        })
      }
    }

    return url.toString()
  }
}

export default Textify
