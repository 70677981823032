import MissionAndVisionImage from '@/assets/images/base/a_conference_room.svg'

import CTACard from './common/cta-card'

const GOOGLE_MAPS_API_KEY = import.meta.env.VITE_GOOGLE_MAPS_API_KEY

export default function AboutUs() {
  return (
    <main className="min-h-screen py-10 bg-white">
      <div className="max-w-[900px] mx-auto px-5 md:px-2">
        <section className="md:w-1/2">
          <h4 className="text-3xl font-bold md:text-4xl">
            Find more about us
            <br />
            and <span className="text-primary-900">our services.</span>
          </h4>
          <p className="mt-5 md:text-lg text-current-500">
            Revampinsights is a roboust platform for aspiring and accomplished professionals and
            business people.
          </p>
        </section>
        <iframe
          width="100%"
          height={320}
          className="my-10 border-0 rounded-md"
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=${GOOGLE_MAPS_API_KEY}&q=Space+Needle,Seattle+WA`}
          allowFullScreen
          title="Company Location"
        />
        <section className="flex flex-col-reverse items-center justify-between gap-5 px-2 my-5 md:flex-row">
          <div className="flex-1">
            <img
              src={MissionAndVisionImage}
              alt="A conference room containing a team discussing a strategy together."
              className="object-contain w-full h-full rounded"
            />
          </div>
          <article className="my-3 md:w-3/6 md:my-0">
            <h4 className="mb-2 text-2xl font-bold md:text-3xl">
              Our mission <span className="text-primary-900">and vision</span>
            </h4>
            <p className="leading-7">
              We believe that the 21st century professional needs a fighting chance at landing dream
              jobs. What better way to do these than to totally re-invent; REVAMP themselves. Our
              goals is that our platform will enhance career development by arming the young
              professional with the tools need to land dream jobs and at the same time build a
              brigde between professionals and prospective enployers. At REVAMP, we see a future
              were through capacity building, every professional will find the job that fit and
              every employer the professional that deleverd optimally.
            </p>
          </article>
        </section>
        <section className="flex flex-col gap-10 my-10 md:my-16">
          <h4 className="text-2xl font-bold text-center md:text-3xl">
            Our <span className="text-primary-900">Services</span>
          </h4>
          <ul className="grid gap-5 list-none sm:grid-cols-2 md:grid-cols-3 justify-items-center">
            <li className="flex flex-col gap-2 p-5 border rounded-md shadow-lg">
              <h5 className="text-lg font-semibold">Job Listing</h5>
              <p className="text-sm">
                Extensive job listing across major industries world wide and free unlimited job
                listing on our web platform for up to 3 months.
              </p>
            </li>
            <li className="flex flex-col gap-2 p-5 border rounded-md shadow-lg">
              <h5 className="text-lg font-semibold">E-Learning</h5>
              <p className="text-sm">
                To truly REVAMP, learning must be continous, we provide specific curated learning
                content to help add value to professionals.
              </p>
            </li>
            <li className="flex flex-col gap-2 p-5 border rounded-md shadow-lg">
              <h5 className="text-lg font-semibold">Automated Resume Building</h5>
              <p className="text-sm">
                Create your professional resume in 5 minutes and land your dream job faster.
              </p>
            </li>
            <li className="flex flex-col gap-2 p-5 border rounded-md shadow-lg">
              <h5 className="text-lg font-semibold">Recruitment Services</h5>
              <p className="text-sm">
                We are the bridge between the 21st century professional and prospective employers.
              </p>
            </li>
            <li className="flex flex-col gap-2 p-5 border rounded-md shadow-lg">
              <h5 className="text-lg font-semibold">Social Responsibility</h5>
              <p className="text-sm">
                We are passionate about Education and how it can inpact and empower future
                generation. We are committed to building a learning culture across Africa thus we
                provide books for 1000 children across Africa annually.
              </p>
            </li>
          </ul>
        </section>
        <CTACard />
      </div>
    </main>
  )
}
