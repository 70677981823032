import ChooseCVTemplateSection from '@features/base/ui/choose-cv-template'
import CVBuildingSection from '@features/base/ui/cv-building-section'
import HeroSection from '@features/base/ui/hero-section'
import PricingSection from '@features/base/ui/pricing-section'
import TestimonialsSection from '@features/base/ui/testimonials-section'
import WinningCVSection from '@features/base/ui/winning-cv-section'

export default function AppLandingPage() {
  return (
    <main>
      <HeroSection />
      <CVBuildingSection />
      <ChooseCVTemplateSection />
      <WinningCVSection />
      <TestimonialsSection />
      <PricingSection />
    </main>
  )
}
