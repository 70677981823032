import AuthOptions from '@entities/oauth/ui/auth-options'
import { useAuthenComplete } from '@features/auth/lib/hooks'
import LoginForm from '@features/auth/login/ui/form'

export default function FLogin() {
  const onComplete = useAuthenComplete()

  return (
    <>
      <LoginForm onComplete={onComplete} />
      {/* Other login options */}
      <AuthOptions />
    </>
  )
}
