import { Button, Icon } from '@shared/ui'
import { CV_TOOLBAR_ACTIONS } from '@widgets/cv-preview/constants'
import clsx from 'clsx'

import CVCustomizer from './customizer'

type Props = {
  customize?: boolean
  className?: string
}

const CVToolbar = ({ customize = false, className }: Props) => {
  const onCustomize = () => {
    // TODO: Implement onSelected
  }

  return (
    <div className={clsx('flex items-center gap-4 py-2', className)}>
      <div className="flex items-center gap-2">
        {CV_TOOLBAR_ACTIONS.map(({ id, label, icon }) => (
          <Button
            key={id}
            variant="outline"
            className="py-1 px-2 whitespace-nowrap gap-1 rounded-md"
          >
            <Icon icon={icon} color="current-900" />
            <span className="text-xs">{label}</span>
          </Button>
        ))}
      </div>
      {customize && <CVCustomizer onSelected={onCustomize} />}
    </div>
  )
}

export default CVToolbar
