import sessionSlice from '@entities/session/model/slice'
import RESUME_FORM_FIELDS from '@features/fastrack/resume-form/model/fields'
import formSchema from '@features/fastrack/resume-form/model/form'
import { ResumeFormDTO } from '@features/fastrack/resume-form/types'
import API from '@shared/lib/api'
import { AxiosResponse } from 'axios'

import { Button, InputBox } from '@/shared/ui'
import { loaders } from '@/widgets'

type Resume = {
  file: ArrayBuffer | null
  type: string
  name: string
}

const ResumeForm = (() => {
  let resume: Resume = {
    file: null,
    type: '',
    name: '',
  }

  const handleResumeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files

    if (files && files.length > 0) {
      const reader = new FileReader()
      reader.onload = (e) => {
        // store resume file in variable
        resume = {
          file: e.target?.result as ArrayBuffer,
          type: files[0].type,
          name: files[0].name,
        }
      }

      reader.readAsDataURL(files[0])
    }
  }

  const handlePreview = async (data: ResumeFormDTO): Promise<AxiosResponse> => {
    const formData = new FormData()

    // add all fields and resume file to form data
    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'resume') {
        formData.append(key, value as string)
      }
    })

    // convert to file object and append to form data
    if (resume.file) {
      const file = new File(
        [resume.file as BlobPart],
        (sessionSlice.user?.username || resume.name.split('.')[0]) + '_resume',
        { type: resume.type },
      )
      formData.append('resume', file)
    }

    // send form data to server
    const api = new API({
      baseURL: import.meta.env.VITE_RI_RESUME_API_URL,
      timeout: 3000,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })

    return await api.post('/user/new', formData)
  }

  return loaders.WithFormLoader(
    handlePreview as (data: Partial<ResumeFormDTO>) => Promise<AxiosResponse>,
    ({ register, errors }) => {
      return (
        <div>
          <div className="space-y-4">
            {RESUME_FORM_FIELDS.branch_a.map((field) => (
              <InputBox
                key={field.name}
                label={field.label}
                placeholder={field.placeholder}
                type={field.type}
                id={field.name}
                error={errors[field.name]?.message}
                {...register(field.name)}
              />
            ))}
            <div className="flex justify-between gap-3 flex-wrap mt-2">
              {RESUME_FORM_FIELDS.branch_b.map((field) => (
                <InputBox
                  key={field.name}
                  label={field.label}
                  placeholder={field.placeholder}
                  type={field.type}
                  id={field.name}
                  error={errors[field.name]?.message}
                  className="flex-1 basis-36"
                  {...register(field.name)}
                />
              ))}
            </div>
            {RESUME_FORM_FIELDS.branch_c.map((field) => (
              <InputBox
                key={field.name}
                label={field.label}
                placeholder={field.placeholder}
                type={field.type}
                id={field.name}
                error={errors[field.name]?.message}
                {...register(field.name)}
                accept={field.accept}
                onChange={handleResumeChange}
              />
            ))}
          </div>
          <div className="flex justify-end mt-10">
            <Button type="submit">Preview</Button>
          </div>
        </div>
      )
    },
    formSchema,
  )
})()

export default ResumeForm
