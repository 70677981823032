import { NavLink } from 'react-router-dom'

import NotFoundError from '@/assets/images/base/404.svg'

export default function NotFound() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen">
      <img src={NotFoundError} alt="404" width="400px" className=" md:w-[400px] w-52" />
      <p className="text-[20px] md:text-[40px] font-semibold text-center">Oops! Page Not Found</p>
      <p className="text-[20px] md:text-[40px] font-semibold text-center pt-6 md:pt-14">
        The page you are looking for doesn’t exist or an other
      </p>
      <p className="text-[20px] md:text-[40px] font-semibold text-center">
        error occured. <NavLink to="/">Go to Home Page</NavLink>
      </p>
    </div>
  )
}
