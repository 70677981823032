import { IUser, User } from '@shared/model'
import { Instance, types } from 'mobx-state-tree'

import SessionAPI from '@/entities/session/api'

const sessionSlice = types
  .model('session', {
    isLoading: types.optional(types.boolean, false),
    accessToken: types.maybeNull(types.string),
    user: types.maybeNull(User),
  })
  .actions((self) => ({
    clear() {
      self.isLoading = false
      self.accessToken = null
      self.user = null
    },
    setToken(token: string, load = true) {
      self.accessToken = token
      load && this.load()
    },
    set(user?: IUser) {
      self.user = user ? User.create(user) : null
    },
    updateUser<T extends keyof IUser>(key: T, value: IUser[T]) {
      if (!self.user) {
        return
      }

      // Update the user data in the session slice
      self.user[key] = value
    },
    load: async function () {
      try {
        if (!self.accessToken) {
          throw new Error('No access token')
        }

        self.isLoading = true

        const user = await SessionAPI.get(self.accessToken)
        this.set({ ...user, cv: null })

        self.isLoading = false
      } catch (error) {
        this.clear()
      }
    },
  }))
  .views((self) => ({
    get isAuthenticated() {
      return !self.isLoading && self.user !== null
    },

    get role(): string | null {
      return self.user?.roleId || null
    },

    async isAuthorized(perm: string): Promise<boolean> {
      if (!this.isAuthenticated || !this.role) {
        return false
      }

      return await SessionAPI.hasPerm(this.role.toString(), perm)
    },
  }))
  .create({
    accessToken: null,
    user: null,
  })

export type ISessionSlice = Instance<typeof sessionSlice>
export default sessionSlice
