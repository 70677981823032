import AuthOptions from '@entities/oauth/ui/auth-options'
import ForgotPasswordForm from '@features/auth/forgot-password/ui/form'
import { useAuthenComplete } from '@features/auth/lib/hooks'

export default function FLogin() {
  const onComplete = useAuthenComplete()

  return (
    <>
      <ForgotPasswordForm onComplete={onComplete} />
      {/* Other login options */}
      <AuthOptions />
    </>
  )
}
