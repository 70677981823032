import { Duration, Location } from '@shared/model/usercv'
import { Instance, types } from 'mobx-state-tree'

const WorkExperience = types
  .model('WorkExperience', {
    organization: types.string,
    location: types.maybeNull(Location),
    title: types.string,
    description: types.maybeNull(types.string),
    dates: Duration,
    months: types.maybeNull(types.number),
  })
  .views((self) => ({
    get duration() {
      return self.dates.from + ' - ' + (self.dates.isCurrent ? 'Present' : self.dates.to)
    },

    get isVolunteerRole() {
      return (
        self.title.toLowerCase().includes('volunteer') ||
        self.description?.toLowerCase().includes('volunteer')
      )
    },
  }))

export type IWorkExperience = Instance<typeof WorkExperience>
export default WorkExperience
