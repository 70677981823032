import { z } from 'zod'

const formSchema = z.object({
  firstName: z
    .string()
    .min(1, { message: 'First name is required' })
    .regex(/^[a-zA-Z\s]*$/, { message: 'First name must contain only letters' }),
  lastName: z
    .string()
    .min(1, { message: 'Last name is required' })
    .regex(/^[a-zA-Z\s]*$/, { message: 'Last name must contain only letters' }),
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Must be a valid email' }),
  phoneNumber: z
    .string()
    .min(1, { message: 'Phone number is required' })
    .regex(
      /^(?:([+]\d{1,4})[-.\s]?)?(?:[(](\d{1,3})[)][-.\s]?)?(\d{1,4})[-.\s]?(\d{1,4})[-.\s]?(\d{1,9})$/,
      { message: 'Phone number must be a valid format' },
    ),
  birthDate: z.date({ coerce: true }).refine(
    (date) => {
      const age = new Date().getFullYear() - date.getFullYear()
      return age >= 16
    },
    {
      message: 'You must be at least 16 years old',
    },
  ),
  profession: z.string().min(1, { message: 'Profession is required' }),
  yearsOfExperience: z
    .number({ coerce: true })
    .int({ message: 'Years of experience must be a whole number' })
    .min(0, { message: 'Years of experience must be a positive number' })
    .max(100, { message: 'Years of experience must be less than 70' }),
})

export type IFormSchema = z.infer<typeof formSchema>
export default formSchema
