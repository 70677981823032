import { ReactNode, useState } from 'react'
import { Outlet, useOutlet } from 'react-router-dom'

import { default as adminNavItems } from '@features/dashboard/admin/nav-items'
import DashboardFooter from '@features/dashboard/footer'
import DashboardHeader from '@features/dashboard/header'
import Sidebar from '@features/dashboard/sidebar'
import { default as userNavItems } from '@features/dashboard/user/nav-items'

type Props = {
  children: ReactNode
}

const DashboardLayout = ({ children }: Props) => {
  const outlet = useOutlet()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [role, _] = useState(location.pathname.startsWith('/admin/dashboard') ? 'admin' : 'user')

  const [headerTitle, setHeaderTitle] = useState('')
  const handleNavLinkClick = (title: string) => {
    setHeaderTitle(title)
  }

  return (
    <main>
      <div className="flex">
        <Sidebar
          navItems={role === 'user' ? userNavItems : adminNavItems}
          onNavLinkClick={handleNavLinkClick}
        />

        <div className="w-full ">
          <DashboardHeader title={headerTitle} />
          <div className="px-2 md:px-10">
            {outlet ? <Outlet /> : children}
            <DashboardFooter />
          </div>
        </div>
      </div>
    </main>
  )
}

export type IAuthLayoutProps = Props
export default DashboardLayout
