import { RouteObject } from 'react-router-dom'

import DashboardLayout from '@features/dashboard/layout'
import SubscribeToRevamp from '@features/dashboard/user/settings/ui/subscribe-to-revamp'
import Dashboard from '@pages/dashboard/user/dashboard'
import Jobs from '@pages/dashboard/user/jobs'
import Settings from '@pages/dashboard/user/settings'
import ROUTEMAP from '@shared/routemap.json'

export default {
  path: ROUTEMAP.user.DASHBOARD,
  element: (
    <DashboardLayout>
      <Dashboard />
    </DashboardLayout>
  ),
  children: [
    {
      path: 'jobs',
      element: <Jobs />,
    },
    {
      path: 'settings',
      element: <Settings />,
    },

    {
      path: 'subscribe-to-revamp',
      element: <SubscribeToRevamp />,
    },
  ],
} as RouteObject
