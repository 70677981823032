import EditPen from '@/assets/images/user-dashboard/edit_pen_blue.png'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

const PersonalDeatailsForm = () => {
  return (
    <div>
      <div className="md:border-b flex justify-between items-center border-[#00071433] py-2 md:py-5">
        <div>
          <p className="text-[12px] md:text-lg font-semibold ">Personal Info</p>
          <p className="text-[12px] md:text-base font-normal ">Update your personal details here</p>
        </div>
        <div className="md:flex gap-4 hidden">
          <Button variant="outline" color="#0959FE">
            Cancel
          </Button>
          <Button variant="filled" color="#0959FE">
            Save
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-12 md:border-b border-[#00071433] item gap-2 md:gap-4 py-2 md:py-5">
        <p className="text-[12px] md:text-lg font-semibold col-span-12 md:col-span-2">Full Name</p>
        <Input className="col-span-11 md:col-span-8" placeholder="Joseph Ngumbau" type="text" />
        <div className="col-span-1 flex items-center gap-3">
          <img src={EditPen} alt="edit-pen" className="w-6 h-6" />
          <p className="text-lg hidden md:block font-normal text-[#0959FE] ">Edit</p>
        </div>
      </div>
      <div className="grid grid-cols-12 md:border-b border-[#00071433] item gap-2 md:gap-4 py-2 md:py-5">
        <p className="text-[12px] md:text-lg font-semibold col-span-12 md:col-span-2">Email</p>
        <Input
          className="col-span-12 md:col-span-8"
          placeholder="tranthuy.nute@gmail.com"
          type="email"
        />
      </div>
      <div className="grid grid-cols-12 md:border-b  border-[#00071433] item gap-2 md:gap-4 py-2 md:py-5">
        <p className="text-[12px] md:text-lg font-semibold col-span-12 md:col-span-2">Password</p>
        <Input className="col-span-12 md:col-span-8" placeholder="********" type="password" />
      </div>
      <div className="grid grid-cols-12 md:border-b  border-[#00071433] item gap-2 md:gap-4 py-2 md:py-5">
        <p className="text-[12px] md:text-lg font-semibold col-span-12 md:col-span-2">Address</p>
        <Input
          className="col-span-11 md:col-span-8"
          placeholder="510 Kampong Bahru Rd Singapore 099446"
          type="text"
        />
        <div className="col-span-1 flex items-center gap-3">
          <img src={EditPen} alt="edit-pen" className="w-6 h-6" />
          <p className="text-lg font-normal text-[#0959FE] hidden md:block ">Edit</p>
        </div>
      </div>
      <div className="grid grid-cols-12 md:border-b border-[#00071433] item gap-2 md:gap-4 py-2 md:py-5">
        <p className="text-[12px] md:text-lg font-semibold col-span-12 md:col-span-2">
          Phone Number
        </p>
        <Input className="col-span-11 md:col-span-8" placeholder="+7 (903) 941-02-27" type="text" />
        <div className="col-span-1 flex items-center gap-3">
          <img src={EditPen} alt="edit-pen" className="w-6 h-6" />
          <p className="text-lg font-normal text-[#0959FE] hidden md:block ">Edit</p>
        </div>
      </div>
      <div className="flex gap-5 my-10 md:hidden">
        <Button variant="outline" color="#0959FE" className="w-1/2">
          Cancel
        </Button>
        <Button variant="filled" color="#0959FE" className="w-1/2">
          Save
        </Button>
      </div>
    </div>
  )
}

export default PersonalDeatailsForm
