import { forwardRef } from 'react'
import { NavLink } from 'react-router-dom'

import { Textify } from '@shared/lib'
import clsx from 'clsx'

import { NavItem } from './types'

type Props = {
  items: NavItem[]
}

const NavItems = forwardRef<HTMLDivElement, Props>(({ items }: Props, ref) => {
  return (
    <div
      className="md:static z-50 md:z-0 border-b md:border-0 hidden md:flex absolute rounded-b-md md:rounded-b-none bg-primary-900 md:bg-transparent text-white md:text-current-900 p-6 md:p-0 left-0 top-full w-full md:w-fit items-center transition-colors duration-300 ease-out"
      ref={ref}
    >
      <nav className="flex gap-6 md:items-center text-[18px] flex-col md:flex-row w-full md:w-fit">
        {items.map((item) => (
          <NavLink
            to={Textify.constructURL(item.route)}
            key={item.label}
            className={({ isActive, isTransitioning }) =>
              clsx(
                // common classes across all screen sizes
                'p-2 hover:font-medium transition-color duration-300 ease-in',
                // mobile screen classes
                'rounded text-center bg-current-200 hover:bg-current-400',
                // desktop screen classes
                'md:rounded-none md:text-xs lg:text-base md:bg-transparent md:hover:bg-transparent',
                {
                  'font-medium bg-current-500 md:bg-transparent': isActive,
                  'opacity-40': isTransitioning,
                },
              )
            }
          >
            {item.label}
          </NavLink>
        ))}
      </nav>
    </div>
  )
})

NavItems.displayName = 'NavItems'
export default NavItems
