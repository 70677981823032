import { ReactNode } from 'react'
import { Outlet, useOutlet } from 'react-router-dom'

type Props = {
  children: ReactNode
  footer: ReactNode | null
  dark?: boolean
}

const AuthLayout = ({ children, footer }: Props) => {
  const outlet = useOutlet()

  return (
    <main className="flex items-center justify-center min-h-screen bg-gray-100 overflow-y-auto py-6">
      <div className="font-sans w-full max-w-md p-4 pb-3 mx-2 bg-white rounded-md shadow-md border border-secondary-200">
        {outlet ? <Outlet /> : children}
        {footer && <footer className="mt-4">{footer}</footer>}
      </div>
    </main>
  )
}

export type IAuthLayoutProps = Props
export default AuthLayout
