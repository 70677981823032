import { useState } from 'react'
import { NavLink } from 'react-router-dom'

import LogoutIcon from '@/assets/images/user-dashboard/logout_icon_black.png'
import MenuIcon from '@/assets/images/user-dashboard/menu.png'
import NotificationBell from '@/assets/images/user-dashboard/notification_bell.png'
import ProfileIcon from '@/assets/images/user-dashboard/profile.png'
import ProfileAvatar from '@/assets/images/user-dashboard/profile_avatar.png'
import LockIcon from '@/assets/images/user-dashboard/secure_lock_icon.png'

interface DashboardHeaderProps {
  title: string
}

const DashboardHeader: React.FC<DashboardHeaderProps> = ({ title }: DashboardHeaderProps) => {
  const [isMobileNavVisible, setIsMobileNavVisible] = useState(false)

  const toggleMobileNav = () => {
    setIsMobileNavVisible(!isMobileNavVisible)
  }

  return (
    <>
      <nav className="flex justify-between items-center py-3 px-2 md:px-10 border-b border-[#00071433] mb-4">
        <h2 className="hidden text-2xl font-semibold md:block">{title}</h2>
        <button onClick={toggleMobileNav}>
          <img src={MenuIcon} alt="menu" className="block cursor-pointer md:hidden" />
        </button>
        <div className="flex gap-2">
          <img src={NotificationBell} alt="notification-bell" className="w-6 h-6" />
          <div className="relative">
            <button className="text-white group">
              <img src={ProfileAvatar} alt="profile-avatar" className="w-6 h-6" />
              <div className="z-10 hidden bg-white absolute rounded-[5px] shadow-md w-40 top-full left-0  py-1 group-focus:block">
                <ul className="py-2 text-sm text-gray-950">
                  <li>
                    <NavLink
                      to=""
                      className="flex items-center text-[12px] font-semibold gap-2 px-3 py-2 hover:bg-[#0959FE] hover:text-white"
                    >
                      <img src={ProfileIcon} alt="profile-icon" />
                      My Profile
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to=""
                      className="flex items-center text-[12px] font-semibold gap-2 px-3 py-2 hover:bg-[#0959FE] hover:text-white"
                    >
                      <img src={LockIcon} alt="lock-icon" color="black" />
                      Change Password
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to=""
                      className="flex items-center text-[12px] font-semibold gap-2 px-3 py-2 hover:bg-[#0959FE] hover:text-white"
                    >
                      <img src={LogoutIcon} alt="logout-icon" color="black" />
                      Logout
                    </NavLink>
                  </li>
                </ul>
              </div>
            </button>
          </div>
          <p className="text-base ">kunle design</p>
        </div>
      </nav>
      <div className={`mobile-nav ${isMobileNavVisible ? 'block' : 'hidden'}`}>
        <div className="flex flex-col text-center">
          <NavLink to="/user/dashboard" className="py-1 cursor-pointer hover:bg-slate-100">
            Dashboard
          </NavLink>
          <NavLink
            to="/user/dashboard/downloads"
            className="py-1 cursor-pointer hover:bg-slate-100"
          >
            Downloads
          </NavLink>
          <NavLink to="/user/dashboard/jobs" className="py-1 cursor-pointer hover:bg-slate-100">
            Job
          </NavLink>
          <NavLink to="/user/dashboard/settings" className="py-1 cursor-pointer hover:bg-slate-100">
            Settings
          </NavLink>
          <NavLink to="" className="py-1 cursor-pointer hover:bg-slate-100">
            Logout
          </NavLink>
        </div>
      </div>
    </>
  )
}

export default DashboardHeader
