const Cookie = () => {
  return (
    <div className="w-full h-full pt-12 bg-white md:pt-24">
      <div className="max-w-[1000px] mx-auto">
        <main className="text-xs font-normal md:text-base ">
          <h3 className="mb-6 text-base font-medium md:text-2xl md:mb-12">COOKIE POLICY</h3>
          <p className="mb-5 md:mb-10">
            This Cookie Policy explains how Revamp Insights (“we,” “our,” “us”) uses cookies and
            similar tracking technologies on our platform. By using the Revamp Insights website or
            services, you agree to the use of cookies as described in this policy. If you do not
            agree to the use of cookies, you may disable them in your browser settings as outlined
            below.
          </p>
          <ol className="text-xs font-medium list-decimal md:text-lg">
            <li className="mb-5 md:mb-10">
              What Are Cookies?
              <p className="text-xs font-normal mb-1 md:text-base">
                Cookies are small text files that are stored on your device (computer, smartphone,
                or tablet) when you visit a website. They are widely used to make websites work more
                efficiently, as well as to provide information to the site owners. Cookies help us
                improve your user experience by enabling features such as remembering your
                preferences and helping us understand how our platform is being used.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">1.1. Types of Cookies We Use</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                1.1.1. Strictly Necessary Cookies
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                These cookies are essential for the functioning of our platform. They enable you to
                navigate the site and use its features, such as accessing secure areas and
                performing transactions. Without these cookies, services like account logins or job
                application submissions cannot be provided.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                1.1.2. Performance and Analytics Cookies
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                These cookies collect information about how visitors use our platform, such as the
                pages you visit most often and whether you encounter any errors. The data collected
                is aggregated and anonymous, and it helps us improve the performance of the platform
                and optimize the user experience.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">1.1.3. Functional Cookies</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                These cookies allow our platform to remember choices you make, such as your language
                preference or region, to provide a more personalized experience. Functional cookies
                may also remember changes you’ve made to customizable parts of the platform, such as
                fonts or text sizes.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                1.1.4. Targeting and Advertising Cookies
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                We may use these cookies to deliver advertisements that are more relevant to you and
                your interests. They are also used to limit the number of times you see an
                advertisement and to measure the effectiveness of advertising campaigns. These
                cookies are usually placed by third-party advertisers and ad networks with our
                permission.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">1.1.5. Third-Party Cookies</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                In addition to the cookies set by Revamp Insights, third parties (such as analytics
                providers and advertisers) may also set cookies when you interact with our platform.
                These cookies collect data about your browsing habits across different websites and
                services to serve ads relevant to you.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                1.2. How Long Do Cookies Last?
              </p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">
                  Session Cookies: These cookies are temporary and expire once you close your
                  browser.
                </li>
                <li className="text-xs font-normal md:text-base">
                  Session Cookies: These cookies are temporary and expire once you close your
                  browser.
                </li>
              </ul>
            </li>
            <li className="mb-5 md:mb-10">
              Why We Use Cookies
              <p className="text-xs mb-1 font-normal md:text-base">
                We use cookies for several reasons, including:
              </p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">
                  To ensure the platform functions properly: Cookies help us remember your
                  preferences and settings to enhance your experience on our site.
                </li>
                <li className="text-xs font-normal md:text-base">
                  To analyze and improve performance: Cookies help us track site usage and identify
                  any potential issues, allowing us to optimize functionality.
                </li>
                <li className="text-xs font-normal md:text-base">
                  To provide personalized content: Cookies enable us to tailor the content and
                  recommendations displayed to your interests and behaviors.
                </li>
                <li className="text-xs font-normal md:text-base">
                  To enable advertising: Cookies help deliver relevant ads based on your browsing
                  habits and measure the effectiveness of our marketing campaigns.
                </li>
              </ul>
            </li>
            <li className="mb-5 md:mb-10">
              How You Can Manage Cookies
              <p className="text-xs font-normal mb-1 md:text-base">
                You have the right to choose whether to accept cookies or not. Most web browsers
                allow you to manage cookies through their settings. However, please note that if you
                disable cookies, some features of the Revamp Insights platform may not function
                properly or may become unavailable.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                3.1. Managing Cookies via Browser Settings
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                You can typically adjust your cookie settings in your browser. The steps vary
                depending on the browser, but here are links to instructions for some commonly used
                browsers:
              </p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base underline">Google Chrome</li>
                <li className="text-xs font-normal md:text-base underline">Mozilla Firefox</li>
                <li className="text-xs font-normal md:text-base underline">Microsoft Edge</li>
                <li className="text-xs font-normal md:text-base underline">Safari</li>
              </ul>
              <p className="text-xs mb-1 font-normal md:text-base">
                3.2. Opting Out of Third-Party Cookies
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                If you would like to opt out of third-party cookies related to targeted advertising,
                you can do so through tools provided by those third parties or via industry opt-out
                programs such as:
              </p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">Your Online Choices</li>
                <li className="text-xs font-normal md:text-base">Network Advertising Initiative</li>
              </ul>
              <p className="text-xs mb-1 font-normal md:text-base">
                Please note that opting out of third-party cookies does not mean you will stop
                seeing ads; it simply means the ads you see may not be as tailored to your
                interests.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              Consent to Use of Cookies
              <p className="text-xs mb-1 font-normal md:text-base">
                When you first visit the Revamp Insights platform, you will be notified that we use
                cookies. By continuing to use our platform, you consent to our use of cookies as
                described in this Cookie Policy unless you adjust your browser settings to disable
                them.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              Changes to This Cookie Policy
              <p className="text-xs mb-1 font-normal md:text-base">
                We may update this Cookie Policy from time to time to reflect changes in our
                practices or legal obligations. When we do, we will post the updated policy on our
                platform with a new effective date. We encourage you to review this policy
                periodically to stay informed about how we use cookies.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              Contact Us
              <p className="text-xs mb-1 font-normal md:text-base">
                If you have any questions or concerns about this Data Policy, please contact us at:{' '}
                <br /> support@revampinsights.com.
              </p>
            </li>
          </ol>
        </main>
      </div>
    </div>
  )
}

export default Cookie
