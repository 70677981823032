import { useEffect, useState } from 'react'

import { DetailedStep } from '@shared/types'
import CountLabel from '@shared/ui/detailed-steps/count-label'
import DottedLines from '@shared/ui/detailed-steps/dotted-lines'
import clsx from 'clsx'

type Props = {
  steps: DetailedStep[]
  stepComponent?: React.FC<DetailedStep & { is_active?: boolean }>
  currentStep?: number
  interactive?: boolean
  horizontal?: boolean
  className?: string
  onStepChange?: (step: number) => void
  css?: {
    step?: string
    stepTitle?: string
    stepDescription?: string
    stepIcon?: string
    stepBox?: string
  }
}

const DetailedSteps: React.FC<Props> = ({
  steps,
  stepComponent: StepComponent,
  currentStep = 0,
  interactive = false,
  horizontal = false,
  onStepChange,
  className,
  css,
}: Props) => {
  const [activeStep, setActiveStep] = useState(currentStep)

  useEffect(() => {
    setActiveStep(currentStep)
  }, [currentStep])

  const handleStepChange = (step: number) => {
    if (!onStepChange) return

    setActiveStep(step)
    onStepChange(step)
  }

  return (
    <div
      className={clsx(
        'flex',
        {
          'flex-row': horizontal,
          'flex-col': !horizontal,
        },
        className,
      )}
    >
      {steps.map((step, index) => (
        <div
          className={clsx(
            'flex flex-1 gap-3',
            {
              'flex-row': !horizontal,
              'flex-col': horizontal,
              'cursor-pointer': interactive,
              'cursor-not-allowed pointer-events-none': !interactive,
            },
            css?.stepBox,
          )}
          key={step.title}
          onClick={() => handleStepChange(index)}
          onKeyDown={(e) => e.key === 'Enter' && handleStepChange(index)}
          role="button"
          tabIndex={0}
          title={step.title}
        >
          <div
            className={clsx('flex items-center relative', {
              'flex-col ': !horizontal,
              'flex-row': horizontal,
              'justify-center': horizontal && steps.length - 1 !== index,
            })}
          >
            <CountLabel
              count={index + 1}
              is_active={interactive && activeStep === index}
              icon={step.icon}
            />
            <DottedLines
              className={clsx({
                'md:hidden': steps.length - 1 === index && !horizontal,
                hidden: steps.length - 1 === index && horizontal,
                'border-solid': interactive && activeStep > index,
                'border-dashed': !interactive || (interactive && activeStep < index),
              })}
              color="primary-900"
              horizontal={horizontal}
            />
          </div>
          {StepComponent ? (
            <StepComponent {...step} is_active={interactive && activeStep === index} />
          ) : (
            <div className={clsx('flex flex-col gap-1', css?.step)}>
              <h4 className={css?.stepTitle}>{step.title}</h4>
              {step.description && (
                <p className={clsx('text-sm', css?.stepDescription)}>{step.description}</p>
              )}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export type IDetailedStepsProps = Props
export default DetailedSteps
