import PricingPlanCard from './plan-card'
import PLANS from './plans'

type Props = {
  onSubscribe?: () => Promise<void>
}

const PricingPlans = ({ onSubscribe }: Props) => {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-5 md:gap-10 my-10">
      {PLANS.map((plan, i) => (
        <PricingPlanCard key={i} {...plan} onSubscribe={onSubscribe} />
      ))}
    </div>
  )
}

export default PricingPlans
