import { useNavigate } from 'react-router-dom'

import ROUTEMAP from '@shared/routemap.json'
import PricingPlans from '@shared/ui/pricing-plans'

export default function PricingSection() {
  const navigate = useNavigate()
  return (
    <div className="py-10 bg-white">
      <section className="max-w-[900px] mx-auto px-5">
        <div className="flex flex-col gap-3 mb-5">
          <h4 className="text-2xl font-medium text-center md:text-4xl">
            Simple, Affordable <span className="text-primary-900">Pricing</span>
          </h4>
          <p className="text-sm text-center text-current-700">
            Choose one of our Pro Plans and enjoy the benefits that come with it
          </p>
        </div>
        <PricingPlans onSubscribe={async () => navigate(ROUTEMAP.auth.LOGIN)} />
      </section>
    </div>
  )
}
