import { useState } from 'react'

import TableComponent from '@shared/ui/table'
import { ColumnDef } from '@tanstack/react-table'

import CvTemplate from '@/assets/images/user-dashboard/cv_template.png'
import DashboardHeroImage from '@/assets/images/user-dashboard/dashboard_hero_image.png'
import DeleteIcon from '@/assets/images/user-dashboard/delete.png'
import EditIcon from '@/assets/images/user-dashboard/fe_edit.png'
import { Button } from '@/components/ui/button'

export type CVCreated = {
  id: string
  fullName: string
  modification: string
  dateCreated: string
  timeCreated: string
  status: 'pending' | 'successful' | 'failed'
}

export type JobsCreated = {
  id: string
  fullName: string
  job: string
  jobDescription: string
  dateApplied: string
  status: 'pending' | 'successful' | 'failed'
}

const cvCretedData: CVCreated[] = [
  {
    id: '1',
    fullName: 'Ikperi David',
    modification: '17 Oct, 2020',
    dateCreated: '24 May, 2020',
    timeCreated: '10:41 pm',
    status: 'successful',
  },
  {
    id: '2',
    fullName: 'Ikperi David',
    modification: '22 Oct, 2020',
    dateCreated: '8 Sep, 2020',
    timeCreated: '07:38 am',
    status: 'successful',
  },
  {
    id: '3',
    fullName: 'Ikperi David',
    modification: '8 Sep, 2020',
    dateCreated: '21 Sep, 2020',
    timeCreated: '02:02 am',
    status: 'pending',
  },
  {
    id: '4',
    fullName: 'Ikperi David',
    modification: '24 May, 2020',
    dateCreated: '1 Feb, 2020',
    timeCreated: '04:02 am',
    status: 'successful',
  },
  {
    id: '5',
    fullName: 'Ikperi David',
    modification: '24 May, 2020',
    dateCreated: '22 Oct, 2020',
    timeCreated: '11:49 pm',
    status: 'failed',
  },
]

const jobsCreatedData: JobsCreated[] = [
  {
    id: '1',
    fullName: 'Ikperi David',
    job: '17 Oct, 2020',
    jobDescription: 'Lorem ipsum dolor sit amet, consectetur......',
    dateApplied: '24 May, 2020',
    status: 'successful',
  },
  {
    id: '2',
    fullName: 'Ikperi David',
    job: '17 Oct, 2020',
    jobDescription: 'Lorem ipsum dolor sit amet, consectetur......',
    dateApplied: '24 May, 2020',
    status: 'pending',
  },
  {
    id: '3',
    fullName: 'Ikperi David',
    job: '17 Oct, 2020',
    jobDescription: 'Lorem ipsum dolor sit amet, consectetur......',
    dateApplied: '24 May, 2020',
    status: 'failed',
  },
  {
    id: '4',
    fullName: 'Ikperi David',
    job: '17 Oct, 2020',
    jobDescription: 'Lorem ipsum dolor sit amet, consectetur......',
    dateApplied: '24 May, 2020',
    status: 'successful',
  },
  {
    id: '5',
    fullName: 'Ikperi David',
    job: '17 Oct, 2020',
    jobDescription: 'Lorem ipsum dolor sit amet, consectetur......',
    dateApplied: '24 May, 2020',
    status: 'successful',
  },
  {
    id: '6',
    fullName: 'Ikperi David',
    job: '17 Oct, 2020',
    jobDescription: 'Lorem ipsum dolor sit amet, consectetur......',
    dateApplied: '24 May, 2020',
    status: 'pending',
  },
  {
    id: '7',
    fullName: 'Ikperi David',
    job: '17 Oct, 2020',
    jobDescription: 'Lorem ipsum dolor sit amet, consectetur......',
    dateApplied: '24 May, 2020',
    status: 'successful',
  },
  {
    id: '8',
    fullName: 'Ikperi David',
    job: '17 Oct, 2020',
    jobDescription: 'Lorem ipsum dolor sit amet, consectetur......',
    dateApplied: '24 May, 2020',
    status: 'failed',
  },
]

const columns: ColumnDef<CVCreated>[] = [
  {
    accessorKey: 'id',
    header: () => <div>#</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('id')}</p>,
  },
  {
    accessorKey: 'fullName',
    header: () => <div>Full Name</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('fullName')}</p>,
  },
  {
    accessorKey: 'modification',
    header: () => <div>Modification</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('modification')}</p>,
  },
  {
    accessorKey: 'dateCreated',
    header: () => <div>Date Created</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('dateCreated')}</p>,
  },
  {
    accessorKey: 'modification',
    header: () => <div>Modification</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('modification')}</p>,
  },
  {
    accessorKey: 'timeCreated',
    header: () => <div>Time Created</div>,
    cell: ({ row }) => <div className="capitalize">{row.getValue('timeCreated')}</div>,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      let statusClass = ''

      if (row.getValue('status') === 'successful') {
        statusClass = 'text-[#2CCF54] bg-[#2CCF541A] px-1 text-center'
      } else if (row.getValue('status') === 'pending') {
        statusClass = 'text-[#FFC107] bg-[#FFC1071A] px-1 text-center'
      } else if (row.getValue('status') === 'failed') {
        statusClass = 'text-[#EB001B] bg-[#EB001B1A] px-1 text-center'
      }

      return <div className={`capitalize ${statusClass}`}>{row.getValue('status')}</div>
    },
  },
  {
    id: 'actions',
    header: 'Action',
    cell: () => {
      const [isModalOpen, setIsModalOpen] = useState(false)

      const handleLogoutClick = () => {
        setIsModalOpen(true)
      }
      const handleCloseModal = () => {
        setIsModalOpen(false)
      }

      return (
        <>
          <div className="flex gap-1 md:gap-3">
            <img src={EditIcon} alt="edit-icon" className="w-[8px] md:w-[20px]" />
            <button onClick={handleLogoutClick}>
              <img src={DeleteIcon} alt="delete-icon" className="w-[8px] md:w-[20px]" />
            </button>
          </div>
          {isModalOpen && (
            <div
              className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50"
              onClick={handleCloseModal}
              aria-hidden="true"
            >
              <div
                className="relative w-1/2 p-6 bg-white rounded-lg shadow-lg"
                onClick={(e) => e.stopPropagation()}
                aria-hidden="true"
              >
                <h3 className="text-3xl text-center text-[#0F1022] font-semibold mb-4">
                  You are about to delete your resume are you sure you want to proceed
                </h3>
                <div className="flex justify-center gap-4">
                  <Button variant="outline" color="#0959FE" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button
                    variant="filled"
                    color="#0959FE"
                    onClick={() => {
                      handleCloseModal()
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )
    },
  },
]
const jobsColumns: ColumnDef<JobsCreated>[] = [
  {
    accessorKey: 'id',
    header: () => <div>#</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('id')}</p>,
  },
  {
    accessorKey: 'fullName',
    header: () => <div>Full Name</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('fullName')}</p>,
  },
  {
    accessorKey: 'job',
    header: () => <div>Job</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('job')}</p>,
  },
  {
    accessorKey: 'jobDescription',
    header: () => <div>Job Description</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('jobDescription')}</p>,
  },
  {
    accessorKey: 'dateApplied',
    header: () => <div>Date Applied</div>,
    cell: ({ row }) => <p className="capitalize">{row.getValue('dateApplied')}</p>,
  },
  {
    accessorKey: 'status',
    header: 'Status',
    cell: ({ row }) => {
      let statusClass = ''

      if (row.getValue('status') === 'successful') {
        statusClass = 'text-[#2CCF54] bg-[#2CCF541A] px-1 text-center'
      } else if (row.getValue('status') === 'pending') {
        statusClass = 'text-[#FFC107] bg-[#FFC1071A] px-1 text-center'
      } else if (row.getValue('status') === 'failed') {
        statusClass = 'text-[#EB001B] bg-[#EB001B1A] px-1 text-center'
      }

      return <div className={`capitalize ${statusClass}`}>{row.getValue('status')}</div>
    },
  },
  {
    id: 'actions',
    header: 'Action',
    cell: () => {
      const [isModalOpen, setIsModalOpen] = useState(false)

      const handleLogoutClick = () => {
        setIsModalOpen(true)
      }
      const handleCloseModal = () => {
        setIsModalOpen(false)
      }

      return (
        <>
          <div className="flex gap-1 md:gap-3">
            <img src={EditIcon} alt="edit-icon" className="w-[8px] md:w-[20px]" />
            <button onClick={handleLogoutClick}>
              {' '}
              <img src={DeleteIcon} alt="delete-icon" className="w-[8px] md:w-[20px]" />
            </button>
          </div>
          {isModalOpen && (
            <div
              className="fixed inset-0 z-10 flex items-center justify-center bg-black bg-opacity-50"
              onClick={handleCloseModal}
              aria-hidden="true"
            >
              <div
                className="relative w-1/2 p-6 bg-white rounded-lg shadow-lg"
                onClick={(e) => e.stopPropagation()}
                aria-hidden="true"
              >
                <h3 className="text-3xl text-center text-[#0F1022] font-semibold mb-4">
                  You are about to delete your resume are you sure you want to proceed
                </h3>
                <div className="flex justify-center gap-4">
                  <Button variant="outline" color="#0959FE" onClick={handleCloseModal}>
                    Cancel
                  </Button>
                  <Button
                    variant="filled"
                    color="#0959FE"
                    onClick={() => {
                      handleCloseModal()
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </div>
          )}
        </>
      )
    },
  },
]

const Dashboard = () => {
  return (
    <div>
      <img src={DashboardHeroImage} alt="hero" />
      <div className="flex flex-col gap-2 mb-5 md:flex-row w-100">
        <img src={CvTemplate} alt="cv-template" className="w-80" />
        <TableComponent<CVCreated>
          data={cvCretedData}
          columns={columns}
          title="List of CV created"
          filterPlaceholder="Search by full name..."
          createButtonText="Create CV"
          showCreateButton={true}
          createButtonVariant="filled"
        />
      </div>
      <TableComponent<JobsCreated>
        data={jobsCreatedData}
        columns={jobsColumns}
        title="List of Job created"
        filterPlaceholder="Search for job..."
        showCreateButton={false}
        createButtonVariant="outline"
      />
    </div>
  )
}

export default Dashboard
