import { forwardRef } from 'react'

import { Textify } from '@shared/lib'
import { Icon, RankedList } from '@shared/ui'
import { CVTemplateProps } from '@widgets/types'
import clsx from 'clsx'

const Template4 = forwardRef<HTMLDivElement, CVTemplateProps>(
  ({ className, cv }: CVTemplateProps, ref) => {
    return (
      <div
        ref={ref}
        className={clsx('flex flex-col gap-1 py-2 items-start text-[#363435]', className)}
      >
        <header className="w-full py-5 md:px-3">
          <h3 className="text-2xl lg:text-3xl font-semibold uppercase leading-6 relative">
            <span className="text-[#d1ae6c] p-0">{cv?.firstName}</span>
            <br />
            <span> {cv?.lastName}</span>
            {/* More kites */}
            <div className="flex items-center justify-center absolute right-10 top-1/2 transform -translate-y-1/2">
              <div className="w-6 h-6 border relative border-[#363435] rotate-45 -ml-5 z-40"></div>
              <div className="w-6 h-6 border relative border-[#363435] rotate-45 -ml-5 z-30"></div>
              <div className="w-6 h-6 border relative rotate-45 -ml-5 z-20 border-[#d1ae6c]"></div>
              <div className="w-6 h-6 border relative rotate-45 -ml-5 z-10 border-[#d1ae6c]"></div>
            </div>
          </h3>
          <div className="relative">
            <div className="flex items-center">
              <div className="w-10/12 mt-[0.05rem] border border-[#363435] grow"></div>
              {/* A kite */}
              <div className="w-6 h-6 border border-[#363435] rotate-45 ml-1"></div>
            </div>
            <p className="text-xs font-light text-white bg-[#363435] px-2 py-0.5 w-fit absolute top-1/2">
              {cv?.profession}
            </p>
          </div>
        </header>
        <main className="flex items-start justify-between w-full gap-2 px-3">
          <div className="flex flex-col gap-2 w-1/2">
            <section>
              <h5 className="font-semibold text-xs border border-[#d1ae6c] px-1 mb-1 text-center">
                CONTACT
              </h5>
              <p className="text-xs font-light">
                <Icon icon="location-dot" color="#d1ae6c" className="mr-2" />
                {cv?.formattedAddress}
              </p>
              <p className="text-xs font-light">
                <Icon icon="envelope" color="#d1ae6c" className="mr-2" />
                {cv?.randomEmail}
              </p>
              <p className="text-xs font-light">
                <Icon icon="phone" color="#d1ae6c" className="mr-2" />
                {cv?.randomPhoneNumber}
              </p>
            </section>
            <section>
              <h5 className="font-semibold text-xs border border-[#d1ae6c] px-1 mb-1 text-center">
                PROFILE
              </h5>
              <p className="text-xs font-light">{cv?.summary}</p>
            </section>
            <section>
              <h5 className="font-semibold text-xs border border-[#d1ae6c] px-1 mb-1 text-center">
                WORK EXPERIENCE
              </h5>
              <div className="flex flex-col gap-3">
                {cv?.workExperience
                  .filter((exp) => !exp.isVolunteerRole)
                  .map((exp, index) => (
                    <div key={index}>
                      <div className="flex items-center justify-between">
                        <span className="text-xs font-semibold">{cv.profession}</span>
                        <span className="text-xs font-light">{exp.duration}</span>
                      </div>
                      <p className="text-xs font-medium">
                        {exp.organization},{' '}
                        {exp.location?.remote
                          ? 'Remote'
                          : exp.location?.city + ', ' + exp.location?.country}
                      </p>
                      <div className="text-xs font-light">{exp.description}</div>
                    </div>
                  ))}
              </div>
            </section>
          </div>
          <div className="flex flex-col gap-2 w-1/2">
            <section>
              <h5 className="font-semibold text-xs border border-[#d1ae6c] px-1 mb-1 text-center">
                EDUCATION AND QUALIFICATIONS
              </h5>
              <div className="flex flex-col gap-3 my-2">
                {cv?.education.map((edu, index) => (
                  <div
                    className="flex flex-col justify-between"
                    key={Textify.generateRandomString(7) + `-${index}`}
                  >
                    <p className="text-xs font-light">
                      {edu.accreditation.degree} {edu.accreditation.fieldOfStudy},{' '}
                      {edu.organization}, {edu.formattedLocation}
                    </p>
                    <p className="text-xs font-semibold text-end">
                      {edu.dates.from} - {edu.dates.isCurrent ? 'Present' : edu.dates.to}
                    </p>
                  </div>
                ))}
              </div>
            </section>
            <section>
              <h5 className="font-semibold text-xs border border-[#d1ae6c] px-1 mb-1 text-center">
                CAPABILITIES
              </h5>
              <RankedList
                items={cv?.skills.map((skill) => skill.name) || []}
                className={(rank, index) =>
                  clsx('grow', rank >= index ? 'bg-[#363435]' : 'bg-[#d1ae6c]', `w-[${100 / 7}%]`)
                }
                css={{
                  rankbar: 'gap-0 w-5/12 bg-[#363435]',
                  list: 'my-2',
                }}
              />
            </section>
            <section>
              <h5 className="font-semibold text-xs border border-[#d1ae6c] px-1 mb-1 text-center">
                CERTIFICATIONS
              </h5>
              <div className="flex flex-col gap-1">
                {cv?.certifications.map((cert, index) => (
                  <p className="text-xs font-light" key={index}>
                    {cert}
                  </p>
                ))}
              </div>
            </section>
          </div>
        </main>
        <footer className="mt-1 mb-3 relative w-full md:px-3">
          <div className="text-xs font-light text-white bg-[#d1ae6c] px-2 py-0.5 w-32 h-2 absolute bottom-1/2"></div>
          <div className="flex items-center w-full">
            <div className="w-10/12 grow border border-[#d1ae6c]"></div>
            {/* A kite */}
            <div className="w-6 h-6 border border-[#d1ae6c] rotate-45 ml-1"></div>
          </div>
        </footer>
      </div>
    )
  },
)

Template4.displayName = 'Template4'
export default Template4
