import DashboardIcon from '@/assets/images/user-dashboard/dashboard.png'
import DownloadIcon from '@/assets/images/user-dashboard/download.png'
import JobIcon from '@/assets/images/user-dashboard/job.png'
import SettingsIcon from '@/assets/images/user-dashboard/settings.png'

const navItems = [
  {
    path: '/user/dashboard',
    icon: DashboardIcon,
    label: 'Dashboard',
  },
  {
    path: '/user/dashboard/downloads',
    icon: DownloadIcon,
    label: 'Downloads',
  },
  { path: '/user/dashboard/jobs', icon: JobIcon, label: 'Jobs' },
  {
    path: '/user/dashboard/settings',
    icon: SettingsIcon,
    label: 'Settings',
  },
]

export default navItems
