import { RefObject, useEffect } from 'react'

const useCollapseOnOutsideClick = (ref: RefObject<HTMLElement>, onCollapse: () => void) => {
  useEffect(() => {
    const handleClickOutside = (e: MouseEvent) => {
      const indicator = ref.current?.previousElementSibling
      if (!ref.current || !indicator) return

      if (!ref.current.contains(e.target as Node) && !indicator.contains(e.target as Node)) {
        onCollapse()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])
}

export default useCollapseOnOutsideClick
