import { ReactNode } from 'react'
import { Outlet, useOutlet } from 'react-router-dom'

import { Footer } from '@shared/ui'
import Header from '@shared/ui/header'
import clsx from 'clsx'

type Props = {
  children: ReactNode
  dark?: boolean
  maxWidth?: string | boolean
  padded?: boolean
}

const AppLayout = ({ children, padded, maxWidth = false }: Props) => {
  const outlet = useOutlet()

  return (
    <>
      <Header />
      <main className="space-y-6">
        <div
          className={clsx('font-sans w-full', {
            'mx-auto': !!maxWidth,
            'p-4 pb-3': padded,
            'max-w-5xl': maxWidth === true,
            [`max-w-${maxWidth}`]: maxWidth && maxWidth !== true,
          })}
        >
          {outlet ? <Outlet /> : children}
        </div>
      </main>
      <Footer />
    </>
  )
}

export type IAppLayoutProps = Props
export default AppLayout
