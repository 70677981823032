import { useRef } from 'react'

import { AppLogo, Button, Icon } from '@shared/ui'
import NavItems from '@shared/ui/header/nav-items'

import ROUTEMAP from '@/shared/routemap.json'

import { NavItem } from './types'

const navItems: NavItem[] = [
  { label: 'Create CV', route: ROUTEMAP.cv.BUILD },
  { label: 'Jobs', route: ROUTEMAP.base.JOBS },
  { label: 'Test', route: ROUTEMAP.base.TEST },
  { label: 'About Us', route: ROUTEMAP.base.ABOUT },
  { label: 'Contact Us', route: ROUTEMAP.base.CONTACT },
]

const Header = () => {
  const navRef = useRef<HTMLDivElement>(null)
  const menuIconBoxRef = useRef<HTMLDivElement>(null)

  const handleNavToggle = (
    e: React.MouseEvent<HTMLDivElement> | React.KeyboardEvent<HTMLDivElement>,
  ) => {
    e.preventDefault()

    if (navRef.current) {
      navRef.current.classList.toggle('hidden')
    }

    if (menuIconBoxRef.current) {
      if (navRef.current?.classList.contains('hidden')) {
        menuIconBoxRef.current.classList.remove('rotate-180')
      } else {
        menuIconBoxRef.current.classList.add('rotate-180')
      }
    }
  }

  return (
    <header className="p-6 bg-white border-b md:border-0 md:bg-[#FAFDFF] relative">
      <div className="flex items-center justify-between gap-4 md:w-[92%] mx-auto">
        <AppLogo clickable />
        <NavItems items={navItems} ref={navRef} />
        <div className="flex items-center gap-6">
          <Button
            variant="primary"
            size="sm"
            onClick={() => window.location.replace(ROUTEMAP.auth.SIGNUP)}
            className="hidden md:flex"
          >
            Get Started
          </Button>
          <div
            ref={menuIconBoxRef}
            onClick={handleNavToggle}
            onKeyDown={(e) => e.key === 'Enter' && handleNavToggle(e)}
            role="button"
            tabIndex={0}
            className="flex md:hidden"
            title="Toggle Menu"
          >
            <Icon icon="bars-staggered" color="current-900" size="lg" />
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header
