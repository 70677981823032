import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select'

const AddAdmin = () => {
  return (
    <div className="bg-white mx-7 rounded-[10px] border border-[#EAE8EB] shadow-sm relative">
      <p className="text-base text-[#000714] md:p-6 p-3 border-b mb-4  border-[#EAE8EB] font-semibold md:text-xl">
        Admin
      </p>
      <div className="flex flex-col  h-[609px] md:pb-6 pb-3 justify-between">
        <div className="max-w-4xl px-3 md:px-6">
          <div className="flex flex-col items-center gap-4 mb-4 md:flex-row">
            <div className="w-full ">
              <p className="text-[12px] md:text-sm font-normal mb-1">First Name</p>
              <Input
                placeholder=""
                type="text"
                id="firstName"
                className="rounded-[5px] border-[#0F102280]"
              />
            </div>
            <div className="w-full ">
              <p className="text-[12px] md:text-sm font-normal mb-1">Last Name</p>
              <Input
                placeholder=""
                type="text"
                id="lastName"
                className="rounded-[5px] border-[#0F102280]"
              />
            </div>
            <div className="w-full ">
              <p className="text-[12px] md:text-sm font-normal mb-1">Phone Number</p>
              <Input
                placeholder=""
                type="text"
                id="phoneNumber"
                className="rounded-[5px] border-[#0F102280]"
              />
            </div>
          </div>
          <div className="flex flex-col items-center gap-4 mb-4 md:flex-row">
            <div className="w-full ">
              <p className="text-[12px] md:text-sm font-normal mb-1">Country</p>
              <Input
                placeholder=""
                type="text"
                id="country"
                className="rounded-[5px] border-[#0F102280]"
              />
            </div>
            <div className="w-full ">
              <p className="text-[12px] md:text-sm font-normal mb-1">State</p>
              <Input
                placeholder=""
                type="text"
                id="state"
                className="rounded-[5px] border-[#0F102280]"
              />
            </div>
            <div className="w-full ">
              <p className="text-[12px] md:text-sm font-normal mb-1">Email</p>
              <Input
                placeholder=""
                type="email"
                id="email"
                className="rounded-[5px] border-[#0F102280]"
              />
            </div>
          </div>
          <div className="grid items-center grid-cols-12 gap-4 mb-4 ">
            <div className="w-full col-span-4 ">
              <p className="text-[12px] md:text-sm font-normal mb-1">Address</p>
              <Input
                placeholder=""
                type="text"
                id="address"
                className="rounded-[5px] border-[#0F102280]"
              />
            </div>
            <div className="w-full col-span-4 ">
              <p className="text-[12px] md:text-sm font-normal mb-1">Role</p>
              <Select>
                <SelectTrigger className="rounded-[5px] border-[#0F102280]">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectGroup>
                    <SelectItem value="apple">Nigeria</SelectItem>
                    <SelectItem value="blueberry">Ghana</SelectItem>
                    <SelectItem value="banana">Egypt</SelectItem>
                    <SelectItem value="grapes">Kenya</SelectItem>
                    <SelectItem value="pineapple">Rwanda</SelectItem>
                  </SelectGroup>
                </SelectContent>
              </Select>
            </div>
          </div>
        </div>
        <div className="flex justify-center gap-4 px-3 md:justify-end md:px-6">
          <Button variant="filled" color="#0959FE">
            Save
          </Button>
          <Button variant="outline" color="#0959FE">
            Cancel
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddAdmin
