import { ForgotPasswordDTO } from '@entities/session/api/types'
import formSchema from '@features/auth/forgot-password/model/form'
import { AxiosResponse } from 'axios'

import SessionAPI from '@/entities/session/api'
import { AppLogo, Button, InputBox } from '@/shared/ui'
import { loaders } from '@/widgets'

const ForgotPasswordForm = loaders.WithFormLoader(
  SessionAPI.forgotPassword as (data: Partial<ForgotPasswordDTO>) => Promise<AxiosResponse>,
  ({ register, errors, isLoading }) => {
    return (
      <div>
        <div className="flex flex-col items-center justify-center w-full gap-2 mx-auto">
          <AppLogo className="mb-4" clickable />
          <h2 className="text-lg font-bold text-current-900">FORGOT PASSWORD?</h2>
          <p className="text-xs text-center md:text-sm md:pt-4">
            No worries, we’ll send you reset instructions.
          </p>
        </div>
        <div className="mt-3 mb-5">
          <InputBox
            label="Email Address"
            placeholder="Email Address"
            type="email"
            id="email"
            error={errors.email?.message}
            {...register('email')}
          />
        </div>
        <div className="flex items-center justify-between mt-6">
          <Button type="submit" className="w-full" size="sm" isLoading={isLoading}>
            Reset Password
          </Button>
        </div>
      </div>
    )
  },
  formSchema,
)

export default ForgotPasswordForm
