import Notifications from '@features/dashboard/admin/settings/ui/notifications'
import Password from '@features/dashboard/admin/settings/ui/password'
import Profile from '@features/dashboard/admin/settings/ui/profile'

import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs'

export function Settings() {
  return (
    <Tabs defaultValue="account">
      <div className="flex w-full border-b justify-between">
        <TabsList className="flex gap-2 md:gap-10 bg-white ">
          <TabsTrigger
            value="profile"
            className="font-semibold text-[8px] sm:text-[10px] md:text-base py-2 "
          >
            Profile
          </TabsTrigger>
          <TabsTrigger
            value="password"
            className="font-semibold text-[8px] sm:text-[10px] md:text-base py-2"
          >
            Password
          </TabsTrigger>
          <TabsTrigger
            value="notification"
            className="font-semibold text-[8px] sm:text-[10px] md:text-base py-2 "
          >
            Notification
          </TabsTrigger>
        </TabsList>
        <p className="font-semibold text-[12px] md:text-xl py-2">Account ID : 490941370732</p>
      </div>
      <TabsContent value="profile">
        <Profile />
      </TabsContent>
      <TabsContent value="password">
        <Password />
      </TabsContent>
      <TabsContent value="notification">
        <Notifications />
      </TabsContent>
    </Tabs>
  )
}
