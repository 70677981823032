import ProfileIcon from '@/assets/images/admin-dashboard/secure-profile.png'
import DashboardIcon from '@/assets/images/user-dashboard/dashboard.png'
import SettingsIcon from '@/assets/images/user-dashboard/settings.png'

const navItems = [
  {
    path: '/admin/dashboard',
    icon: DashboardIcon,
    label: 'Dashboard',
  },
  {
    path: '/admin/dashboard/users',
    icon: ProfileIcon,
    label: 'Users',
  },
  {
    path: '/admin/dashboard/admin',
    icon: ProfileIcon,
    label: 'Admin',
  },
  {
    path: '/admin/dashboard/settings',
    icon: SettingsIcon,
    label: 'Settings',
  },
]

export default navItems
