import { useState } from 'react'

import { IWorkHistory } from '@features/build-cv/assisted/model'
import { Button, Icon, InputBox } from '@shared/ui'
import { loaders } from '@widgets/index'

import List from './list'

const WorkHistoryStep = ({ register, errors }: loaders.IWithFormLoaderComponentProps) => {
  const [currentJob, setCurrentJob] = useState(false)

  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col w-full gap-4">
        <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
          <InputBox
            className="flex-1"
            label="Job Title"
            placeholder="Type Here..."
            type="text"
            id="job_title"
            error={errors.job_title?.message}
            {...register('job_title')}
          />
          <InputBox
            className="flex-1"
            label="Employer"
            placeholder="Type Here..."
            type="text"
            id="employer"
            error={errors.employer?.message}
            {...register('employer')}
          />
        </div>
        <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
          <InputBox
            className="flex-1"
            label="City"
            placeholder="Type Here..."
            type="text"
            id="city"
            error={errors.city?.message}
            {...register('city')}
          />
          <InputBox
            className="flex-1"
            label="Country"
            placeholder="Type Here..."
            type="text"
            id="country"
            error={errors.country?.message}
            {...register('country')}
          />
        </div>
        <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
          <InputBox
            className="flex-1"
            label="Start Date"
            placeholder="Type Here..."
            type="date"
            id="start_date"
            error={errors.start_date?.message}
            {...register('start_date')}
          />
          <InputBox
            className="flex-1"
            label="End Date"
            placeholder="Type Here..."
            type="date"
            id="end_date"
            disabled={currentJob}
            error={errors.end_date?.message}
            {...register('end_date')}
          />
        </div>
        <InputBox
          className="flex-row-reverse gap-2"
          label="I currently work here"
          type="checkbox"
          id="currently_working_here"
          css={{
            input:
              'max-w-5 h-5 focus:ring-none focus:outline-none rounded-md shadow-none focus-within:ring-0',
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setCurrentJob(e.target.checked)}
        />
        <InputBox
          spellCheck
          className="flex-1"
          label="Job Description"
          placeholder="Type Here..."
          type="textarea"
          rows={12}
          id="job_description"
          css={{
            input: 'resize-none rounded-md',
          }}
          error={errors.job_description?.message}
          {...register('job_description')}
        />
      </div>
      {/* Add more work experiences */}
      <Button size="sm" variant="outline" type="submit">
        <Icon icon="plus" className="mr-2" color="current-900" />
        Add employment
      </Button>
      <List
        dataKey="work_history"
        getSummary={(work) => {
          work = work as IWorkHistory
          return (
            <p className="text-sm text-gray-500">
              {work.job_title} at {work.employer} in{' '}
              {work.city && work.country
                ? `${work.city}, ${work.country}`
                : work.city
                  ? work.city
                  : work.country}
            </p>
          )
        }}
      ></List>
    </div>
  )
}

export default WorkHistoryStep
