import { Editor } from '@tinymce/tinymce-react'

import { loaders } from '@/widgets'

const SummaryStep = ({ register, errors }: loaders.IWithFormLoaderComponentProps) => {
  return (
    <div className="relative w-full">
      {typeof errors.prof_summary?.message === 'string' && (
        <p className="absolute z-20 text-sm text-red-500 transform -translate-x-1/2 bottom-1 left-1/2">
          {errors.prof_summary?.message}
        </p>
      )}
      <Editor
        apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
        init={{
          placeholder: 'Type Here...',
          auto_focus: true,
          plugins: [
            'link',
            'emoticons',
            'charmap',
            'code',
            'lists',
            'wordcount',
            'linkchecker',
            'autocorrect',
            'markdown',
          ],
          toolbar:
            'bold italic underline strikethrough | alignleft aligncenter alignright alignjustify | emoticons charmap | undo redo | outdent indent | numlist bullist | removeformat | code | link',
          height: '30rem',
          width: '100%',
          readonly: undefined,
          encoding: 'json',
        }}
        onEditorChange={(content) => {
          register('prof_summary').onChange({
            type: 'textarea',
            target: { name: 'summary', value: content },
          })
        }}
      />
    </div>
  )
}

export default SummaryStep
