export default [
  {
    id: 'toolbar-action-print',
    label: 'Print',
    icon: 'print',
  },
  {
    id: 'toolbar-action-download',
    label: 'Download',
    icon: 'cloud-arrow-down',
  },
  {
    id: 'toolbar-action-email',
    label: 'Email',
    icon: 'envelope',
  },
]
