import FCVReview from '@features/fastrack/cv-review'

export default function ReviewCV() {
  return (
    <div className="flex flex-col gap-6 my-8 min-h-screen">
      <div className="text-current-900">
        <h2 className="text-lg md:text-2xl font-semibold mb-1">Preview</h2>
        <p className="text-sm md:text-base lg:text-lg">
          Preview your cv and make any necessary modifications. Apply for jobs after downloading or
          emailing yourself a copy.
        </p>
      </div>
      <FCVReview />
    </div>
  )
}
