import { Navigate, useSearchParams } from 'react-router-dom'

import { ResetPasswordDTO } from '@entities/session/api/types'
import formSchema from '@features/auth/reset-password/model/form'
import { AxiosResponse } from 'axios'

import SessionAPI from '@/entities/session/api'
import { AppLogo, Button, InputBox } from '@/shared/ui'
import { loaders } from '@/widgets'

const ResetPasswordForm = loaders.WithFormLoader(
  SessionAPI.resetPassword as (data: Partial<ResetPasswordDTO>) => Promise<AxiosResponse>,
  ({ register, errors, isLoading }) => {
    const searchParams = useSearchParams()[0]
    const resetToken = searchParams.get('token')

    if (!resetToken) {
      return <Navigate to="/auth/login" replace />
    }
    return (
      <div>
        <div className="flex flex-col items-center justify-center w-full gap-2 mx-auto">
          <AppLogo className="mb-4" clickable />
          <h2 className="text-lg font-bold text-current-900">Reset Password?</h2>
          <p className="text-xs text-center md:text-sm md:pt-4">
            Your new password must be different to previously new password
          </p>
        </div>
        <div className="mt-3 mb-5">
          <InputBox type="hidden" value={resetToken} id="resetToken" {...register('resetToken')} />
          <InputBox
            label="New Password"
            placeholder="New Password"
            type="password"
            id="password"
            error={errors.password?.message}
            {...register('password')}
          />
          <InputBox
            label="Confirm Password"
            placeholder="Confirm Password"
            type="password"
            id="confirmPassword"
            error={errors.confirmPassword?.message}
            {...register('confirmPassword')}
          />
        </div>
        <div className="flex items-center justify-between mt-6">
          <Button type="submit" className="w-full" size="sm" isLoading={isLoading}>
            Reset Password
          </Button>
        </div>
      </div>
    )
  },
  formSchema,
)

export default ResetPasswordForm
