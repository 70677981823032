const PrivacyPolicy = () => {
  return (
    <div className="w-full h-full pt-12 bg-white md:pt-24">
      <div className="max-w-[1000px] mx-auto">
        <main className="text-xs font-normal md:text-base ">
          <h3 className="mb-6 text-base font-medium md:text-2xl md:mb-12">Privacy Policy</h3>
          <p className="mb-5 md:mb-10">
            At Revamp Insights, we are committed to protecting your privacy and ensuring the
            security of your personal data. This Privacy Policy outlines how we collect, use, store,
            and share your information when you use our platform. By accessing our platform, you
            recognize and accept the terms outlined in this policy. If you do not agree with our
            policies and practices, you may not use our platform.
          </p>
          <ol className="text-xs font-medium list-decimal md:text-lg">
            <li className="mb-5 md:mb-10">
              Information We Collect
              <p className="text-xs font-normal mb-1 md:text-base">
                We collect different types of information to provide and improve our services. This
                includes:
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">1.1 Personal Information</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                This is information that identifies you as an individual, which may include:
              </p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">Name</li>
                <li className="text-xs font-normal md:text-base">Email address </li>
                <li className="text-xs font-normal md:text-base">Phone number </li>
                <li className="text-xs font-normal md:text-base">Date of birth </li>
                <li className="text-xs font-normal md:text-base">
                  Job history, educational background, and CV details
                </li>
                <li className="text-xs font-normal md:text-base">
                  Payment details (for any paid services)
                </li>
              </ul>
              <p className="text-xs mb-1 font-normal md:text-base">1.2. Non-Personal Information</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                This is information that does not identify you as an individual, which may include:
              </p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">IP address</li>
                <li className="text-xs font-normal md:text-base">Browser type and version</li>
                <li className="text-xs font-normal md:text-base">Operating system</li>
                <li className="text-xs font-normal md:text-base">
                  Usage data (e.g., pages visited, time spent on the platform, referral URL)
                </li>
              </ul>
              <p className="text-xs mb-1 font-normal md:text-base">
                1.3. Information from Third Parties
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">
                We may collect additional information from third-party platforms if you log in via
                social media (e.g., LinkedIn or Facebook) or if third parties (e.g., recruitment
                agencies) provide data on your behalf.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              How We Use Your Information
              <p className="text-xs mb-1 font-normal md:text-base">
                We use your information for a variety of purposes, including but not limited to:
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">2.1 Providing Services</p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">
                  Facilitating your access to job listings, CV optimization, e-learning materials,
                  and other services offered on the platform.
                </li>
                <li className="text-xs font-normal md:text-base">
                  Personalizing your experience by showing content tailored to your preferences and
                  background.
                </li>
              </ul>
              <p className="text-xs mb-1 font-normal md:text-base">2.2. Communication</p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">
                  Sending you transactional emails, notifications, and updates regarding your
                  account and activities on the platform.
                </li>
                <li className="text-xs font-normal md:text-base">
                  Responding to your inquiries, feedback, and support requests.
                </li>
              </ul>
              <p className="text-xs mb-1 font-normal md:text-base">2.3. Marketing</p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">
                  Sending promotional materials and newsletters, provided you have consented to
                  receive them. You can opt out of these communications at any time.
                </li>
              </ul>
              <p className="text-xs mb-1 font-normal md:text-base">2.4. Improving Services</p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">
                  Analyzing usage data to improve the performance, security, and functionality of
                  the platform.
                </li>
                <li className="text-xs font-normal md:text-base">
                  Conducting research and analysis to understand trends and user behavior.
                </li>
              </ul>
              <p className="text-xs mb-1 font-normal md:text-base">2.5. Legal Compliance</p>
              <ul className="list-disc mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">
                  Complying with legal obligations, such as responding to lawful requests and
                  protecting the rights and safety of Revamp Insights, our users, and the public.
                </li>
              </ul>
            </li>
            <li className="mb-5 md:mb-10">
              How We Share Your Information
              <p className="text-xs font-normal mb-1 md:text-base">
                We do not sell or rent your personal information. However, we may share your
                information in the following circumstances:
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">3.1. With Your Consent</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                We may share your personal data with third parties if you have given us explicit
                consent to do so.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">3.2. Service Providers</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                We may share your information with third-party service providers who help us operate
                and maintain our platform, such as payment processors, email services, and data
                analytics providers. These service providers are bound by confidentiality agreements
                and may only use your information as instructed by Revamp Insights.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">3.3. Business Transfers</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                In the event of a merger, acquisition, or asset sale, your personal data may be
                transferred to a third party. We will notify you if your data is transferred and
                becomes subject to a different privacy policy.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">3.4. Legal Obligations</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                We may disclose your personal data if required to do so by law or in response to
                valid requests by public authorities (e.g., a court or government agency).
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">3.5. Protection of Rights</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                We may share your data when necessary to:
              </p>
              <ul className="list-disc  mb-2 ms-2 md:ms-4">
                <li className="text-xs font-normal md:text-base">Enforce our Terms of Use.</li>
                <li className="text-xs font-normal md:text-base">
                  Protect the rights, privacy, safety, or property of Revamp Insights, our users, or
                  the public.
                </li>
              </ul>
            </li>
            <li className="mb-5 md:mb-10">
              Data Retention
              <p className="text-xs mb-1 font-normal md:text-base">
                We will retain your personal data for as long as necessary to fulfill the purposes
                outlined in this policy, unless a longer retention period is required by law. If you
                close your account or request deletion, we will delete your data unless certain
                information is needed to comply with legal obligations or resolve disputes.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              Your Data Rights
              <p className="text-xs mb-1 font-normal md:text-base">
                Depending on your jurisdiction, you may have the following rights regarding your
                personal data:
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">5.1. Access</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                You can request access to the personal data we hold about you and ask us to correct,
                update, or delete it.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">5.2. Data Portability</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                You can request that we provide your personal data in a structured, commonly used,
                and machine-readable format, or transfer it to another service provider.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">5.3. Rectification</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                You can ask us to correct any inaccurate or incomplete personal data.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">5.4. Deletion</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                You can request that we delete your personal data under certain conditions, such as
                when it is no longer necessary for the purposes for which it was collected.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">5.5. Objection</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                You can object to the processing of your data for certain purposes, such as direct
                marketing or profiling.
              </p>
              <p className="text-xs mb-1 font-normal md:text-base">5.6. Withdraw Consent</p>
              <p className="text-xs mb-1 font-normal md:text-base">
                If we rely on your consent to process your data, you can withdraw that consent at
                any time. To exercise these rights, please contact us at support@revampinsights.com.
                We will respond to your request within a reasonable time frame, as required by law.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              Data Security
              <p className="text-xs mb-1 font-normal md:text-base">
                We are committed to protecting your personal data and have implemented appropriate
                technical and organizational measures to safeguard it against unauthorized access,
                disclosure, alteration, and destruction. However, no system is completely secure,
                and we cannot guarantee the absolute security of your data.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              Cookies and Tracking Technologies
              <p className="text-xs mb-1 font-normal md:text-base">
                We use cookies and similar tracking technologies to collect and store certain
                information about your use of our platform. These technologies help us personalize
                your experience, analyze site traffic, and understand user behaviour. You can
                control cookies through your browser settings, but please note that disabling
                cookies may affect your ability to use some features of the platform.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              International Data TransfersInternational Data Transfers
              <p className="text-xs mb-1 font-normal md:text-base">
                Your information may be transferred to and processed in countries other than the one
                in which you reside. These countries may have data protection laws that differ from
                those of your jurisdiction. By using our platform, you consent to the transfer of
                your data to other countries, including those that may not offer the same level of
                data protection.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              Children’s Privacy
              <p className="text-xs mb-1 font-normal md:text-base">
                Revamp Insights is not intended for children under the age of 18. We do not
                knowingly collect personal data from anyone under 18. If you believe we have
                collected information from a minor, please contact us so we can take appropriate
                action.
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              Changes to this Data Policy
              <p className="text-xs mb-1 font-normal md:text-base">
                We may update this Data Policy from time to time to reflect changes in our practices
                or legal requirements. We will notify you of any significant changes by posting the
                updated policy on the platform. Your continued use of the platform after such
                changes will constitute your acknowledgment and acceptance of the updated policy.{' '}
              </p>
            </li>
            <li className="mb-5 md:mb-10">
              Contact Us
              <p className="text-xs mb-1 font-normal md:text-base">
                If you have any questions or concerns about this Data Policy, please contact us at:{' '}
                <br /> support@revampinsights.com.
              </p>
            </li>
          </ol>
        </main>
      </div>
    </div>
  )
}

export default PrivacyPolicy
