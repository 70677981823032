import ROUTEMAP from '@shared/routemap.json'
import { Icon } from '@shared/ui'

const DashboardFooter: React.FC = () => {
  const socialLinks = ['linkedin-in', 'twitter', 'facebook-f', 'instagram']

  return (
    <footer className="flex flex-col lg:flex-row gap-4 md:gap-2 text-center justify-between items-center text-sm py-5 border-t border-[#00071433] mt-4 md:mt-20 ">
      <p>© Revampinsights.All Right Reserved | support@revampinsights.com</p>
      <p>
        <a className="text-sm" href={ROUTEMAP.base.ABOUT}>
          About Us
        </a>{' '}
        |{' '}
        <a className="text-sm" href={ROUTEMAP.base.CONTACT}>
          Contact Us
        </a>{' '}
        |{' '}
        <a className="text-sm" href="/">
          Terms & condition
        </a>{' '}
        |{' '}
        <a className="text-sm" href="/">
          Privacy Policy
        </a>
      </p>
      <div className="flex items-center gap-2">
        {socialLinks.map((sl) => (
          <span
            className=" bg-[#0007144A] flex items-center justify-center w-8 h-8 p-4 rounded-full "
            key={`social-link-${sl}`}
          >
            <Icon icon={sl} style="brands" color="white" />
          </span>
        ))}
      </div>
    </footer>
  )
}

export default DashboardFooter
