import { Instance, types } from 'mobx-state-tree'

const Duration = types.model('Duration', {
  from: types.string,
  to: types.maybe(types.string),
  isCurrent: types.boolean,
})

export type IDuration = Instance<typeof Duration>
export default Duration
