import CVTemplateScreenshot from '@/assets/images/base/cv-template.svg'

export default function WinningCVSection() {
  return (
    <div className="bg-white py-16">
      <section className="max-w-[900px] mx-auto px-5 flex flex-col-reverse md:flex-row items-center justify-between gap-5">
        <div className="w-full md:w-1/2 relative my-10 px-2 md:px-0 py-5 md:my-0 flex items-center justify-center md:justify-start">
          <img
            src={CVTemplateScreenshot}
            alt=""
            className="w-10/12 md:w-[400px] h-full max-h-[400px] object-contain"
          />
          <img
            src={CVTemplateScreenshot}
            alt=""
            className="w-10/12 md:w-[400px] h-full max-h-[400px] object-contain absolute z-10 transform transition-transform -rotate-12"
          />
        </div>
        <div className="w-full md:w-1/2">
          <h3 className="text-4xl font-semibold text-center md:text-start space-y-8 md:space-y-0">
            Build your job &mdash; winning <br />
            <span className="text-primary-900">CV</span> in minutes
          </h3>
          <div className="flex flex-col gap-10 md:gap-5 mt-10 md:mt-5">
            <article className="flex flex-col gap-2 text-center md:text-start">
              <h4 className="text-xl font-medium">Easy to use</h4>
              <p className="px-10 md:px-0">
                Array of web based applications with features that allow user to share and download
                created CVs.
              </p>
            </article>
            <article className="flex flex-col gap-2 shadow-lg shadow-current-200 rounded-md p-3 text-center md:text-start">
              <h4 className="text-xl font-semibold">Access to templates</h4>
              <p>
                A vast CV template directory that provides exceptional models to choose from and
                create outstanding CV in minute.
              </p>
            </article>
            <article className="flex flex-col gap-2 text-center md:text-start">
              <h4 className="text-xl font-medium">Share and download</h4>
              <p className="px-10 md:px-0">
                Set of web-based applications that implements features which allow its users easily,
                and with less human intervention, create CVs for different job roles and industries
              </p>
            </article>
          </div>
        </div>
      </section>
    </div>
  )
}
