import { z } from 'zod'

const formSchema = z.object({
  email: z
    .string()
    .min(1, { message: 'Email is required' })
    .email({ message: 'Must be a valid email' }),
})

export type IFormSchema = z.infer<typeof formSchema>
export default formSchema
