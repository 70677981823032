import { useState } from 'react'
import { Tooltip } from 'react-tooltip'

import { Textify, templater } from '@shared/lib'
import { Snapshots } from '@shared/model'
import clsx from 'clsx'

type Props = {
  onSelectTemplate: (template: string) => void
}

const snapshots = Snapshots.create({
  key: 'cv-templates',
  items: Object.entries(templater.getAllCVTemplates()).map(([key, value]) => ({
    id: key,
    url: value,
  })),
})

const TemplatesSelector = ({ onSelectTemplate }: Props) => {
  const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null)

  const isSelected = (template: string) => selectedTemplate === template
  const handleSelect = (template: string) => {
    if (isSelected(template)) {
      setSelectedTemplate(null)
    } else {
      setSelectedTemplate(template)
    }

    // publish event to parent component
    onSelectTemplate(template)
  }

  return (
    <div
      className={clsx(
        'h-full max-h-[32rem] lg:max-h-[40rem] overflow-x-hidden overflow-y-auto gap-2 xs:p-3 p-6 md:p-3 flex flex-wrap sm:grid grid-cols-3 sm:grid-cols-5 md:grid-cols-4',
        `sm:grid-rows-${Math.ceil(snapshots.all.length / 3)}`,
        `lg:grid-rows-${Math.ceil(snapshots.all.length / 4)}`,
      )}
    >
      {snapshots.all.map(({ id, url }) => {
        return (
          <div
            key={Textify.generateRandomString(7) + `-${id}`}
            onClick={() => handleSelect(id)}
            onKeyDown={(e) => e.key === 'Enter' && handleSelect(id)}
            role="button"
            tabIndex={0}
            data-tooltip-id={'tooltip-template-' + id}
            data-tooltip-content={(isSelected(id) ? 'Unselect' : 'Select') + ' template'}
            className="relative w-full max-w-32 h-full max-h-42 cursor-pointer transition-colors duration-300 ease-in-out"
          >
            <img
              src={url}
              alt={`Revamp Insights Fastrack ` + Textify.toSpacedWords(id)}
              className={clsx('w-full h-full', {
                'border border-primary-500 bg-primary-500 opacity-60': isSelected(id),
              })}
            />
            <Tooltip className="z-20" place="top-start" id={'tooltip-template-' + id} />
          </div>
        )
      })}
    </div>
  )
}

export default TemplatesSelector
