import { Link } from 'react-router-dom'

import { Textify } from '@shared/lib'
import ROUTEMAP from '@shared/routemap.json'
import clsx from 'clsx'

const buildOptions = [
  {
    title: 'Fast track your CV',
    description:
      "We'll help you create a new CV from start to finish, by just uploading your old CV and get a new one in 30 seconds",
    path: Textify.constructURL(ROUTEMAP.cv['FAST-TRACK']),
    disabled: false,
  },
  {
    title: 'Help me create a new CV',
    description:
      "We'll help you create a new CV from start to finish, by submitting an existing cv and getting a completely new one in the next 24 hours.",
    path: Textify.constructURL(ROUTEMAP.cv.build.ASSISTED),
    disabled: false,
  },
  {
    title: 'Create CV from scratch',
    description: "We'll help you build a brand new CV in 5 minutes from start to finish",
    path: Textify.constructURL(ROUTEMAP.cv.build.SCRATCH),
    disabled: true,
  },
]

export default function ChooseBuildOption() {
  return (
    <main className="flex flex-col gap-5 max-w-[700px] mx-auto py-8">
      <h3 className="text-2xl md:text-3xl text-center">
        How would you like to
        <br />
        build <span className="text-primary-900 font-medium">your CV</span>?
      </h3>
      <div className="py-4 flex flex-col gap-3">
        {buildOptions.map((option) => (
          <Link
            key={option.title}
            to={option.path}
            aria-disabled={option.disabled}
            draggable
            title={option.disabled ? 'Coming soon' : option.title}
            className={clsx({
              'opacity-50 cursor-not-allowed pointer-events-none': option.disabled,
            })}
          >
            <div
              className={clsx(
                'flex flex-col items-center text-center md:text-start md:grid md:grid-cols-6 gap-5 md:gap-2 p-4 border-2 border-primary-900 rounded-md transition-colors group',
                {
                  'hover:bg-primary-900 hover:text-white': !option.disabled,
                },
              )}
            >
              <img
                src="/icons/resume-skeleton.svg"
                alt="Resume Skeleton"
                className="max-w-20 max-h-24 group-hover:scale-90 group-hover:rounded-full group-hover:bg-white"
              />
              <div className="flex flex-col gap-1 md:col-span-5">
                <h4 className="text-lg font-semibold">{option.title}</h4>
                <p className="text-sm">{option.description}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>
    </main>
  )
}
