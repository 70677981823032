import { Button } from '@/components/ui/button'
import { Switch } from '@/components/ui/switch'

const Notifications = () => {
  return (
    <div>
      <div className="md:border-b flex justify-between items-center border-[#00071433] py-2 md:py-5">
        <div>
          <p className="text-[12px] md:text-lg font-semibold ">Notification</p>
          <p className="text-[12px] md:text-base font-normal ">Update your password here</p>
        </div>
        <div className="md:flex gap-4 hidden">
          <Button variant="outline" color="#0959FE">
            Cancel
          </Button>
          <Button variant="filled" color="#0959FE">
            Save
          </Button>
        </div>
      </div>
      <div className="flex items-center justify-between mt-4">
        <div>
          <label htmlFor="airplane-mode" className="text-sm font-bold">
            Notification Language
          </label>
          <p className="text-sm font-normal">Email push notification language settings.</p>
        </div>
        <Switch id="airplane-mode" />
      </div>
      <div className="flex items-center justify-between mt-4">
        <div>
          <label htmlFor="airplane-mode" className="text-sm font-bold">
            On-site Notifications
          </label>
          <p className="text-sm font-normal">
            Once enabled, you will receive relevant notifications within the website.
          </p>
        </div>
        <Switch id="airplane-mode" />
      </div>
      <div className="flex items-center justify-between mt-4">
        <div>
          <label htmlFor="airplane-mode" className="text-sm font-bold">
            Marketing Emails
          </label>
          <p className="text-sm font-normal">
            Select whether you want to receive marketing emails from us.
          </p>
        </div>
        <Switch id="airplane-mode" />
      </div>

      <div className="flex gap-5 my-10 md:hidden">
        <Button variant="outline" color="#0959FE" className="w-1/2">
          Cancel
        </Button>
        <Button variant="filled" color="#0959FE" className="w-1/2">
          Save
        </Button>
      </div>
    </div>
  )
}

export default Notifications
