import AuthOptions from '@entities/oauth/ui/auth-options'
import { useAuthenComplete } from '@features/auth/lib/hooks'
import SignupForm from '@features/auth/signup/ui/form'

export default function FSignup() {
  const onComplete = useAuthenComplete()

  return (
    <>
      <SignupForm onComplete={onComplete} />
      {/* Other signup options */}
      <AuthOptions />
    </>
  )
}
