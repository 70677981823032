import { SignupUserDTO } from '@entities/session/api/types'
import formSchema from '@features/auth/signup/model/form'
import { AxiosResponse } from 'axios'

import SessionAPI from '@/entities/session/api'
import { AppLogo, Button, InputBox } from '@/shared/ui'
import { loaders } from '@/widgets'

const SignupForm = loaders.WithFormLoader(
  SessionAPI.signup as (data: Partial<SignupUserDTO>) => Promise<AxiosResponse>,
  ({ register, errors, isLoading }) => {
    return (
      <div>
        <div className="flex items-center justify-center w-full gap-2 mx-auto">
          <h2 className="self-start text-lg font-bold text-current-900">Sign Up</h2>
          <AppLogo className="mb-4" clickable />
        </div>
        <div className="space-y-4">
          <InputBox
            label="Full Name"
            placeholder="Full Name"
            type="text"
            id="fullName"
            error={errors.fullName?.message}
            {...register('fullName')}
          />
          <InputBox
            label="Email Address"
            placeholder="Email Address"
            type="email"
            id="email"
            error={errors.email?.message}
            {...register('email')}
          />
          <InputBox
            label="Password"
            placeholder="Password"
            type="password"
            id="password"
            error={errors.password?.message}
            {...register('password')}
          />
          <InputBox
            label="Confirm Password"
            placeholder="Confirm Password"
            type="password"
            id="confirmPassword"
            error={errors.confirmPassword?.message}
            {...register('confirmPassword')}
          />
        </div>
        <div className="flex items-center justify-between mt-6">
          <Button type="submit" className="w-full" size="sm" isLoading={isLoading}>
            Sign Up
          </Button>
        </div>
      </div>
    )
  },
  formSchema,
)

export default SignupForm
