import { Instance, types } from 'mobx-state-tree'

const SocialLinks = types.model('SocialLinks', {
  github: types.maybe(types.string),
  linkedin: types.maybe(types.string),
  twitter: types.maybe(types.string),
  website: types.maybe(types.string),
})

export type ISocialLinks = Instance<typeof SocialLinks>
export default SocialLinks
