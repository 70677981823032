import { RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import sessionSlice from '@entities/session/model/slice'
import clsx from 'clsx'
import { getCookie } from 'typescript-cookie'

import routes from '@/pages'

// set token
const token = getCookie('ri-private_token')
if (token) {
  sessionSlice.setToken(token)
}

const router = createBrowserRouter(routes)
const App = () => {
  return (
    <>
      <RouterProvider router={router} />
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        newestOnTop
        draggable
        pauseOnHover
        closeOnClick
        theme="light"
        transition={Slide}
        limit={7}
        className={(context) => clsx(context?.defaultClassName, 'text-sm md:text-lg md:font-light')}
        bodyClassName="text-current-500"
      />
    </>
  )
}

export default App
