import { useState } from 'react'

import { Icon } from '@shared/ui'
import { SoleDropdownItem } from '@widgets/types'
import clsx from 'clsx'

type Props = {
  selected: SoleDropdownItem | SoleDropdownItem[]
  onSelect: (item: SoleDropdownItem) => Promise<void>
  item: SoleDropdownItem
  isMulti?: boolean
}

const SoleDropdownOption = ({ selected, onSelect, item, isMulti }: Props) => {
  const [isLoading, setIsLoading] = useState(false)

  const isSelected = Array.isArray(selected)
    ? selected.some((i) => {
        if (typeof i === 'string' || typeof item === 'string') {
          return i === item
        }

        return i.value === item.value
      })
    : typeof selected === 'string' || typeof item === 'string'
      ? selected === item
      : selected.value === item.value

  const onClick = async (item: SoleDropdownItem) => {
    setIsLoading(true)
    await onSelect(item)
    setIsLoading(false)
  }

  return (
    <div
      className="flex items-center gap-1 p-2"
      onClick={() => onClick(item)}
      onKeyDown={(e) => e.key === 'Enter' && onClick(item)}
      role="button"
      tabIndex={0}
    >
      {isMulti && (
        <span className="flex items-center justify-center w-3 h-3 p-1 border border-current-200 bg-white rounded-sm">
          {isLoading ? (
            <Icon icon="spinner" animation="animate-spin" />
          ) : (
            isSelected && <Icon icon="check" color="primary-700" className="text-[0.6rem]" />
          )}
        </span>
      )}
      <span
        className={clsx('flex-1 truncate text-xs text-current-700', {
          'font-medium text-current-900': isSelected,
        })}
      >
        {typeof item === 'string' ? item : item.label}
      </span>
    </div>
  )
}

export default SoleDropdownOption
