import Icon from '@shared/ui/icon'
import clsx from 'clsx'

type Props = {
  count: number
  is_active?: boolean
  className?: string
  size?: 'sm'
  icon?: React.ReactNode | string
}

export default function CountLabelHorizontal({
  count,
  is_active = false,
  className,
  icon,
  size = 'sm',
}: Props) {
  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-full md:p-4 p-[10px] text-center  font-semibold  border',
        {
          'text-[8px] w-[16px] h-[16px] md:text-lg md:w-8 md:h-8 lg:text-2xl lg:w-10 lg:h-10':
            size === 'sm',
          'bg-primary-900 text-white': is_active,
        },
        className,
      )}
    >
      {icon ? (
        typeof icon === 'string' ? (
          <Icon icon={icon} size="sm" />
        ) : (
          icon
        )
      ) : (
        count.toLocaleString().padStart(2, '0')
      )}
    </div>
  )
}
