import { useParams } from 'react-router-dom'

import { AppLogo, Button } from '@shared/ui'

import GMailLogo from '@/assets/images/base/gmail.svg'

const CheckYourEmail = () => {
  const { userEmail } = useParams()
  const sendEmail = () => {
    window.location.href = `mailto:${userEmail}`
  }

  return (
    <div>
      <div className="flex flex-col items-center justify-center w-full gap-2 mx-auto">
        <AppLogo className="mb-2 md:mt-4" clickable />
        <img src={GMailLogo} alt="Gmail logo" />
        <h2 className="text-lg font-bold text-current-900">Check your email</h2>
        <p className="text-xs font-medium text-center md:text-sm md:pt-3">
          We sent a password reset link to
        </p>
        <p className="text-xs font-medium text-center md:text-sm">{userEmail}</p>
      </div>
      <div className="flex items-center justify-between mt-8 md:mt-14">
        <Button onClick={sendEmail} type="submit" className="w-full" size="sm">
          Check your mail
        </Button>
      </div>
      <div className="flex items-center justify-center">
        <p className="mt-3 text-xs text-center mb-7 md:text-sm md:pt-4 ">
          Didn’t receive the email?
        </p>
        <p className="mt-3 text-xs text-center mb-7 md:text-sm md:pt-4 text-[#0062E0]">
          Click to resend
        </p>
      </div>
    </div>
  )
}

export default CheckYourEmail
