import { Button, Icon } from '@shared/ui'

const AuthOptions = () => {
  return (
    <div className="mt-5">
      <div className="flex items-center justify-center">
        <hr className="w-1/3 bg-secondary-200" />
        <span className="mx-2 text-sm text-secondary-600">OR</span>
        <hr className="w-1/3 bg-secondary-200" />
      </div>
      <div className="flex flex-col justify-center gap-2 mt-2">
        <Button type="button" size="sm" variant="primary">
          <Icon icon="facebook-f" style="brands" size="lg" />
          <span className="mx-auto">Continue with Facebook</span>
        </Button>
        <Button type="button" size="sm" variant="outline">
          <Icon icon="google" style="brands" size="lg" color="current-900" />
          <span className="mx-auto">Continue with Google</span>
        </Button>
      </div>
    </div>
  )
}

export default AuthOptions
