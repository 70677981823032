import { useCallback, useState } from 'react'
import { FieldErrors, FieldValues, UseFormRegister, useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import { zodResolver } from '@hookform/resolvers/zod'
import { submit } from '@shared/lib'
import { APIResponseData } from '@shared/types'
import { AxiosResponse } from 'axios'
import { ZodObject, ZodRawShape, z } from 'zod'

type IFormSchema = z.infer<ZodObject<ZodRawShape>>
type IPartialFormSchema = Partial<IFormSchema>

type Props = {
  register: UseFormRegister<FieldValues>
  errors: FieldErrors<FieldValues>
  isLoading?: boolean
}

const WithFormLoader = (
  action: (data: IPartialFormSchema) => Promise<AxiosResponse>,
  component: React.ComponentType<Props>,
  formSchema: ZodObject<ZodRawShape>,
) => {
  return function Wrapper({
    onComplete,
    extend,
    children,
  }: {
    onComplete: (data: APIResponseData) => unknown
    extend?: (Component: React.ComponentType<Props>) => React.ComponentType<Props>
    children?: React.ReactNode
  }) {
    const {
      register,
      setError,
      formState: { errors },
      handleSubmit,
    } = useForm<IFormSchema>({
      resolver: zodResolver(formSchema),
    })
    const [isLoading, setIsLoading] = useState(false)

    const handleError = useCallback((message: string, formSchema: ZodObject<ZodRawShape>) => {
      const keys = Object.keys(formSchema.shape)
      for (const key of keys) {
        if (message.toLocaleLowerCase().includes(key.toLocaleLowerCase())) {
          setError(key, { message, type: 'validate' })
          return true
        }
      }
      return false
    }, [])

    const onSubmit = async <T extends IPartialFormSchema>(data: T) => {
      setIsLoading(true)

      const result = await submit<APIResponseData>(action(data), onComplete)

      if (typeof result === 'string') {
        const success = handleError(result, formSchema)

        if (!success) {
          toast.error(result)
        }
      }

      setIsLoading(false)
    }

    // extend the component output jsx if specified
    const Component = extend ? extend(component) : component

    return (
      <form onSubmit={handleSubmit(onSubmit)} className="flex-1">
        <Component register={register} errors={errors} isLoading={isLoading} />
        {children}
      </form>
    )
  }
}

export type IWithFormLoaderComponentProps = Props
export default WithFormLoader
