import { DetailedStep } from '@shared/types'
import { DetailedSteps } from '@shared/ui'

import CVTemplateScreenshot from '@/assets/images/base/cv-template.svg'

const steps: DetailedStep[] = [
  {
    title: 'Create CV',
    description: 'Create industry standard CV with your key skills, experience etc.',
  },
  {
    title: 'Add a Subscription Plan',
    description: 'Choose a subscription plan and pay using your debit card or bank transfer.',
  },
  {
    title: 'Get Hired Fast',
    description: 'Submit your CV and get hired fast by top firms.',
  },
]

export default function CVBuildingSection() {
  return (
    <div className="py-5 md:py-10">
      <div className="flex flex-col text-center items-center gap-4 md:gap-6 w-full max-w-[900px] mx-auto">
        <h3 className="text-2xl md:text-4xl font-semibold">
          Start building your <br />
          <span className="text-primary-900">CV</span> in 5 minutes
        </h3>
        <div className="flex flex-col md:flex-row md:justify-between items-center gap-5 md:gap-3 px-2 md:px-3">
          <div className="flex flex-col gap-10 md:gap-5 text-start">
            <DetailedSteps
              steps={steps}
              className="items-center md:items-start gap-6 md:gap-0 py-5 md:py-0"
              css={{
                stepBox: 'flex-col md:flex-row text-center md:text-start',
                step: 'md:self-baseline md:pt-2 md:pb-5',
                stepTitle: 'text-lg md:text-xl font-medium',
                stepDescription: 'text-xs md:text-sm md:w-2/5',
              }}
            />
          </div>
          <div>
            <img
              src={CVTemplateScreenshot}
              alt="CV Template"
              className="w-full md:w-[400px] h-full max-h-[400px] object-contain"
            />
          </div>
        </div>
      </div>
    </div>
  )
}
