import { useNavigate } from 'react-router-dom'

import ResumeForm from '@features/fastrack/resume-form/ui/form'
import ROUTEMAP from '@shared/routemap.json'
import { APIResponseData } from '@shared/types'
import clsx from 'clsx'

type Props = {
  className?: string
}

export default function FResumeForm({ className }: Props) {
  const navigate = useNavigate()

  const onComplete = (data: APIResponseData) => {
    navigate(ROUTEMAP.cv['fast-track'].PREVIEW, { state: { cv_id: data.id } })
  }

  return (
    <div className={clsx('flex flex-col items-center justify-center h-full px-2', className)}>
      <ResumeForm onComplete={onComplete} />
    </div>
  )
}
