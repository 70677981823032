import { CVTemplateProps } from '@widgets/types'

type TemplatesImport = Record<string, React.FC<CVTemplateProps>>

class Templater {
  private templates: TemplatesImport
  private static instance: Templater

  private constructor() {
    this.templates = import.meta.glob('@widgets/templates/*.tsx', {
      import: 'default',
      eager: true,
    }) as TemplatesImport
  }

  public static instantiate() {
    if (!this.instance) {
      this.instance = new Templater()
    }

    return this.instance
  }

  public getCVTemplate(template: string) {
    if (template !== 'default' && !/template-\d/.test(template)) {
      return false
    }

    for (const key in this.templates) {
      if (key.includes(`${template}.tsx`)) {
        return this.templates[key]
      }
    }

    return false
  }

  public getAllCVTemplates() {
    const templates: Record<string, string> = {}

    const imports = import.meta.glob('@widgets/templates/assets/template-*.svg', {
      import: 'default',
      eager: true,
    })

    for (const key in imports) {
      const name = key.split('/').pop()?.split('.').shift()
      if (name && /template-\d/.test(name)) {
        templates[name] = imports[key] as string
      }
    }

    return templates
  }
}

export default Templater.instantiate()
