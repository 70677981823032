import sessionSlice from '@entities/session/model/slice'
import { templater } from '@shared/lib'
import { IUserCV } from '@shared/model/usercv'
import { Icon } from '@shared/ui'
import clsx from 'clsx'

import CVToolbar from './toolbar'

type Props = {
  template?: string
  className?: string
  showToolbar?: boolean
}

const CVPreview = ({ className, template = 'default', showToolbar = false }: Props) => {
  const CVTemplate = templater.getCVTemplate(template)

  return (
    <div className={clsx('border border-secondary-200 rounded-md w-full grow min-h-80', className)}>
      {showToolbar && (
        <div className="px-2">
          <CVToolbar className="border-b container-snap overflow-x-auto" />
        </div>
      )}
      <div
        className={clsx('px-3 my-3 md:max-h-[40rem] md:overflow-y-auto md:mx-auto relative', {
          'pointer-events-none': sessionSlice.isLoading,
        })}
      >
        {!sessionSlice.user?.cv && sessionSlice.isLoading && (
          <div className="absolute top-0 right-0 left-0 bottom-0 bg-current-200 text-white flex items-center justify-center">
            <Icon icon="spinner" animation="spin-pulse" size="lg" />
          </div>
        )}
        {/* Render CV in template inside an iframe */}
        {CVTemplate ? (
          <CVTemplate
            className="w-full h-full rounded-sm md:border md:border-secondary-200"
            cv={sessionSlice.user?.cv as IUserCV}
          />
        ) : (
          <div className="flex items-center justify-center h-64">
            <p className="text-sm text-secondary-400">No CV to display</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default CVPreview
