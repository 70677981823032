import { forwardRef } from 'react'

import clsx from 'clsx'

type Props = {
  showLabel?: boolean
  id?: string
  label?: string
  placeholder?: string
  name?: string
  value?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
  type?: string
  className?: string
  css?: {
    input?: string
    label?: string
  }
  disabled?: boolean
  required?: boolean
  error?: string
  row?: number
  [key: string]: unknown
}

const InputBox = forwardRef<HTMLInputElement, Props>(
  (
    {
      showLabel = true,
      label,
      id,
      placeholder,
      name,
      value,
      onChange,
      type = 'text',
      className,
      css,
      disabled,
      required,
      error,
      row,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        ref={ref}
        className={clsx(
          'flex',
          {
            'flex-row': ['checkbox', 'radio'].includes(type),
            'flex-col': !['checkbox', 'radio'].includes(type),
          },
          className,
        )}
      >
        {showLabel && (
          <label htmlFor={id} className={clsx('text-sm text-gray-400', css?.label)}>
            {label}
          </label>
        )}
        {type === 'textarea' ? (
          <textarea
            id={id}
            placeholder={placeholder}
            name={name}
            value={value}
            rows={row}
            onChange={onChange}
            className={clsx(
              'p-2 w-full rounded-sm border border-secondary-200 shadow-md focus:outline-none text-xs focus-within:ring-1 focus-within:ring-primary-500',
              { 'cursor-not-allowed': disabled, 'border-red-500': error },
              css?.input,
            )}
            disabled={disabled}
            required={required}
            {...rest}
          />
        ) : (
          <input
            id={id}
            type={type}
            placeholder={placeholder}
            name={name}
            value={value}
            onChange={onChange}
            className={clsx(
              'p-2 w-full rounded-sm border border-secondary-200 shadow-md focus:outline-none text-xs focus-within:ring-1 focus-within:ring-primary-500',
              { 'cursor-not-allowed': disabled, 'border-red-500': error },
              css?.input,
            )}
            disabled={disabled}
            required={required}
            {...rest}
          />
        )}
        {error && <p className="mt-2 text-xs text-red-600">{error}</p>}
      </div>
    )
  },
)

// add a display name for the component
InputBox.displayName = 'InputBox'

export type IInputBoxProps = Props
export default InputBox
