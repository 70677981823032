import React, { useState } from 'react'

import Billing from '@features/dashboard/user/settings/ui/billing'
import ChangePasswordForm from '@features/dashboard/user/settings/ui/change-password'
import Payment from '@features/dashboard/user/settings/ui/payment'
import PersonalDetailsForm from '@features/dashboard/user/settings/ui/personal-details'
import PlansAndPricing from '@features/dashboard/user/settings/ui/plans-and-pricing'

type Tab = {
  label: string
  key: string
  content: React.ReactNode
}

function Tabs({ tabs, defaultSelectedTab }: { tabs: Tab[]; defaultSelectedTab: Tab }) {
  const [selectedTab, selectTab] = useState(defaultSelectedTab)
  return (
    <div>
      <div className="flex justify-between items-center w-100 border-b border-[#00071433]">
        <div className=" flex gap-2 md:gap-10">
          {tabs.map((tab) => (
            <button
              key={tab.key}
              onClick={() => selectTab(tab)}
              className={
                selectedTab === tab
                  ? 'font-semibold text-[8px] sm:text-[10px] md:text-base py-2 border-b-2 border-[#0959FE]'
                  : 'font-semibold text-[8px] sm:text-[10px] md:text-base py-2'
              }
            >
              {tab.label}
            </button>
          ))}
        </div>
        <p className="font-semibold text-[12px] md:text-xl py-2">Account ID : 490941370732</p>
      </div>
      {selectedTab.content}
    </div>
  )
}
const tabs: Tab[] = [
  {
    label: 'Personal Details',
    key: 'personal-details',
    content: <PersonalDetailsForm />,
  },
  {
    label: 'Change Password',
    key: 'change-password',
    content: <ChangePasswordForm />,
  },
  {
    label: 'Plans & Pricing',
    key: 'plans-and-pricing',
    content: <PlansAndPricing />,
  },
  {
    label: 'Payment',
    key: 'payment',
    content: <Payment />,
  },
  {
    label: 'Billing',
    key: 'billing',
    content: <Billing />,
  },
]
const Settings = () => {
  return (
    <div>
      <Tabs tabs={tabs} defaultSelectedTab={tabs[0]} />
    </div>
  )
}

export default Settings
