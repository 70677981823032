import { Button, Icon, InputBox } from '@shared/ui'

import { loaders } from '@/widgets'

import List from './list'

const SkillsStep = ({ register, errors }: loaders.IWithFormLoaderComponentProps) => {
  return (
    <div className="flex flex-col w-full gap-4">
      <div className="flex flex-col flex-1 gap-5 md:flex-row md:items-center">
        <InputBox
          className="flex-1"
          label="Skill"
          placeholder="Type Here..."
          type="text"
          id="skill"
          error={errors.skill?.message}
          {...register('skill')}
        />
      </div>
      <Button size="sm" variant="outline" className="w-fit" type="submit">
        <Icon icon="plus" className="mr-2" color="current-900" />
        Add skill
      </Button>
      <List
        dataKey="skills"
        getSummary={(item) => <p className="text-sm text-gray-500">{item as string}</p>}
      />
    </div>
  )
}

export default SkillsStep
