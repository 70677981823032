const PLANS: Plan[] = [
  {
    title: 'Starter',
    price: 'Free',
    features: [
      {
        isSupported: true,
        name: '90 days access',
      },
      {
        isSupported: true,
        name: '3 CV templates',
      },
      {
        isSupported: false,
        name: 'Unlimited customization',
      },
      {
        isSupported: false,
        name: 'Download as PDF',
      },
      {
        isSupported: false,
        name: 'Download as Docx',
      },
    ],
  },
  {
    title: 'Classic',
    price: 15,
    features: [
      {
        isSupported: true,
        name: 'Unlimited access',
      },
      {
        isSupported: true,
        name: '20 CV templates',
      },
      {
        isSupported: false,
        name: 'Unlimited customization',
      },
      {
        isSupported: true,
        name: 'Download as PDF',
      },
      {
        isSupported: false,
        name: 'Download as Docx',
      },
    ],
  },
  {
    title: 'Premium',
    price: 50,
    features: [
      {
        isSupported: true,
        name: 'Unlimited access',
      },
      {
        isSupported: true,
        name: 'Unlimited CV templates',
      },
      {
        isSupported: true,
        name: 'Unlimited customization',
      },
      {
        isSupported: true,
        name: 'Download as PDF',
      },
      {
        isSupported: true,
        name: 'Download as Docx',
      },
    ],
  },
]

export type Plan = {
  title: string
  price: string | number
  features: {
    isSupported: boolean
    name: string
  }[]
}
export default PLANS
