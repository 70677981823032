import { useState } from 'react'

import { Textify } from '@shared/lib'
import clsx from 'clsx'

type Props = {
  className: (rank: number, index: number) => string
  css?: {
    item?: string
    rankbar?: string
    list?: string
  }
  items: string[] | Record<string, string>[]
  key?: string
  name?: string
}

const RankedList = ({
  className,
  css,
  items,
  key,
  name = Textify.generateRandomString(7),
}: Props) => {
  const [ranks, setRanks] = useState<Record<string, number>>(
    items.reduce((acc: Record<string, number>, item) => {
      if (typeof item === 'string') {
        acc[item] = Math.floor(Math.random() * 7) + 1
      } else if (key) {
        acc[item[key]] = Math.floor(Math.random() * 7) + 1
      }

      return acc
    }, {}),
  )

  const updateRank = (id: string, rank: number) => {
    setRanks((prev) => ({
      ...prev,
      [id]: rank,
    }))
  }

  return (
    <ul className={clsx('flex flex-col gap-1', css?.list)}>
      {Object.entries(ranks).map(([id, rank]) => (
        <li
          key={id + `-${name}`}
          className={clsx('flex gap-1 justify-between items-center', css?.item)}
        >
          <span className="text-xs font-light">{id}</span>
          <div className={clsx('flex gap-1', css?.rankbar)}>
            {Array.from({ length: 7 }).map((_, i) => (
              <span
                key={i}
                className={clsx('w-2 h-2', className(rank, i + 1))}
                onClick={() => updateRank(id, i + 1)}
                onKeyDown={(e) => e.key === 'Enter' && updateRank(id, i + 1)}
                role="button"
                tabIndex={0}
                title="Click to rate"
              />
            ))}
          </div>
        </li>
      ))}
    </ul>
  )
}

export type { Props as RankedListProps }
export default RankedList
