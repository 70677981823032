import { toast } from 'react-toastify'

import axios, { AxiosError, AxiosInstance } from 'axios'
import { getCookie, getCookies, removeCookie, setCookie } from 'typescript-cookie'

type APIConfig = {
  baseURL: string
  timeout?: number
  token?: string
  namespace?: string
  isPrivate?: boolean
  headers?: Readonly<Record<string, string>>
}

class API {
  private _server: AxiosInstance
  private _private = true
  private _success = false
  private _status?: number

  public readonly token: {
    key: Readonly<string>
    value: string | null
  }

  constructor(config: APIConfig) {
    this._server = axios.create({
      baseURL: config.baseURL,
      timeout: config.timeout || 5000,
      headers: {
        'Content-Type': 'application/json',
        ...config.headers,
      },
    })

    // Set the private flag
    if (config.isPrivate !== undefined) {
      this._private = config.isPrivate
    }

    this.token = new Proxy(
      {
        key: (config.namespace ?? 'ri-private') + '_token',
        value: config.token || null,
      },
      {
        set: (target, key, value: string | null) => {
          if (key === 'key' || value === target.value) {
            throw new Error('Cannot change token key!')
          }

          if (this._private) {
            // set or remove cookie
            if (value) {
              setCookie(target.key, value)
            } else {
              removeCookie(target.key)
            }
          }

          target.value = value
          return true
        },

        get: (target, key) => {
          if (key === 'value') {
            return target.value ?? getCookie(target.key)
          }

          return target.key
        },
      },
    )

    this.setupInterceptors()
  }

  get isPrivate(): boolean {
    return this._private
  }

  get success(): boolean {
    return this._success
  }

  get status(): number | undefined {
    return this._status
  }

  get server(): Readonly<AxiosInstance> {
    return this._server
  }

  static tokens(namespace: string): Record<string, string> {
    return Object.entries(getCookies()).reduce((acc: Record<string, string>, [key, value]) => {
      if (key.startsWith(namespace)) {
        acc[key] = value
      }
      return acc
    }, {})
  }

  private setupInterceptors() {
    // intercept request and add bearer token
    this._server.interceptors.request.use((request) => {
      // If the request is private, add the token to the headers
      if (this._private && this.token.value) {
        request.headers.Authorization = this.token.value
      }

      return request
    })

    // intercept response and remove token if status is 401
    this._server.interceptors.response.use(
      (response) => {
        this._status = response.status
        this._success = true
        return response
      },
      (error: AxiosError) => {
        this._status = error.response?.status || 500
        this._success = false

        // If the response is unauthorized...remove the token
        if (this._private && this._status === 401) {
          this.token.value = null
        }

        // Show a toast message with the error if it is a GET request
        if (error.config?.method === 'get') {
          toast.error(`${error.code}: ${error.message}`)
        }

        return Promise.reject(error)
      },
    )
  }

  public get<T>(endpoint: string, config?: Record<string, unknown>) {
    return this._server.get<T>(endpoint, config)
  }

  public post<T>(endpoint: string, data?: unknown, config?: Record<string, unknown>) {
    return this._server.post<T>(endpoint, data, config)
  }

  public put<T>(endpoint: string, data?: unknown, config?: Record<string, unknown>) {
    return this._server.put<T>(endpoint, data, config)
  }

  public delete<T>(endpoint: string, config?: Record<string, unknown>) {
    return this._server.delete<T>(endpoint, config)
  }
}

export default API
