import { cn } from '@shared/lib/utils'
import TableComponent from '@shared/ui/table'
import { ColumnDef } from '@tanstack/react-table'

import CheckIcon from '@/assets/images/user-dashboard/check.png'
import DownloadIcon from '@/assets/images/user-dashboard/download_icon.png'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card'
import { Checkbox } from '@/components/ui/checkbox'

type Billings = {
  invoice: string
  name: string
  email: string
  phoneNumber: string
  amount: string
  date: string
  status: 'Paid'
}

const billingData: Billings[] = [
  {
    invoice: 'Classic Plan-Dec 2022',
    name: 'Joseph Ngumbau',
    email: 'tranthuy.nute@gmail.com',
    phoneNumber: '+7 (903) 941-02-27',
    amount: 'USD $15.00',
    date: '15 May 2020 9:30 am',
    status: 'Paid',
  },
  {
    invoice: 'Classic Plan-Dec 2022',
    name: 'Joseph Ngumbau',
    email: 'tranthuy.nute@gmail.com',
    phoneNumber: '+7 (903) 941-02-27',
    amount: 'USD $15.00',
    date: '15 May 2020 9:30 am',
    status: 'Paid',
  },
  {
    invoice: 'Classic Plan-Dec 2022',
    name: 'Joseph Ngumbau',
    email: 'tranthuy.nute@gmail.com',
    phoneNumber: '+7 (903) 941-02-27',
    amount: 'USD $15.00',
    date: '15 May 2020 9:30 am',
    status: 'Paid',
  },
  {
    invoice: 'Classic Plan-Dec 2022',
    name: 'Joseph Ngumbau',
    email: 'tranthuy.nute@gmail.com',
    phoneNumber: '+7 (903) 941-02-27',
    amount: 'USD $15.00',
    date: '15 May 2020 9:30 am',
    status: 'Paid',
  },
  {
    invoice: 'Classic Plan-Dec 2022',
    name: 'Joseph Ngumbau',
    email: 'tranthuy.nute@gmail.com',
    phoneNumber: '+7 (903) 941-02-27',
    amount: 'USD $15.00',
    date: '15 May 2020 9:30 am',
    status: 'Paid',
  },
  {
    invoice: 'Classic Plan-Dec 2022',
    name: 'Joseph Ngumbau',
    email: 'tranthuy.nute@gmail.com',
    phoneNumber: '+7 (903) 941-02-27',
    amount: 'USD $15.00',
    date: '15 May 2020 9:30 am',
    status: 'Paid',
  },
  {
    invoice: 'Classic Plan-Dec 2022',
    name: 'Joseph Ngumbau',
    email: 'tranthuy.nute@gmail.com',
    phoneNumber: '+7 (903) 941-02-27',
    amount: 'USD $15.00',
    date: '15 May 2020 9:30 am',
    status: 'Paid',
  },
]

const columns: ColumnDef<Billings>[] = [
  {
    id: 'select',
    header: ({ table }) => (
      <Checkbox
        className="border-[#00071426] rounded-[1px] md:rounded-[3px] data-[state=checked]:bg-white data-[state=checked]:text-black"
        checked={
          table.getIsAllPageRowsSelected() || (table.getIsSomePageRowsSelected() && 'indeterminate')
        }
        onCheckedChange={(value) => table.toggleAllPageRowsSelected(!!value)}
        aria-label="Select all"
      />
    ),
    cell: ({ row }) => (
      <Checkbox
        className="border-[#00071426] rounded-[1px] md:rounded-[3px] data-[state=checked]:bg-white data-[state=checked]:text-black"
        checked={row.getIsSelected()}
        onCheckedChange={(value) => row.toggleSelected(!!value)}
        aria-label="Select row"
      />
    ),
    enableSorting: false,
    enableHiding: false,
  },
  {
    accessorKey: 'invoice',
    header: 'Invoice',
    cell: ({ row }) => (
      <div className="font-normal text-black capitalize">{row.getValue('invoice')}</div>
    ),
  },
  {
    accessorKey: 'name',
    header: () => <div>Name</div>,
    cell: ({ row }) => <p className="font-normal text-black capitalize">{row.getValue('name')}</p>,
  },
  {
    accessorKey: 'email',
    header: () => {
      return <div>Email</div>
    },
    cell: ({ row }) => (
      <div className="font-normal text-black lowercase">{row.getValue('email')}</div>
    ),
  },
  {
    accessorKey: 'phoneNumber',
    header: () => <div>Phone Number</div>,
    cell: ({ row }) => (
      <p className="font-normal text-black capitalize">{row.getValue('phoneNumber')}</p>
    ),
  },
  {
    accessorKey: 'amount',
    header: () => <div className="text-left">Amount</div>,
    cell: ({ row }) => {
      return <div className="font-normal text-left text-black">{row.getValue('amount')}</div>
    },
  },
  {
    accessorKey: 'date',
    header: () => <div>Date</div>,
    cell: ({ row }) => <p className="font-normal text-black capitalize">{row.getValue('date')}</p>,
  },
  {
    id: 'status',
    accessorKey: 'status',
    header: () => <div className="text-right">Status</div>,
    cell: ({ row }) => {
      let statusClass = ''

      if (row.getValue('status') === 'Paid') {
        statusClass =
          'bg-[#4CAF50] rounded-[1px] md:rounded-[5px] w-6 h-3 md:w-auto md:h-auto m-auto text-center text-white py-[0px] md:py-1'
      }

      return (
        <div className={`capitalize flex items-center justify-center ${statusClass}`}>
          {row.getValue('status')}
        </div>
      )
    },
  },
  {
    accessorKey: 'download',
    header: () => <div></div>,
    cell: () => <img src={DownloadIcon} alt="download" className="h-2 cursor-pointer md:h-4" />,
  },
]

const Billing = () => {
  return (
    <>
      <div>
        <div className="flex justify-between items-center py-2 md:py-5 md:border-b border-[#00071433] ">
          <div>
            <p className="text-[12px] md:text-lg font-semibold ">Billing</p>
            <p className="text-[12px] md:text-base font-normal ">
              Manage your billing and payment details.
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 mt-5 md:my-16">
          {/* free */}
          <Card className={cn('col-span-12 mb-5 md:mr-5 md:col-span-4')}>
            <CardHeader>
              <h3 className="mb-2 text-base font-medium md:text-2xl md:mb-4">Starter</h3>
              <CardTitle className="text-lg md:text-4xl">Free</CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <Button className="w-full mb-2" variant="outline" size="lg" disabled>
                Try for free
              </Button>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">90 days access</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">3 templates</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Unlimited customization</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as PDF</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as Docx</p>
                </div>
              </div>
            </CardContent>
          </Card>
          {/* classic */}
          <Card className={cn('col-span-12 mb-5 md:mr-5 md:col-span-4')}>
            <CardHeader>
              <h3 className="mb-2 text-base font-medium md:text-2xl md:mb-4">Classic</h3>
              <CardTitle className="text-lg md:text-4xl">
                $ 15<span className="text-xs font-normal md:text-lg"> / month</span>
              </CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <Button className="w-full mb-2" variant="filled" size="lg">
                Upgrade
              </Button>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">90 days access</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">3 templates</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Unlimited customization</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as PDF</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as Docx</p>
                </div>
              </div>
            </CardContent>
          </Card>
          {/* Premium */}
          <Card className={cn('col-span-12 mb-5 md:col-span-4')}>
            <CardHeader>
              <h3 className="mb-2 text-base font-medium md:text-2xl md:mb-4">Premium</h3>
              <CardTitle className="text-lg md:text-4xl">
                $ 50<span className="text-xs font-normal md:text-lg"> / month</span>
              </CardTitle>
            </CardHeader>
            <CardContent className="grid gap-4">
              <Button className="w-full mb-2" variant="outline" size="lg">
                Upgrade
              </Button>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">90 days access</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">3 templates</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Unlimited customization</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as PDF</p>
                </div>
              </div>
              <div className=" grid grid-cols-[25px_1fr] items-center pb-2 last:mb-0 last:pb-0">
                <img src={CheckIcon} alt="check-icon" className="w-4 h-4" />
                <div className="space-y-1">
                  <p className="text-sm font-medium leading-none">Download as Docx</p>
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
        <div className="flex items-center justify-between mb-5">
          <p className="text-[12px] md:text-lg font-semibold ">Billing History</p>
          <Button
            variant="outline"
            className="border-[#00071426] text-[#000714] flex gap-3 items-center"
          >
            <img src={DownloadIcon} alt="download-icon" className="h-4 " />
            Download all
          </Button>
        </div>
        <TableComponent data={billingData} columns={columns} title="" showHeader={false} />
        <div className="flex gap-5 my-10 md:hidden">
          <Button variant="outline" color="#0959FE" className="w-1/2">
            Cancel
          </Button>
          <Button variant="filled" color="#0959FE" className="w-1/2">
            Save
          </Button>
        </div>
      </div>
    </>
  )
}

export default Billing
