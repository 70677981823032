import { useRef, useState } from 'react'

import { Icon } from '@shared/ui'
import { useCollapseOnOutsideClick, useRecalculateFlow } from '@widgets/sole-dropdown/hooks'
import { SoleDropdownLabel, SoleDropdownOption } from '@widgets/sole-dropdown/ui'
import { SoleDropdownItem, SoleDropdownProps } from '@widgets/types'
import clsx from 'clsx'

const SoleDropdown = ({
  isMulti = false,
  isClearable = false,
  isLoading = false,
  disabled = false,
  useSelectedAsPlaceholder = false,
  size = 'fit',
  flow = 'bottom-left',
  placeholder,
  className,
  options,
  defaultValue,
  onSelect,
}: SoleDropdownProps) => {
  const listRef = useRef<HTMLDivElement>(null)

  const [isOpen, setIsOpen] = useState(false)
  const [selected, setSelected] = useState<SoleDropdownItem | SoleDropdownItem[]>(
    defaultValue || (isMulti ? [] : ''),
  )

  // Hook to handle dropdown positioning
  const position = useRecalculateFlow(listRef, isOpen, flow)
  // Hook to handle collapsing dropdown on outside click
  useCollapseOnOutsideClick(listRef, () => setIsOpen(false))

  // Handle selection
  const handleSelect = async (item: SoleDropdownItem) => {
    if (isMulti) {
      const selectedItems = Array.isArray(selected) ? [...selected] : []
      const index = selectedItems.findIndex((i) => i === item)
      if (index === -1) {
        selectedItems.push(item)
      } else {
        selectedItems.splice(index, 1)
      }

      setSelected(selectedItems)
      onSelect && onSelect(selectedItems)
    } else {
      if (selected === item) {
        setSelected('')
        onSelect && onSelect('')
      } else {
        setSelected(item)
        onSelect && onSelect(item)
      }

      // Close dropdown on select
      setIsOpen(false)
    }
  }

  return (
    <div
      className={clsx(
        'relative',
        {
          'w-full': size === 'full',
          'w-fit': size === 'fit',
          'cursor-pointer': !disabled,
          'cursor-not-allowed opacity-75': disabled,
        },
        className,
      )}
    >
      {/* Indicator container */}
      <div
        className={clsx('flex items-center gap-3 px-2 py-1 bg-white border rounded-md', {
          'border-secondary-200': !isOpen,
          'border-primary-500 bg-primary-50': isOpen,
        })}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={(e) => e.key === 'Enter' && setIsOpen(!isOpen)}
        role="button"
        tabIndex={0}
      >
        <SoleDropdownLabel
          selectedAsPH={useSelectedAsPlaceholder}
          selected={selected}
          isMulti={isMulti}
          placeholder={placeholder}
        />
        <span
          className={clsx({
            'text-current-400': !isOpen,
            'text-current-700': isOpen,
          })}
          onClick={() => isClearable && handleSelect((isMulti ? [] : '') as SoleDropdownItem)}
          onKeyDown={(e) =>
            e.key === 'Enter' &&
            isClearable &&
            handleSelect((isMulti ? [] : '') as SoleDropdownItem)
          }
          role="button"
          tabIndex={0}
        >
          {isLoading ? (
            <Icon icon="spinner" animation="spin" color="current-900" />
          ) : isClearable ? (
            <Icon icon="xmark" animation="fade" color="current-900" />
          ) : (
            <Icon icon={isOpen ? 'angle-up' : 'angle-down'} color="current-900" />
          )}
        </span>
      </div>

      {/* Dropdown list */}
      {isOpen && (
        <div
          ref={listRef}
          className={clsx(
            'absolute z-10 mt-1 bg-white border border-gray-300 rounded-md shadow-lg max-h-80 overflow-y-auto',
            {
              // calculate height based on number of options
              [`h-[${options.length * 8}]`]: options.length <= 10,
              // set position based on flow
              'top-full left-0': position === 'bottom-left',
              'top-full right-0': position === 'bottom-right',
              'bottom-full left-0': position === 'top-left',
              'bottom-full right-0': position === 'top-right',
            },
            `w-${size}`,
          )}
        >
          {options.map((option, index) => (
            <SoleDropdownOption
              key={index}
              selected={selected}
              onSelect={handleSelect}
              item={option}
              isMulti={isMulti}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default SoleDropdown
