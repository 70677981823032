import { useState } from 'react'

import EyeIcon from '@/assets/images/user-dashboard/ri_eye.png'
import { Button } from '@/components/ui/button'
import { Input } from '@/components/ui/input'

type ChangeFieldType = 'previousPassword' | 'newPassword' | 'confirmPassword'

const ChangePasswordForm = () => {
  const [isPreviousPasswordVisible, setIsPreviousPasswordVisible] = useState(false)
  const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false)
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false)

  const [previousPassword, setPreviousPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [previousPasswordError, setPreviousPasswordError] = useState(false)
  const [newPasswordError, setNewPasswordError] = useState(false)
  const [confirmPasswordError, setConfirmPasswordError] = useState(false)
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    field: ChangeFieldType,
  ) => {
    const value = event.target.value

    switch (field) {
      case 'previousPassword':
        setPreviousPassword(value)
        setPreviousPasswordError(!value)
        break
      case 'newPassword':
        setNewPassword(value)
        setNewPasswordError(!value || value.length < 8)
        break
      case 'confirmPassword':
        setConfirmPassword(value)
        setConfirmPasswordError(value !== newPassword)
        break
      default:
        break
    }
  }

  return (
    <div>
      <div className="md:border-b flex justify-between items-center border-[#00071433] py-2 md:py-5">
        <div>
          <p className="text-[12px] md:text-lg font-semibold ">Password</p>
          <p className="text-[12px] md:text-base font-normal ">Update your password details here</p>
        </div>
        <div className="hidden gap-4 md:flex">
          <Button variant="outline" color="#0959FE">
            Cancel
          </Button>
          <Button variant="filled" color="#0959FE">
            Save
          </Button>
        </div>
      </div>
      <div className="grid grid-cols-12 md:border-b border-[#00071433] item gap-2 md:gap-4 py-2 md:py-5">
        <p className="text-[12px] md:text-lg font-semibold col-span-12 md:col-span-2">
          Previous Password
        </p>
        <div className="relative col-span-12 md:col-span-9">
          <Input
            required
            className={`w-full ${previousPasswordError ? 'border-red-500' : ''}`}
            placeholder="********"
            type={isPreviousPasswordVisible ? 'text' : 'password'}
            value={previousPassword}
            onChange={(e) => handleInputChange(e, 'previousPassword')}
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={() => setIsPreviousPasswordVisible(!isPreviousPasswordVisible)}
          >
            <img src={EyeIcon} alt="Toggle visibility" className="w-6 h-6" />
          </button>
        </div>
      </div>

      {/* New Password */}
      <div className="grid grid-cols-12 md:border-b border-[#00071433] item gap-2 md:gap-4 py-2 md:py-5">
        <p className="text-[12px] md:text-lg font-semibold col-span-12 md:col-span-2">
          New Password
        </p>
        <div className="relative col-span-12 md:col-span-9">
          <Input
            required
            className={`w-full ${newPasswordError ? 'border-red-500' : ''}`}
            placeholder="********"
            type={isNewPasswordVisible ? 'text' : 'password'}
            value={newPassword}
            onChange={(e) => handleInputChange(e, 'newPassword')}
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={() => setIsNewPasswordVisible(!isNewPasswordVisible)}
          >
            <img src={EyeIcon} alt="Toggle visibility" className="w-6 h-6" />
          </button>
        </div>
      </div>

      {/* Confirm Password */}
      <div className="grid grid-cols-12 md:border-b border-[#00071433] item gap-2 md:gap-4 py-2 md:py-5">
        <p className="text-[12px] md:text-lg font-semibold col-span-12 md:col-span-2">
          Confirm Password
        </p>
        <div className="relative col-span-12 md:col-span-9">
          <Input
            required
            className={`w-full ${confirmPasswordError ? 'border-red-500' : ''}`}
            placeholder="********"
            type={isConfirmPasswordVisible ? 'text' : 'password'}
            value={confirmPassword}
            onChange={(e) => handleInputChange(e, 'confirmPassword')}
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 flex items-center pr-3"
            onClick={() => setIsConfirmPasswordVisible(!isConfirmPasswordVisible)}
          >
            <img src={EyeIcon} alt="Toggle visibility" className="w-6 h-6" />
          </button>
        </div>
      </div>

      <div className="flex gap-5 my-10 md:hidden">
        <Button variant="outline" color="#0959FE" className="w-1/2">
          Cancel
        </Button>
        <Button variant="filled" color="#0959FE" className="w-1/2">
          Save
        </Button>
      </div>
    </div>
  )
}

export default ChangePasswordForm
