import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import AddIcon from '@/assets/images/user-dashboard/add_icon.png'
import CloseIcon from '@/assets/images/user-dashboard/close_icon.png'
import MasterCard from '@/assets/images/user-dashboard/master_card.png'
import Paypal from '@/assets/images/user-dashboard/paypal.png'
import { Button } from '@/components/ui/button'
import { Checkbox } from '@/components/ui/checkbox'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/components/ui/collapsible'
import { Input } from '@/components/ui/input'
import { RadioGroup, RadioGroupItem } from '@/components/ui/radio-group'

const SubscribeToRevamp = () => {
  const [isOpen, setIsOpen] = useState(false)
  const navigate = useNavigate()

  const handleClose = () => {
    navigate(-1)
  }

  return (
    <div
      className="bg-white grid grid-cols-12 mx-7 rounded-[10px] h-auto shadow-md relative"
      onClick={(e) => e.stopPropagation()}
      aria-hidden="true"
    >
      <div className="col-span-7 p-6 bg-white">
        <p className="mb-5 text-xl font-semibold md:mb-8">Subcribe to Revamp</p>
        <div>
          <p className="mb-4 text-xl font-semibold">Payment Info</p>
          <p className="mb-2 text-sm font-normal">
            Billing Address <span className="text-[#0007144D]">(optional)</span>
          </p>
          <Input placeholder="Address" type="text" />
          <div className="flex flex-col gap-4 my-3 md:flex-row">
            <Input placeholder="City" type="text" />
            <Input placeholder="State" type="text" />
          </div>
          <div className="flex flex-col gap-4 my-3 md:flex-row">
            <Input placeholder="Zip" type="text" />
            <Input placeholder="Country" type="text" />
          </div>
          <p className="mb-2 text-sm font-normal">
            Company Details <span className="text-[#0007144D]">(optional)</span>
          </p>
          <div className="flex flex-col gap-4 my-3 md:flex-row">
            <Input placeholder="Address" type="text" />
            <Input placeholder="Phone Number" type="text" />
          </div>
          <p className="mb-2 text-sm font-normal mt-7">Payment Method</p>
          <RadioGroup defaultValue="comfortable">
            <div className="flex items-center justify-between space-x-2 py-2 px-2 border rounded-[5px]">
              <div className="flex items-center gap-1">
                <img src={MasterCard} alt="master-card" className="h-4 " />
                <label htmlFor="master-card" className="text-sm font-normal">
                  Master Card ********189
                </label>
              </div>
              <RadioGroupItem value="default" id="master-card" />
            </div>
            <div className="flex items-center justify-between space-x-2 py-2 px-2 border rounded-[5px]">
              <div className="flex items-center gap-1">
                <img src={Paypal} alt="paypal" className="h-5 " />
                <label htmlFor="Paypal" className="text-sm italic font-normal">
                  Pay<span className="text-[#2790c3]">pal</span>
                </label>
              </div>
              <RadioGroupItem value="Paypal" id="Paypal" />
            </div>
            <Collapsible open={isOpen} onOpenChange={setIsOpen}>
              <div className="flex items-center justify-between space-x-2 py-2 px-2 border rounded-[5px]">
                <div className="flex items-center gap-1">
                  <img src={MasterCard} alt="master-card" className="h-4 " />
                  <label htmlFor="master-card-visa" className="text-sm font-normal">
                    Master Card / VISA Card
                  </label>
                </div>
                <CollapsibleTrigger asChild>
                  <RadioGroupItem value="master-card/visa-card" id="master-card-visa" />
                </CollapsibleTrigger>
              </div>
              <CollapsibleContent className="mt-7">
                <p className="mb-2 text-sm font-normal">Name on Card *</p>
                <Input placeholder="Name" type="text" required className="mb-4" />
                <p className="mb-2 text-sm font-normal">Card Number *</p>
                <Input placeholder="0773 - 9817 - 1333 - 7837" type="text" required />
                <div className="flex flex-col gap-4 mt-4 md:flex-row">
                  <div className="w-1/2">
                    <p className="mb-2 text-sm font-normal">Expiry Date *</p>
                    <Input placeholder="MM/YY" type="text" required />
                  </div>
                  <div className="w-1/2">
                    <p className="mb-2 text-sm font-normal">CVC/CVV *</p>
                    <Input placeholder="CVV" type="text" required />
                  </div>
                </div>
              </CollapsibleContent>
            </Collapsible>
          </RadioGroup>
        </div>
      </div>
      <div className="bg-[#F7F9FA] col-span-5 p-6">
        <div className="flex items-end justify-end mb-5">
          <button onClick={handleClose}>
            <img src={CloseIcon} alt="close-icon" className="h-5 " />
          </button>
        </div>
        <p className="mb-4 text-xl font-semibold">Review your subscription</p>
        <RadioGroup>
          <div className="flex gap-5">
            <div className="flex items-center gap-2 px-2 py-2 space-x-2">
              <RadioGroupItem value="monthly" />
              <label htmlFor="monthly" className="text-sm font-normal">
                Pay Monthly
              </label>
            </div>
            <div className="flex items-center gap-2 px-2 py-2 space-x-2">
              <RadioGroupItem value="yearly" />
              <label htmlFor="yearly" className="text-sm font-normal">
                Pay Yearly
              </label>
            </div>
          </div>
        </RadioGroup>
        <div className="flex justify-between my-4">
          <p className="text-sm font-normal">Miles Esther</p>
          <p className="text-base font-medium">$15:00</p>
        </div>
        <Collapsible
          open={isOpen}
          onOpenChange={setIsOpen}
          className=" border-b border-[#00071433] space-y-2"
        >
          <div className="flex justify-between mt-4 ">
            <p className="font-normal text-sm mb-2 text-[#0959FE]">
              Add Coupon Code <span className="text-[#0959FE80]">(optional)</span>
            </p>
            <CollapsibleTrigger asChild>
              <Button variant="ghost" size="sm" className="p-0 w-9">
                <img src={AddIcon} alt="add-icon" className="h-4" />
                <span className="sr-only">Toggle</span>
              </Button>
            </CollapsibleTrigger>
          </div>
          <CollapsibleContent>
            <Input
              className="col-span-11 mb-5 md:col-span-8"
              placeholder="1234 1425 6784"
              type="text"
            />
          </CollapsibleContent>
        </Collapsible>
        <div className="flex justify-between my-4">
          <p className="text-sm font-normal">To Pay Now</p>
          <p className="text-base font-medium">$15:00</p>
        </div>
        <div className="flex flex-col w-100">
          <Button variant="filled" size="lg" type="submit">
            Pay Now
          </Button>
        </div>
        <div className="flex items-center gap-2 mt-4">
          <Checkbox className="h-[13px] w-[14px] rounded-[2px] flex items-center" />
          <label
            htmlFor="terms"
            className="text-[10px] font-normal leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            Click on the check box to save your card for future purchase.
          </label>
        </div>
        <p className="text-[10px] font-normal my-4">
          By continue, you agree to Revamp <span className="text-[#0959FE]">terms</span> and{' '}
          <span className="text-[#0959FE]">privacy policy</span> you&apos;ll be charged $15:00 USD
          every month until you cancel your subscription
        </p>
      </div>
    </div>
  )
}

export default SubscribeToRevamp
