import { forwardRef } from 'react'

import { Icon } from '@shared/ui'
import { CVTemplateProps } from '@widgets/types'
import clsx from 'clsx'

const DefaultCVTemplate = forwardRef<HTMLDivElement, CVTemplateProps>(
  ({ className, cv }: CVTemplateProps, ref) => {
    return (
      <div ref={ref} className={clsx('flex', className)}>
        <section className="w-4/12 md:w-3/12 bg-[#373c48] text-[#e6e7e8] py-3 rounded-l-sm">
          <div className="px-2 mb-5">
            <h1 className="text-md md:text-lg lg:text-xl xl:text-2xl font-bold mb-1">
              {cv?.fullName}
            </h1>
            <h5 className="text-sm xl:text-lg leading-5 font-medium">{cv?.profession}</h5>
          </div>

          <div>
            <h3 className="bg-[#242832] p-2 text-sm md:text-md font-medium">Personal Info</h3>
            <div className="px-2 py-4 flex gap-2 flex-col break-words">
              {/* Address Info */}
              {cv?.address && (
                <div>
                  <h6 className="font-medium text-xs">Address</h6>
                  <address>
                    {cv?.address?.streetNumber ?? ''} {cv?.address?.street || ''}{' '}
                    {cv?.address?.city}, {cv?.address?.state} {cv?.address?.country}
                  </address>
                </div>
              )}

              {/* Phone Number Info */}
              <div>
                <h6 className="font-medium text-xs">Phone</h6>
                <div>{cv?.phoneNumbers.map((p_num) => <p key={p_num}>{p_num}</p>)}</div>
              </div>

              {/* Email Info */}
              <div>
                <h6 className="font-medium text-xs">E-mail</h6>
                <div>{cv?.emails.map((email, index) => <p key={index}>{email}</p>)}</div>
              </div>

              {/* LinkedIn Info */}
              <div>
                <h6 className="font-medium text-xs">LinkedIn</h6>
                <p>{cv?.socialLinks.linkedin}</p>
              </div>
            </div>
          </div>

          {/* List of Skills */}
          <div>
            <h3 className="bg-[#242832] p-2 text-sm md:text-md font-medium">Skills</h3>
            <div className="px-2 py-4 flex gap-3 flex-col break-all">
              {cv?.skills.slice(0, 5).map((skill) => (
                <p key={skill.id} className="flex items-center gap-2">
                  <i className="fa-solid fa-check"></i>
                  <span>{skill.name}</span>
                </p>
              ))}
            </div>
          </div>
        </section>

        <section className="w-8/12 md:w-9/12 grow px-2 md:p-3 flex flex-col gap-3">
          <p className="italic">{cv?.summary}</p>
          <div>
            <h3 className="border-t border-b border-[#0007144c] py-1 text-xl font-bold">
              Experience
            </h3>
            <div className="flex flex-col gap-4 my-4">
              {cv?.workExperience.slice(0, 3).map((experience, index) => (
                <article
                  key={`work_experience_${index}`}
                  className="flex items-start gap-3 justify-between"
                >
                  <p className="w-3/12">
                    {experience.dates.from} -
                    {experience.dates.isCurrent ? 'Present' : experience.dates.to}
                  </p>
                  <div className="w-8/12">
                    <h4>{experience.title}</h4>
                    <p>
                      <span>{experience.organization}</span>,
                      <span>
                        {experience.location?.remote ? 'Remote' : experience?.location?.country}
                      </span>
                    </p>
                    <div>{experience.description}</div>
                  </div>
                </article>
              ))}
            </div>
          </div>
          <div>
            <h3 className="border-t border-b border-[#0007144c] py-1 text-xl font-bold">
              Education
            </h3>
            <div className="flex flex-col gap-4 my-4">
              {cv?.education.slice(0, 3).map((education, index) => (
                <article
                  key={`education_${index}`}
                  className="flex items-start gap-3 justify-between"
                >
                  <p className="w-3/12">
                    {education.dates.from} -
                    {education.dates.isCurrent ? 'Present' : education.dates.to}
                  </p>
                  <div className="w-8/12">
                    <h4>{education.accreditation.fieldOfStudy}</h4>
                    <p>
                      {education.organization},
                      {education.location
                        ? `${education.location.country}, ${education.location.countryCode}`
                        : 'Remote'}
                    </p>
                  </div>
                </article>
              ))}
            </div>
          </div>
          <div>
            <h3 className="border-t border-b border-[#0007144c] py-1 text-xl font-bold">
              Certificates
            </h3>
            <div className="flex flex-col gap-2 my-4">
              {cv?.certifications.slice(0, 5).map((cert, index) => (
                <p key={`certification_${index}`} className="flex items-center gap-3">
                  <Icon icon="check" />
                  <span>{cert}</span>
                </p>
              ))}
            </div>
          </div>
        </section>
      </div>
    )
  },
)

DefaultCVTemplate.displayName = 'DefaultCVTemplate'
export default DefaultCVTemplate
