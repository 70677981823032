import AuthOptions from '@entities/oauth/ui/auth-options'
import { useAuthenComplete } from '@features/auth/lib/hooks'
import ResetPasswordForm from '@features/auth/reset-password/ui/form'

export default function FLogin() {
  const onComplete = useAuthenComplete()

  return (
    <>
      <ResetPasswordForm onComplete={onComplete} />
      {/* Other login options */}
      <AuthOptions />
    </>
  )
}
