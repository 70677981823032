import { useNavigate } from 'react-router-dom'

import ROUTEMAP from '@shared/routemap.json'
import { Button } from '@shared/ui'

import CVTemplateScreenshot from '@/assets/images/base/cv-template.svg'

export default function HeroSection() {
  const navigate = useNavigate()

  const handleCreateCV = () => {
    navigate(ROUTEMAP.cv.BUILD, { state: { from: ROUTEMAP.base.DEFAULT } })
  }

  return (
    <section className="flex flex-col text-center items-center gap-4 md:gap-6 py-5 leading-8 md:py-10 md:h-[75vh] bg-[#FAFDFF] overflow-y-hidden">
      <h1 className="text-3xl md:text-5xl font-bold">
        Create a <span className="text-primary-900">job - winning CV</span>
        <br />
        in only 5 minutes
      </h1>
      <p className="text-sm md:text-base w-5/6 md:w-6/12">
        Easily create an outstanding CV for different roles and industries with less intervention to
        level up your career goal and get hired by top firms.
      </p>
      <Button variant="primary" size="md" onClick={handleCreateCV} className="px-10">
        Create CV
      </Button>
      <div className="relative max-w-[900px] w-full h-[300px] md:h-[500px] mt-5">
        <img
          src={CVTemplateScreenshot}
          alt="CV Template Left"
          className="absolute top-0 left-0 w-[150px] md:w-[300px] z-10"
        />
        <img
          src={CVTemplateScreenshot}
          alt="CV Template Center"
          className="absolute top-0 left-1/2 transform -translate-x-1/2 w-[300px] md:w-[500px] z-20 -mt-5"
        />
        <img
          src={CVTemplateScreenshot}
          alt="CV Template Right"
          className="absolute top-0 right-0 w-[150px] md:w-[300px] z-10"
        />
      </div>
    </section>
  )
}
