import Icon from '@shared/ui/icon'
import clsx from 'clsx'

type Props = {
  count: number
  is_active?: boolean
  className?: string
  size?: 'sm' | 'md' | 'lg'
  icon?: React.ReactNode | string
}

export default function CountLabel({
  count,
  is_active = false,
  className,
  icon,
  size = 'md',
}: Props) {
  return (
    <div
      className={clsx(
        'flex items-center justify-center rounded-full p-4 text-center text-primary-900 font-semibold border-primary-900 border',
        {
          'text-sm w-6 h-6': size === 'sm',
          'text-lg w-8 h-8': size === 'md',
          'text-2xl w-10 h-10': size === 'lg',
          'bg-primary-900 text-white': is_active,
        },
        className,
      )}
    >
      {icon ? (
        typeof icon === 'string' ? (
          <Icon icon={icon} size={size} />
        ) : (
          icon
        )
      ) : (
        count.toLocaleString().padStart(2, '0')
      )}
    </div>
  )
}
