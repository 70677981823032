import { useSelectedTemplate } from '@shared/hooks'
import { CVCustomizer, CVPreview } from '@widgets/cv-preview'
import { TemplatesSelector } from '@widgets/selectors'

// import { loaders } from '@/widgets'

const ReviewStep = () => {
  const { selectedTemplate, select } = useSelectedTemplate()

  const handleCustomizerSelected = () => {
    // TODO: handle customizer selection
  }

  return (
    <div className="w-full flex gap-3 flex-col md:flex-row">
      <CVPreview template={selectedTemplate} className="md:w-6/12 xl:w-5/12" />
      <div className="w-full md:w-5/12 border border-secondary-200 rounded-md relative self-start min-h-[32rem]">
        <TemplatesSelector onSelectTemplate={select} />
        <div className="absolute bottom-0 bg-white right-0 left-0 shadow m-2 md:mx-4 p-2 md:p-3 rounded-md border">
          <CVCustomizer onSelected={handleCustomizerSelected} />
        </div>
      </div>
    </div>
  )
}

export default ReviewStep
