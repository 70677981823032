import { Address, Education, Skill, SocialLinks, WorkExperience } from '@shared/model/usercv'
import { Instance, types } from 'mobx-state-tree'

const UserCV = types
  .model('User', {
    id: types.identifier,
    fullName: types.string,
    profession: types.string,
    summary: types.maybe(types.string),
    avatar: types.maybe(types.string),
    emails: types.array(types.string),
    phoneNumbers: types.array(types.string),
    certifications: types.array(types.string),
    address: types.maybe(Address),
    skills: types.array(Skill),
    socialLinks: SocialLinks,
    workExperience: types.array(WorkExperience),
    education: types.array(Education),
  })
  .views((self) => ({
    get firstName() {
      return self.fullName.split(' ')[0]
    },

    get lastName() {
      return self.fullName.split(' ')[1]
    },

    get formattedAddress() {
      let address = ''

      if (!self.address) {
        return 'No address provided'
      }

      if (self.address.streetNumber) {
        address += `${self.address.streetNumber} `
      }

      if (self.address.street) {
        address += `${self.address.street} `
      }

      if (self.address.city) {
        address += `${self.address.city}, `
      }

      if (self.address.state) {
        address += `${self.address.state} `
      }

      if (self.address.country) {
        address += self.address.country
      }

      return address
    },

    get randomEmail() {
      if (self.emails.length === 0) {
        return 'No email provided'
      }

      return self.emails[Math.floor(Math.random() * self.emails.length)]
    },

    get randomPhoneNumber() {
      if (self.phoneNumbers.length === 0) {
        return 'No phone number provided'
      }

      return self.phoneNumbers[Math.floor(Math.random() * self.phoneNumbers.length)]
    },
  }))

interface IUserCV {
  id: string
  fullName: string
  profession: string
  summary?: string
  avatar?: string
  emails: string[]
  phoneNumbers: string[]
  certifications: string[]
  address?: Instance<typeof Address>
  skills: Instance<typeof Skill>[]
  socialLinks: Instance<typeof SocialLinks>
  workExperience: Instance<typeof WorkExperience>[]
  education: Instance<typeof Education>[]

  firstName: string
  lastName: string
  formattedAddress: string
  randomEmail: string
  randomPhoneNumber: string
}

type APIUserCVData = Omit<IUserCV, 'fullName' | 'socialLinks' | 'address'> & {
  raw: string
  location: Instance<typeof Address>
  linkedin?: string
  github?: string
  twitter?: string
  website?: string
}

export type { APIUserCVData, IUserCV }
export default UserCV
