import { RefObject, useEffect, useState } from 'react'

type Flow = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right'

const useRecalculateFlow = (listRef: RefObject<HTMLDivElement>, isOpen: boolean, flow: Flow) => {
  const [calculatedFlow, setCalculatedFlow] = useState(flow)

  useEffect(() => {
    if (!listRef.current || !isOpen) return

    const list = listRef.current
    const listRect = list.getBoundingClientRect()
    const viewportHeight = window.innerHeight
    const viewportWidth = window.innerWidth

    let [v, h] = calculatedFlow.split('-')

    if (listRect.top < 0) {
      v = 'bottom'
    } else if (listRect.bottom > viewportHeight) {
      v = 'top'
    }

    if (listRect.right > viewportWidth) {
      h = 'right'
    } else if (listRect.left < 0) {
      h = 'left'
    }

    // update the flow if it doesn't fit the viewport
    setCalculatedFlow(`${v}-${h}` as Flow)
  }, [flow, isOpen])

  return calculatedFlow
}

export default useRecalculateFlow
