import { RouteObject } from 'react-router-dom'

import FAssistedBuildMode from '@features/build-cv/assisted'
import ROUTEMAP from '@shared/routemap.json'
import { AppLayout } from '@shared/ui'

import ChooseBuildOption from './choose-option'

const routes: RouteObject = {
  path: ROUTEMAP.cv.BUILD,
  element: (
    <AppLayout padded maxWidth>
      <ChooseBuildOption />
    </AppLayout>
  ),
  children: [
    {
      path: 'assisted-mode',
      element: (
        <div>
          <FAssistedBuildMode />
        </div>
      ),
    },
  ],
}

export default routes
