import { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

import sessionSlice from '@entities/session/model/slice'
import { IUser } from '@shared/model'

function useAuthenComplete() {
  const navigate = useNavigate()

  const oncomplete = useCallback((data: unknown) => {
    // Handle the login completion
    const expected = data as {
      success: boolean
      user: Omit<IUser, 'cv'> & {
        confirmed: boolean
      }
      accessToken: string
      expiresIn: number
    }

    if (expected.success && expected.user.confirmed) {
      // send the token to the session slice
      sessionSlice.setToken(expected.accessToken, false)
      sessionSlice.set({ ...expected.user, cv: null })

      // set the expiresIn in the session storage
      sessionStorage.setItem('ri-expires-in', expected.expiresIn.toString())

      // Show a welcome message
      toast.success(`Welcome, ${expected.user.fullName}`)
      // Redirect to the home page
      navigate('/user/dashboard', { replace: true })
    }

    // TODO: Handle unconfirmed users
  }, [])

  return oncomplete
}

export default useAuthenComplete
