import { z } from 'zod'

const formSchema = z.object({
  password: z
    .string()
    .min(6, { message: 'Password must be at least 6 characters' })
    .regex(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/, {
      message:
        'Password must contain at least one uppercase letter, one lowercase letter, one number, and one special character',
    }),
  confirmPassword: z.string().min(1, { message: 'Confirm password is required' }),
  resetToken: z.string(),
})

export type IFormSchema = z.infer<typeof formSchema>
export default formSchema
