import FResumeForm from '@features/fastrack/resume-form'

export default function CreateCV() {
  return (
    <div className="flex flex-col gap-6 my-8 min-h-screen">
      <h1 className="text-2xl md:text-3xl lg:text-4xl font-medium text-current-900 mx-auto">
        Enter your details below
      </h1>
      <FResumeForm className="border border-secondary-200 py-6 md:py-10 rounded" />
    </div>
  )
}
