import { useRef, useState } from 'react'

import { useAutoScroll } from '@shared/hooks'
import { templater } from '@shared/lib'
import { Button } from '@shared/ui'
import clsx from 'clsx'

const snapshots = Object.entries(templater.getAllCVTemplates())
  .map(([key, value]) => ({
    id: key,
    url: value,
  }))
  .slice(0, 10)

export default function ChooseCVTemplateSection() {
  const templatesRef = useRef<HTMLDivElement | null>(null)
  // choose the center template as the active template
  const [activeTemplate, setActiveTemplate] = useState<string>(snapshots[0].id)

  // scroll to the active template
  useAutoScroll(templatesRef, activeTemplate, () => {
    setActiveTemplate((t) => {
      const currentIndex = snapshots.findIndex(({ id }) => id === t)
      const nextIndex = (currentIndex + 1) % snapshots.length
      return snapshots[nextIndex].id
    })
  })

  return (
    <div className="py-5 md:py-10 bg-[#051A45] text-white">
      <section className="max-w-[900px] mx-auto px-5">
        <h4 className="text-2xl md:text-4xl font-medium text-center">
          Choose from our professionally
          <br />
          designed CV templates.
        </h4>
        {/* An automated templates slider */}
        <section className="flex flex-col gap-3 mt-5 md:mt-10">
          <div
            className="flex gap-4 items-center mt-5 container-snap overflow-x-auto scroll-smooth pointer-events-none"
            ref={templatesRef}
          >
            {snapshots.map(({ id, url }) => (
              <img
                key={id}
                src={url}
                id={id}
                alt={`Revamp Insights Fastrack ` + id}
                className={clsx(
                  'relative w-full max-w-96 bg-slate-100 rounded-md shadow-lg cursor-pointer',
                  {
                    'h-[350px] md:h-[450px]': activeTemplate !== id,
                    'h-[400px] md:h-[500px]': activeTemplate === id,
                    ' transition-all hover:h-[350px] hover:md:h-[450px] duration-300':
                      activeTemplate !== id,
                  },
                )}
              />
            ))}
          </div>
          <div className="flex justify-center mt-5">
            {snapshots.map(({ id }) => (
              <div
                key={id}
                className={clsx('w-2 md:w-3 h-2 md:h-3 rounded-full mx-1', {
                  'bg-white': activeTemplate === id,
                  'bg-gray-600': activeTemplate !== id,
                })}
                onClick={() => setActiveTemplate(id)}
                onKeyDown={() => setActiveTemplate(id)}
                role="button"
                tabIndex={0}
                title="Show template"
              />
            ))}
          </div>
        </section>
        <div className="flex justify-center mt-10">
          <Button variant="primary" size="md" className="px-10">
            Choose Template
          </Button>
        </div>
      </section>
    </div>
  )
}
