import clsx from 'clsx'

type Props = {
  style?: 'solid' | 'regular' | 'light' | 'duotone' | 'brands'
  icon: string
  color?: string
  size?: 'sm' | 'md' | 'lg'
  animation?: string
  className?: string
}

const PREFIX = 'fa-'

const Icon = ({
  icon,
  animation,
  className,
  color = 'white',
  style = 'solid',
  size = 'sm',
}: Props) => {
  return (
    <i
      className={clsx(
        PREFIX + icon.replace(PREFIX, ''),
        PREFIX + style.replace(PREFIX, ''),
        PREFIX + size.replace(PREFIX, ''),
        { ['text-' + color]: color, [PREFIX + animation]: animation },
        className,
      )}
      title={icon === 'spinner-third' ? 'Loading...' : `Icon: ${icon}`}
    />
  )
}

export type IIconProps = Props
export default Icon
